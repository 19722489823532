/* eslint-disable prefer-arrow-callback */
import { Button, Modal } from 'antd';
import './OfflineWarningModal.less';
import 'styles/modal.less';

const OfflineWarningModal = ({
  setShowOfflineWarningModal,
  openModal,
  handleContinueEdit,
}: {
  setShowOfflineWarningModal: () => void;
  openModal: boolean;
  handleContinueEdit: () => void;
}) => (
  <Modal
    className="app-modal"
    open={openModal}
    title="Offline Warning"
    width={520}
    onCancel={setShowOfflineWarningModal}
    footer={null}>
    <div className="transferModelForm">
      <p className="justify-text">
        Please make sure that driver is online before editing the logs, because if driver went offline the logs which
        has been stored in tablet might conflict with previous logs.
      </p>
      <div className="custom-csv-file-footer">
        <Button className="generic-button-class cancel-button-set" onClick={setShowOfflineWarningModal}>
          Cancel
        </Button>
        <Button
          htmlType="button"
          className="generic-button-class primary-btn-md"
          type="primary"
          onClick={() => handleContinueEdit()}>
          Ok
        </Button>
      </div>
    </div>
  </Modal>
);

export default OfflineWarningModal;
