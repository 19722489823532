/* eslint-disable @typescript-eslint/no-explicit-any */
import { Col, Row } from 'antd';

import { useGetHistoryDetails } from 'hooks/unitHooks';
import { DriverResponseType } from 'services/drivers';

import InfoModal from 'components/modal/modalInfo';

import Graph from '../../../GraphPlot/GraphPlot';
import LogsEventListing from './LogsEventList';

import 'styles/modal.less';

const LogEditHistoryModal = ({
  setShowEditHistoryDetailModal,
  record,
  driverDetail,
  intialDate,
}: {
  setShowEditHistoryDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
  record: { id: string; status: string };
  driverDetail: DriverResponseType | undefined;
  intialDate: string;
}) => {
  // @ts-ignore
  const { data: historyData } = useGetHistoryDetails(record?.id, driverDetail?.homeTerminalTimeZone?.tzCode);

  const viewModalBody = () => (
    <Row gutter={24}>
      <Col span={12}>
        <>
          <h2 style={{ fontWeight: 'bold' }}>{record.status}</h2>
          <div className="history-modal">
            <Graph
              logDataGraph={historyData?.data.csvBeforeUpdate}
              date={intialDate}
              violations={historyData?.data?.violations}
              driverDetail={driverDetail}
            />
          </div>
          <LogsEventListing
            logDataGraph={historyData?.data.csvBeforeUpdate}
            date={intialDate}
            driverDetail={driverDetail}
          />
        </>{' '}
      </Col>
      <Col span={12}>
        <>
          <h2 style={{ display: 'flex', justifyContent: 'end', fontWeight: 'bold', paddingRight: '130px' }}>Edited</h2>
          <div className="history-modal">
            <Graph
              logDataGraph={historyData?.data.csvAfterUpdate}
              date={intialDate}
              violations={historyData?.data?.violations}
              driverDetail={driverDetail}
            />
          </div>
          <LogsEventListing
            logDataGraph={historyData?.data.csvAfterUpdate}
            date={intialDate}
            driverDetail={driverDetail}
          />
        </>{' '}
      </Col>
    </Row>
  );

  return (
    <InfoModal
      title="Log History"
      body={viewModalBody}
      width={1800}
      okText="close"
      onSubmit={() => {
        setShowEditHistoryDetailModal(false);
      }}
    />
  );
};

export default LogEditHistoryModal;
