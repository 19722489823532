import { Button, Col, Modal, Row, notification } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormItem, Input, Select, SubmitButton } from 'formik-antd';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { BsCardHeading, BsFillPersonFill } from 'react-icons/bs';
import { DEFAULT_CURRENT_PAGE } from 'constants/config';
import { useGetOfficesOptions } from 'hooks/officeHooks';
import { svgIcons } from 'resources/icons';
import { ReactComponent as Vehicles } from 'resources/icons/Group 73.svg';
import { useGetVehiclesOptions } from 'hooks/vehicleHooks';
import { GetLogFormResonse, LogFormType } from 'services/unit';
import { isEmpty } from 'lodash';
import { logFormValidation } from 'schemas/logform';
import { useUpdateLogForm } from 'hooks/unitHooks';
import { queryClient } from 'hooks';
import { GET_LOG_FORM } from 'hooks/keys';

import './UpdateLogFormModal.less';
import 'styles/modal.less';

const { Option } = Select;

const UpdtaeLogFormModal = ({
  setShowUpdateLogFormModal,
  openModal,
  logFormData,
  date,
}: {
  setShowUpdateLogFormModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  logFormData: GetLogFormResonse | undefined;
  date: string;
}) => {
  const officeOptions = useGetOfficesOptions({ pageNo: DEFAULT_CURRENT_PAGE, limit: 'All' });
  const { data: VehicleOptions } = useGetVehiclesOptions({ limit: 'all', showUnAssigned: true });
  const saveLogForm = useUpdateLogForm();

  const formInitialValues: LogFormType = {
    vehicleId: logFormData?.data?.manualVehicleId || '',
    trailerNumber: Array.isArray(logFormData?.data?.trailerNumber)
      ? logFormData?.data?.trailerNumber.filter(item => item.trim() !== '')
      : [logFormData?.data?.trailerNumber || ''].filter(item => item.trim() !== ''),
    distance: logFormData?.data?.distance || 0,
    shippingDocument: Array.isArray(logFormData?.data?.shippingDocument)
      ? logFormData?.data?.shippingDocument.filter(item => item.trim() !== '')
      : [logFormData?.data?.shippingDocument || ''].filter(item => item.trim() !== ''),
    driverName: `${logFormData?.data?.driverFirstName} ${logFormData?.data?.driverLastName}` || '',
    manualDriverId: logFormData?.data?.manualDriverId || '',
    from: logFormData?.data?.from || '',
    to: logFormData?.data?.to || '',
    carrier: logFormData?.data?.carrier || '',
    headOffice: logFormData?.data?.headOffice || '',
    homeTerminalAddress: logFormData?.data?.homeTerminalAddress || '',
    trailerNumberField: '',
    shippingDocumentField: '',
  };

  const handleLogDataSendModal = (values: LogFormType, formikHelpers: FormikHelpers<LogFormType>) => {
    formikHelpers.validateForm();
    const trailerNumber = values?.trailerNumber;
    if (values?.trailerNumberField) {
      trailerNumber?.push(values?.trailerNumberField);
    }
    const ShippingId = values?.shippingDocument;
    if (values?.shippingDocumentField) {
      ShippingId?.push(values?.shippingDocumentField);
    }
    const formData = new FormData();
    formData.append('driverId', String(logFormData?.data?.driverId));
    formData.append('trailerNumber', JSON.stringify(trailerNumber));
    formData.append('shippingDocument', JSON.stringify(ShippingId));
    formData.append('from', String(values?.from));
    formData.append('to', String(values?.to));
    // formData.append('odoMeterSpeed', String(logFormData?.data?.odoMeterSpeed));
    formData.append('homeTerminalAddressId', String(logFormData?.data?.homeTerminalAddressId));
    formData.append('homeTerminalAddress', String(logFormData?.data?.homeTerminalAddress));
    formData.append('date', String(date));

    saveLogForm
      .mutateAsync(formData)
      .then(response => {
        if (response?.data) {
          notification.success({ message: response?.message });
          queryClient.refetchQueries([GET_LOG_FORM]);
        } else {
          queryClient.refetchQueries([GET_LOG_FORM]);
          notification.success({ message: response?.message });
        }
      })
      .catch(error => {
        notification.error({ message: error?.message || 'Internal Server Error' });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        setShowUpdateLogFormModal(false);
      });
  };

  return (
    <Modal
      className="app-modal"
      open={openModal}
      title="Update Log Form"
      width={1050}
      onCancel={() => setShowUpdateLogFormModal(false)}
      footer={null}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={logFormValidation}
        onSubmit={handleLogDataSendModal}>
        {formProps => (
          <div className="logFormModal">
            <Form size="large" layout="vertical" initialValues={formInitialValues}>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="Driver Name" name="driverName" className="form-label" required>
                    <Input
                      name="driverName"
                      prefix={<BsFillPersonFill size={20} style={{ color: '#C9CCCE' }} />}
                      type="text"
                      maxLength={20}
                      placeholder="Driver Name"
                      className="form-control"
                      disabled={!isEmpty(formProps?.initialValues?.driverName)}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Carrier Name" name="carrier" className="form-label">
                    <Input
                      name="carrier"
                      prefix={<BsCardHeading size={20} />}
                      type="text"
                      maxLength={20}
                      placeholder="Carrier Name"
                      className="form-control"
                      disabled={!isEmpty(formProps?.initialValues?.carrier)}
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="Vehicle ID" name="vehicleId" className="form-label" required>
                    <BsCardHeading size={20} className="selectIcons" />
                    <div className="iconBorder" />
                    <Select
                      name="vehicleId"
                      placeholder="Select Vehicle ID"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={!isEmpty(formProps.initialValues.vehicleId)}>
                      {VehicleOptions?.data?.map(item => (
                        <Option value={item?.id} key={`eldId_${item.id}`}>
                          {`${item?.vehicleId}/${item?.licensePlateNo}`}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Distance" name="distance" className="form-label" required>
                    <Input
                      name="distance"
                      prefix={<svgIcons.LocationIcon width={20} height={20} />}
                      type="text"
                      maxLength={20}
                      placeholder="Distance"
                      className="form-control"
                      disabled
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="Main Office Address" name="headOffice" className="form-label" required>
                    <Input
                      name="headOffice"
                      prefix={<svgIcons.LocationIcon width={20} height={20} />}
                      type="text"
                      maxLength={20}
                      placeholder="Main Office Address"
                      className="form-control"
                      disabled={!isEmpty(formProps?.initialValues.headOffice)}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="Home Terminal Address" name="homeTerminalAddress" className="form-label" required>
                    <svgIcons.LocationIcon width={20} height={20} className="selectIcons" />
                    <div className="iconBorder" />
                    <Select
                      name="homeTerminalAddress"
                      placeholder="Select Terminal Address"
                      showSearch
                      filterOption={(input, option) =>
                        option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                        option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      disabled={!isEmpty(formProps?.initialValues.homeTerminalAddress)}>
                      {officeOptions.data?.data.map(item =>
                        item?.isActive ? (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ) : (
                          ''
                        ),
                      )}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <FormItem label="From Address" name="from" className="form-label">
                    <Input
                      name="from"
                      prefix={<svgIcons.LocationIcon width={20} height={20} />}
                      type="text"
                      maxLength={20}
                      placeholder="From Address"
                      className="form-control"
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem label="To Address" name="to" className="form-label">
                    <Input
                      name="to"
                      prefix={<svgIcons.LocationIcon width={20} height={20} />}
                      type="text"
                      maxLength={20}
                      placeholder="To Address"
                      className="form-control"
                    />
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem label="Trailer Number" name="trailerNumberField" className="form-label">
                    <Input
                      name="trailerNumberField"
                      prefix={<BsCardHeading width={20} height={20} />}
                      type="text"
                      placeholder="Add Trailer Number"
                      className="form-control"
                      maxLength={10}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          const fieldValue = formProps.values.trailerNumberField;
                          if (
                            fieldValue?.trim() !== '' &&
                            !formProps.values.trailerNumber?.includes(fieldValue || '')
                          ) {
                            formProps.setFieldValue('trailerNumber', [
                              ...formProps.values.trailerNumber!,
                              fieldValue?.trim(),
                            ]);
                            formProps.setFieldValue('trailerNumberField', '');
                          }
                        }
                      }}
                    />
                  </FormItem>
                  <div className="tagsShow">
                    {formProps &&
                      formProps?.values &&
                      formProps?.values?.trailerNumber?.map(item => (
                        <div key={item} className="custom-tag">
                          {item}
                          <span
                            aria-hidden
                            className="remove-custom-tag"
                            onClick={() =>
                              formProps?.setFieldValue(
                                'trailerNumber',
                                formProps?.values?.trailerNumber?.filter(entry => entry !== item),
                              )
                            }>
                            x
                          </span>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <FormItem label="Shipping IDs" name="shippingDocumentField" className="form-label">
                    <Input
                      name="shippingDocumentField"
                      prefix={<Vehicles width={20} height={20} />}
                      type="text"
                      placeholder="Add Shipping IDs"
                      className="form-control"
                      maxLength={40}
                      onKeyDown={event => {
                        if (event.key === 'Enter') {
                          event.preventDefault();
                          const fieldValue = formProps.values.shippingDocumentField;
                          if (
                            fieldValue?.trim() !== '' &&
                            !formProps.values.shippingDocument?.includes(fieldValue || '')
                          ) {
                            formProps.setFieldValue('shippingDocument', [
                              ...formProps.values.shippingDocument!,
                              fieldValue?.trim(),
                            ]);
                            formProps.setFieldValue('shippingDocumentField', '');
                          }
                        }
                      }}
                    />
                  </FormItem>
                  <div className="tagsShow">
                    {formProps &&
                      formProps?.values &&
                      formProps?.values?.shippingDocument?.map(shippingDocument => (
                        <div key={shippingDocument} className="custom-tag">
                          {shippingDocument}
                          <span
                            aria-hidden
                            className="remove-custom-tag"
                            onClick={() =>
                              formProps?.setFieldValue(
                                'shippingDocument',
                                formProps?.values?.shippingDocument?.filter(item => item !== shippingDocument),
                              )
                            }>
                            x
                          </span>
                        </div>
                      ))}
                  </div>
                </Col>
              </Row>
              <div className="custom-csv-file-footer">
                <Button
                  className="generic-button-class cancel-button-set"
                  onClick={() => setShowUpdateLogFormModal(false)}>
                  CANCEL
                </Button>
                <SubmitButton htmlType="submit" className="generic-button-class primary-btn-md">
                  Save
                </SubmitButton>
              </div>
              {/* <pre
                style={{
                  background: '#f6f8fa',
                  fontSize: '.65rem',
                  padding: '.5rem',
                }}>
                <FormikDebug />
              </pre> */}
            </Form>
          </div>
        )}
      </Formik>
    </Modal>
  );
};

export default UpdtaeLogFormModal;
