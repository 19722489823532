import { DEFAULT_EVENT_MMDDYY } from 'constants/dates';
import React, { useEffect, useState } from 'react';
import { Table, Row, Col, notification, Button } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { CSVGraphStatusType } from 'types/hos';
import { TimeZone } from 'types/common';
import { DriverResponseType } from 'services/drivers';
import { convertDateDriverHomeTimeZone, getStatusEventCode } from 'helpers/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { useDeleteLogs, useGetSingleUnitByDriverId } from 'hooks/unitHooks';
import { queryClient } from 'hooks';
import { GET_HOS_DRIVER_CSV } from 'hooks/keys';
import AppModal from 'components/modal/modal';
import LogInfoModal from '../LogInfoModal/LogInfoModal';
import LogsEventTableColumns from './ModifiedLogsEventTableColumns';

import '../LogsEventList/LogsEventListing.less';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const ModifiedLogsEventList = ({
  logDataGraph,
  date,
  driverDetail,
  highlightedLog,
  setActionType,
  setSelectedLog,
  setSelectedRange,
}: {
  logDataGraph: any;
  date: string;
  driverDetail: DriverResponseType | undefined;
  highlightedLog: any;
  setActionType: React.Dispatch<React.SetStateAction<number>>;
  setSelectedLog: React.Dispatch<React.SetStateAction<CSVGraphStatusType | null | undefined>>;
  setSelectedRange: React.Dispatch<React.SetStateAction<object>>;
}) => {
  const histroy = useHistory();
  const location = useLocation();
  const urlParams = location?.pathname.split('/');
  const driverNameByParams = urlParams[2] || '';

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let logStorageData: any
  if (!sessionStorage.getItem(`selectdLogDatail`)) {
    histroy.push("/logs")
  } else {
    logStorageData = JSON.parse(sessionStorage.getItem(`selectdLogDatail`) || '');
  }
  const convertedDate = moment(date, 'YYYY-MM-DD').format(DEFAULT_EVENT_MMDDYY);
  const tableRef = React.useRef<any>();
  const [showDeleteLogModal, setShowDeleteLogModal] = useState(false);
  const [showEditLogInfoModal, setShowEditLogInfoModal] = useState(false);
  const [ShowBulkButton, setShowBulkButton] = useState(false);
  const [selectedLogs, setSelectedLogs] = useState<string[]>();
  const [selectedInfoLogs, setSelectedInfoLogs] = useState<CSVGraphStatusType>();

  const deleteLogs = useDeleteLogs();
  const { data: singleUnitDetails } = useGetSingleUnitByDriverId(logStorageData?.driverId);

  const logOffDutyEvents: CSVGraphStatusType[] = logDataGraph?.csv?.eldEventListForDriversRecordOfDutyStatus
    ?.filter(
      (item: CSVGraphStatusType) =>
        (item?.eventType === '1' || item?.eventType === '3') &&
        item?.eventRecordStatus === '1' &&
        item?.eventDate === convertedDate,
    )
    ?.sort((a: CSVGraphStatusType, b: CSVGraphStatusType) => Number(a.eventTime) - Number(b.eventTime)) || [
      {
        eventSequenceIdNumber: '1',
        eventRecordStatus: '1',
        eventRecordOrigin: '1',
        eventType: '1',
        eventCode: getStatusEventCode(logStorageData?.lastActivityStatus, logStorageData?.currentDate, convertedDate),
        eventDate: convertedDate,
        eventTime: '000000',
        accumulatedVehicleMiles: 0,
        accumulatedEngineHours: 0,
        eventLatitude: 0,
        eventLongitude: 0,
        distanceSinceLastValidCoordinates: 0,
        correspondingCmvOrderNumber: '1',
        userOrderNumberForRecordOriginator: '1',
        malfunctionIndicatorStatusForEld: '0',
        dataDiagnosticEventIndicatorForDriver: '1',
        eventDataCheckValue: 'F2',
        lineDataCheckValue: '67',
        address: logStorageData?.meta?.lastActivity?.address || '',
      },
    ];

  const intLocationEvents: CSVGraphStatusType[] =
    logDataGraph?.csv?.eldEventListForDriversRecordOfDutyStatus?.filter(
      (item: CSVGraphStatusType) => item?.eventType === '2' && !item?.missing,
    ) || [];

  const updatedArray: CSVGraphStatusType[] = [];

  const loginLogoutEvents: CSVGraphStatusType[] =
    logDataGraph?.csv?.eldLoginLogoutReport?.filter((item: CSVGraphStatusType) => item?.eventTypeExtra === '5') || [];

  const certifyEvents: CSVGraphStatusType[] =
    logDataGraph?.csv?.eldEventListForDriverCertificationOfOwnRecords?.filter(
      (item: CSVGraphStatusType) =>
        item?.certificateType === '4' && item?.eventDate === moment(date, 'YYYY-MM-DD').format('MMDDYY'),
    ) || [];

  const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: logStorageData?.driverTimeZone };
  if (!isEmpty(logOffDutyEvents)) {
    // const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: logStorageData?.driverTimeZone };
    const currentDateInZone = convertDateDriverHomeTimeZone(new Date(), tzCode);
    const currentDate = currentDateInZone.format(DEFAULT_EVENT_MMDDYY);

    for (let i = 0; i < logOffDutyEvents.length; i += 1) {
      const start = moment(logOffDutyEvents[i]?.eventTime, 'HH:mm:ss');
      const end = moment(
        logOffDutyEvents[i + 1]?.eventTime
          ? logOffDutyEvents[i + 1]?.eventTime
          : [convertedDate === currentDate ? currentDateInZone.format('HHmmss') : '235959'],
        'HH:mm:ss',
      );

      const showData = moment.utc(end.diff(start)).format('HH:mm:ss');
      const finalTime = showData.split(':');
      let duration = `${finalTime[0] !== '00' ? `${finalTime[0]} hrs` : ''} ${finalTime[1] !== '00' ? `${finalTime[1]} min` : ''
        } ${finalTime[2] !== '00' ? `${finalTime[2]} sec` : ''}`;

      if (duration === '23 hrs 59 min 59 sec') {
        duration = '24h';
      }
      updatedArray?.push({
        ...logOffDutyEvents[i],
        duration,
        eventEndTime: end?.format('HHmmss'),
        durationTime: showData,
      });
    }
  }

  const drivingStatuses = updatedArray?.filter(
    (item: CSVGraphStatusType) =>
      (item?.eventType === '1' && item?.eventCode === '3') ||
      (item?.eventType === '3' && (item?.eventCode === '1' || item?.eventCode === '2')),
  );
  const stationaryStatus = updatedArray?.filter(
    (item: CSVGraphStatusType) =>
      item?.eventType === '1' && (item?.eventCode === '1' || item?.eventCode === '2' || item?.eventCode === '4'),
  );

  const vehicleId = logDataGraph?.csv?.powerUnitLine?.powerUnitNumber || driverDetail?.vehicleId?.vehicleId || '';

  const logPowerUpDownEvents = logDataGraph?.csv?.cmvEnginePowerUpShutDownActivity || [];
  const lastDayPowerUpDownEvents = logDataGraph?.meta?.powerUp || false;
  const powerUpDownEvents: CSVGraphStatusType[] = logPowerUpDownEvents?.map((item: CSVGraphStatusType) => ({
    ...item,
    eventType: '6',
  }));

  // Comment For now as Poer Up missing red show not need for now
  // powerUpDownEvents = powerUpDownEvents.map((entry: CSVGraphStatusType, index) => {
  //   const current_event_code = entry.eventCode;
  //   if (index === 0) {
  //     if (lastDayPowerUpDownEvents && (current_event_code === '1' || current_event_code === '2')) {
  //       return { ...entry, colored: true };
  //     }
  //     if (!lastDayPowerUpDownEvents && (current_event_code === '3' || current_event_code === '4')) {
  //       return { ...entry, colored: true };
  //     }
  //   }
  //   if (index > 0) {
  //     // && entry.eventCode === powerUpDownEvents[index - 1].eventCode
  //     if (
  //       current_event_code === '1' &&
  //       (powerUpDownEvents[index - 1].eventCode === '1' || powerUpDownEvents[index - 1].eventCode === '2')
  //     ) {
  //       return { ...entry, colored: true };
  //     }
  //     if (
  //       current_event_code === '2' &&
  //       (powerUpDownEvents[index - 1].eventCode === '1' || powerUpDownEvents[index - 1].eventCode === '2')
  //     ) {
  //       return { ...entry, colored: true };
  //     }
  //     if (
  //       current_event_code === '3' &&
  //       (powerUpDownEvents[index - 1].eventCode === '3' || powerUpDownEvents[index - 1].eventCode === '4')
  //     ) {
  //       return { ...entry, colored: true };
  //     }
  //     if (
  //       current_event_code === '4' &&
  //       (powerUpDownEvents[index - 1].eventCode === '3' || powerUpDownEvents[index - 1].eventCode === '4')
  //     ) {
  //       return { ...entry, colored: true };
  //     }
  //   }
  //   return entry;
  // });

  const scrollReset = () => {
    const tableBody = tableRef?.current?.querySelector('.ant-table-content');
    if (tableBody) {
      tableBody.scrollTop = 0;
    }
  };

  const isDrivingStatus = (type: string, code: string) =>
    (type === '1' && code === '3') || (type === '3' && (code === '1' || code === '2')) || type === '2';

  const calculateExpectedEngineHours = (currentRecord: CSVGraphStatusType, previousRecord: CSVGraphStatusType) => {
    const previousRecordTime = moment(`${previousRecord?.eventDate} ${previousRecord?.eventTime}`, 'MMDDYY HHmmss');
    const currentRecordTime = moment(`${currentRecord?.eventDate} ${currentRecord?.eventTime}`, 'MMDDYY HHmmss');
    const duration = moment.duration(currentRecordTime.diff(previousRecordTime));
    const durationInHours = duration.asHours();
    const expectedEngineHours = parseFloat(previousRecord?.totalEngineHoursDutyStatus || '0') + durationInHours;
    // const finalFormattedValue = parseFloat(
    //   `${expectedEngineHours?.toString().split('.')[0]}.${expectedEngineHours.toString().split('.')[1][0]}`,
    // );

    const parts = expectedEngineHours.toString().split('.');

    // Extract the integer part
    const integerPart = parts[0];

    // Extract the first decimal digit if it exists
    const decimalPart = parts[1]?.[0] || '0';

    return parseFloat(`${integerPart}.${decimalPart}`);
  };

  const calculateAccumulatedMiles = (
    dutyRecordEvents: CSVGraphStatusType[],
    intermediateEvents: CSVGraphStatusType[],
    powerEvents: CSVGraphStatusType[],
  ) => {
    const allLogsEvents = [...dutyRecordEvents, ...intermediateEvents, ...powerEvents]?.sort((a, b) =>
      a.eventTime.localeCompare(b.eventTime),
    );

    if (!isEmpty(allLogsEvents)) {
      allLogsEvents[0].isOdometer = '0';
      allLogsEvents[0].isEngineHours = '0';
      allLogsEvents[0].speed = '';
      let lastIsDriving = false;
      let isEngineOn = lastDayPowerUpDownEvents || false;
      let errorShownDouble = false;
      for (let i = 1; i < allLogsEvents.length; i += 1) {
        let prevIndex = i;
        prevIndex -= 1;

        allLogsEvents[i].isOdometer = '0';
        allLogsEvents[i].isEngineHours = '0';
        allLogsEvents[i].speed = '';
        if (
          ((allLogsEvents[prevIndex]?.eventType === '1' && allLogsEvents[prevIndex]?.eventCode === '3') ||
            (allLogsEvents[prevIndex]?.eventType === '3' && allLogsEvents[prevIndex]?.eventCode === '1') ||
            (allLogsEvents[prevIndex]?.eventType === '3' && allLogsEvents[prevIndex]?.eventCode === '2') ||
            allLogsEvents[prevIndex]?.eventType === '2') &&
          allLogsEvents[i]?.eventType === '6'
        ) {
          if (
            Number(allLogsEvents[i]?.totalVehicleMilesDutyStatus) <
            Number(allLogsEvents[prevIndex]?.totalVehicleMilesDutyStatus)
          ) {
            allLogsEvents[i].isOdometer = '2';
          }
          lastIsDriving = true;
        } else if (
          (allLogsEvents[prevIndex]?.eventType === '1' && allLogsEvents[prevIndex]?.eventCode === '3') ||
          (allLogsEvents[prevIndex]?.eventType === '3' && allLogsEvents[prevIndex]?.eventCode === '1') ||
          (allLogsEvents[prevIndex]?.eventType === '3' && allLogsEvents[prevIndex]?.eventCode === '2') ||
          allLogsEvents[prevIndex]?.eventType === '2'
        ) {
          if (allLogsEvents[i]?.eventType === '2') {
            if (
              Number(allLogsEvents[i]?.totalVehicleMilesDutyStatus) <=
              Number(allLogsEvents[prevIndex]?.totalVehicleMilesDutyStatus)
            ) {
              allLogsEvents[i].isOdometer = '2';
            }
          }
          if (allLogsEvents[i]?.eventType !== '2') {
            if (
              Number(allLogsEvents[i]?.totalVehicleMilesDutyStatus) <
              Number(allLogsEvents[prevIndex]?.totalVehicleMilesDutyStatus)
            ) {
              allLogsEvents[i].isOdometer = '2';
            }
          }
          lastIsDriving = isDrivingStatus(allLogsEvents[i]?.eventType, allLogsEvents[i]?.eventCode);
        } else if (allLogsEvents[i]?.eventType === '6' && lastIsDriving) {
          if (
            Number(allLogsEvents[i].totalVehicleMilesDutyStatus) <
            Number(allLogsEvents[prevIndex].totalVehicleMilesDutyStatus)
          ) {
            allLogsEvents[i].isOdometer = '2';
          }
        } else if (allLogsEvents[i]?.eventType === '6' && !lastIsDriving) {
          if (
            Number(allLogsEvents[i].totalVehicleMilesDutyStatus) !==
            Number(allLogsEvents[prevIndex].totalVehicleMilesDutyStatus)
          ) {
            allLogsEvents[i].isOdometer = '1';
          }
        } else if (lastIsDriving) {
          if (
            Number(allLogsEvents[i]?.totalVehicleMilesDutyStatus) <
            Number(allLogsEvents[prevIndex]?.totalVehicleMilesDutyStatus)
          ) {
            allLogsEvents[i].isOdometer = '2';
          }
          lastIsDriving = false;
        } else if (
          Number(allLogsEvents[i].totalVehicleMilesDutyStatus) !==
          Number(allLogsEvents[prevIndex].totalVehicleMilesDutyStatus)
        ) {
          allLogsEvents[i].isOdometer = '1';
          lastIsDriving = isDrivingStatus(allLogsEvents[i]?.eventType, allLogsEvents[i]?.eventCode);
        }
        if (isEngineOn) {
          let expectedEngineHours;
          if (prevIndex === 0) {
            const lastLogTime = logDataGraph?.meta?.deviceCalculations?.lastLogTime;
            const expectedFirstLog = {
              ...allLogsEvents[prevIndex],
              eventTime: lastLogTime?.eventTime,
              eventDate: lastLogTime?.eventDate,
            };
            expectedEngineHours = calculateExpectedEngineHours(allLogsEvents[i], expectedFirstLog);
          } else {
            expectedEngineHours = calculateExpectedEngineHours(allLogsEvents[i], allLogsEvents[prevIndex]);
          }
          if (
            Number(allLogsEvents[i].totalEngineHoursDutyStatus) ===
            Number(allLogsEvents[prevIndex].totalEngineHoursDutyStatus) &&
            Number(allLogsEvents[i].totalEngineHoursDutyStatus) !== Number(expectedEngineHours)
          ) {
            allLogsEvents[i].isEngineHours = '2';
          } else if (
            Number(allLogsEvents[i].totalEngineHoursDutyStatus) <
            Number(allLogsEvents[prevIndex].totalEngineHoursDutyStatus)
          ) {
            allLogsEvents[i].isEngineHours = '1';
          } else if (
            Number(allLogsEvents[i].totalEngineHoursDutyStatus) !==
            Number(allLogsEvents[prevIndex].totalEngineHoursDutyStatus) &&
            !(
              Number(allLogsEvents[i].totalEngineHoursDutyStatus) !== Number(expectedEngineHours) ||
              Number(allLogsEvents[i].totalEngineHoursDutyStatus) !== Number(expectedEngineHours - 0.1) ||
              Number(allLogsEvents[i].totalEngineHoursDutyStatus) !== Number(expectedEngineHours + 0.1)
            )
          ) {
            allLogsEvents[i].isEngineHours = '1';
          }
        } else if (!isEngineOn) {
          if (
            Number(allLogsEvents[i].totalEngineHoursDutyStatus) !==
            Number(allLogsEvents[prevIndex].totalEngineHoursDutyStatus)
          ) {
            // allLogsEvents[i].isEngineHours = '1';
            if (!errorShownDouble) {
              allLogsEvents[i].isEngineHours = '1';
            }
            errorShownDouble = isDrivingStatus(allLogsEvents[i]?.eventType, allLogsEvents[i]?.eventCode);
          }
        }

        if (allLogsEvents[i]?.eventType === '6') {
          isEngineOn = allLogsEvents[i]?.eventCode === '1' || allLogsEvents[i]?.eventCode === '2';
          errorShownDouble = false;
        }
      }
    }
    const finalDutyRecords = allLogsEvents.filter(x => x.eventType !== '6');
    if (!isEmpty(finalDutyRecords)) {
      for (let i = 1; i < finalDutyRecords.length - 1; i += 1) {
        if (
          (finalDutyRecords[i]?.eventType === '2' ||
            finalDutyRecords[i]?.eventType === '3' ||
            (finalDutyRecords[i]?.eventType === '1' && finalDutyRecords[i]?.eventCode === '3')) &&
          finalDutyRecords[i + 1]
        ) {
          // calculate speed by formula
          const distance =
            Number(finalDutyRecords[i + 1]?.totalVehicleMilesDutyStatus) -
            Number(finalDutyRecords[i]?.totalVehicleMilesDutyStatus);
          const duration = moment
            .duration(
              moment(
                `${finalDutyRecords[i + 1]?.eventDate} ${finalDutyRecords[i + 1]?.eventTime}`,
                'MMDDYY HHmmss',
              ).diff(moment(`${finalDutyRecords[i]?.eventDate} ${finalDutyRecords[i]?.eventTime}`, 'MMDDYY HHmmss')),
            )
            .asHours();
          let speed = 0;
          if (distance !== 0 && duration !== 0) {
            speed = Math.floor(distance / duration);
          }
          finalDutyRecords[i].speed = speed.toString();
        }
      }
    }
    return allLogsEvents;
  };

  const dutyRecorsAfterValidation = calculateAccumulatedMiles(updatedArray, intLocationEvents, powerUpDownEvents);

  let mergedAllevents = [...dutyRecorsAfterValidation, ...certifyEvents, ...loginLogoutEvents];
  mergedAllevents = mergedAllevents.map((item: CSVGraphStatusType, index) => ({
    ...item,
    key: `id_${index}`,
  }));

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: CSVGraphStatusType[]) => {
      if (selectedRows.length > 0) {
        setShowBulkButton(true);
      } else {
        setShowBulkButton(false);
      }
      setSelectedLogs(selectedRows?.map(key => key.eventSequenceIdNumber));
    },
    getCheckboxProps: (record: CSVGraphStatusType) => ({
      disabled: record.eventType === '1' || record.eventType === '3',
    }),
  };

  const openUpdateModal = (sequenceId: string) => {
    setSelectedLogs([sequenceId]);
  };

  const openInfoModal = (record: CSVGraphStatusType) => {
    setSelectedInfoLogs(record);
  };

  const removeModalBody = () => <p className="modal-body-title">Do you want to delete logs?</p>;

  const handleDeletedLogsSubmit = () => {
    const finalData = {
      date,
      driverId: driverDetail?.id || '',
      eventSequenceIdNumber: selectedLogs!,
    };

    deleteLogs
      .mutateAsync(finalData)
      .then(response => {
        if (response?.data) {
          setShowBulkButton(false);
          setSelectedLogs([]);
          notification.success({ message: response?.message });
          queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
          histroy.push(`/logs/${driverNameByParams}`);
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } else {
          notification.success({ message: response?.message });
        }
      })
      .catch(error => {
        notification.error({ message: error?.message });
      })
      .finally(() => {
        setShowDeleteLogModal(false);
        setSelectedLogs([]);
      });
  };

  useEffect(() => {
    if (highlightedLog) {
      const row = mergedAllevents.find(item => item?.eventSequenceIdNumber === highlightedLog?.eventSequenceIdNumber);

      if (row) {
        const rowElement = tableRef.current.querySelector(`[data-row-key="${row.key}"]`);
        if (rowElement) {
          rowElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
      scrollReset();
    }
  }, [highlightedLog]);
  return (
    <div className="table-container LogsEventListing">
      <Row>
        <Col span={24}>
          <div>
            <Table
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              columns={LogsEventTableColumns({
                vehicleId,
                openUpdateModal,
                setShowDeleteLogModal,
                setShowEditLogInfoModal,
                openInfoModal,
                setSelectedLog,
                setSelectedRange,
                setActionType,
                drivingStatuses,
                stationaryStatus,
              })}
              pagination={false}
              ref={tableRef}
              dataSource={mergedAllevents?.sort((a, b) => Number(a?.eventTime) - Number(b?.eventTime))}
              rowKey={record => record.key!}
              rowClassName={record =>
                record?.eventRecordOrigin === '3' ||
                  record?.eventSequenceIdNumber === highlightedLog?.eventSequenceIdNumber
                  ? 'editedLogHighlight'
                  : ''
              }
              size="small"
              scroll={{ y: 450 }}
              footer={() => (
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                  {ShowBulkButton ? (
                    <Button
                      className="danger-btn-md"
                      onClick={() => {
                        setShowDeleteLogModal(true);
                      }}>
                      Bulk Delete
                    </Button>
                  ) : null}
                </div>
              )}
              showSorterTooltip={false}
            />
          </div>
        </Col>
      </Row>
      {showDeleteLogModal && (
        <AppModal
          title="Delete Logs"
          body={removeModalBody}
          onCancel={() => setShowDeleteLogModal(false)}
          onSubmit={() => handleDeletedLogsSubmit()}
        />
      )}
      {showEditLogInfoModal && (
        <LogInfoModal
          openModal={showEditLogInfoModal}
          onCancel={() => setShowEditLogInfoModal(false)}
          driverId={driverDetail?.id}
          singleUnitDetails={singleUnitDetails}
          selectedInfoLogs={selectedInfoLogs}
          date={date}
          tzCode={tzCode}
          setShowBulkButton={setShowBulkButton}
          setSelectedLogs={setSelectedLogs}
        />
      )}
    </div>
  );
};

export default ModifiedLogsEventList;
