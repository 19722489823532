import React, { FC } from 'react';

import './Footer.less';

const Footer: FC = () => {
  const apiVersion = 'API Version 1.0';
  const version = 'Version 1.0';

  return (
    <div className="footer">
      <div className="hr-line" />
      <div className="footer-content">
        <span className="footer-text">© 2021-2022 LOG ELD LLC</span>
        <span className="footer-text">
          {apiVersion} | {version}
        </span>
      </div>
    </div>
  );
};

export default Footer;
