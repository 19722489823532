/* eslint-disable prefer-arrow-callback */
import { DATE_ONLY } from 'constants/dates';
import { Button, Col, Modal, notification, Row } from 'antd';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormItem, SubmitButton } from 'formik-antd';
import { CSVGraphStatusType, TransferLogsRequestType, UnitType } from 'types/hos';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { queryClient } from 'hooks';
import { GET_HOS_DRIVER_CSV } from 'hooks/keys';
import { useTransferLogs } from 'hooks/unitHooks';

import './TransferLogModal.less';
import 'styles/modal.less';
import { TransferPicker } from 'components/transfer-timepicker/TransferTimepicker';

const TransferLogModal = ({
  setShowTransferModal,
  openModal,
  selectedLogEntry,
  singleUnitDetails,
}: {
  setShowTransferModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  selectedLogEntry: CSVGraphStatusType | undefined;
  singleUnitDetails: UnitType | undefined;
}) => {
  const histroy = useHistory();
  const location = useLocation();
  const urlParams = location?.pathname.split('/');
  const driverNameByParams = urlParams[2] || '';
  const saveTransferLog = useTransferLogs();

  const formInitialValues = {
    duration: moment(selectedLogEntry?.durationTime, 'HH:mm:ss').format('HH:mm:ss'),
  };

  const validationSchema = Yup.object().shape({
    duration: Yup.mixed()
      .required('Time is required')
      .test('is-non-zero', '', value => {
        const timeValue = moment.duration(value);
        const durationInSeconds = timeValue.asSeconds();
        return durationInSeconds > 0;
      })
      .test('is-greater', 'selected time is greater than current time!', value => {
        const logTime = value;
        const selectedLogDurationSeconds = moment.duration(logTime).asSeconds();
        const originLogDurationSeconds = moment.duration(selectedLogEntry?.durationTime).asSeconds();
        const currentTimeTotalSeconds = singleUnitDetails?.meta?.deviceCalculations?.ON_DUTY_CURRENT_TIME || 0;
        return selectedLogDurationSeconds <= originLogDurationSeconds + currentTimeTotalSeconds;
      }),
  });

  const handleTransferLogModal = (values: { duration: string }, formikHelpers: FormikHelpers<{ duration: string }>) => {
    formikHelpers.validateForm();
    const selectedLogDurationSeconds = moment.duration(values.duration).asSeconds();
    const originLogDurationSeconds = moment.duration(selectedLogEntry?.durationTime).asSeconds();

    const finalData: TransferLogsRequestType = {
      driverId: singleUnitDetails?.driverId || '',
      sequenceId: selectedLogEntry!.eventSequenceIdNumber,
      date: moment(selectedLogEntry?.eventDate, 'MMDDYY').format(DATE_ONLY),
      duration: Math.abs(originLogDurationSeconds - selectedLogDurationSeconds),
      type: selectedLogDurationSeconds < originLogDurationSeconds ? 1 : 2,
    };
    saveTransferLog
      .mutateAsync(finalData)
      .then(response => {
        notification.success({ message: response?.message });
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        histroy.push(`/logs/${driverNameByParams}`);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch(error => {
        notification.error({ message: error?.message });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        setShowTransferModal(false);
      });
  };
  const handleTimeChange = (value: string, formProps: FormikHelpers<{ duration: string }>) => {
    formProps.setFieldValue('duration', value);
  };
  const fetchAvailableTime = () => {
    const currentTimeTotalSeconds = singleUnitDetails?.meta?.deviceCalculations?.ON_DUTY_CURRENT_TIME || 0;

    const hours: number = Math.floor(currentTimeTotalSeconds / 3600);
    const minutes: number = Math.floor((currentTimeTotalSeconds % 3600) / 60);
    const seconds: number = currentTimeTotalSeconds % 60;

    // Ensure each component is two digits
    const formattedHours: string = hours < 10 ? `0${hours}` : String(hours);
    const formattedMinutes: string = minutes < 10 ? `0${minutes}` : String(minutes);
    const formattedSeconds: string = seconds < 10 ? `0${seconds}` : String(seconds);

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  };
  return (
    <Modal
      className="app-modal"
      open={openModal}
      title="Transfer Log"
      width={520}
      onCancel={() => setShowTransferModal(false)}
      footer={null}>
      <div className="transferModelForm">
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleTransferLogModal}>
          {formProps => (
            <Form size="large" layout="vertical" initialValues={formInitialValues}>
              <Row gutter={72}>
                <Col span={24}>
                  <h6 className="content-title-bold">Responsibility warning</h6>
                  <p className="justify-text">
                    LOGELD, as your service provider, is not responsible for any financial or legal repercussions
                    resulting from facilitating your request. It is the sole responsibility of the user to maintain
                    legal compliance while using ELD.
                  </p>
                </Col>
                <Col span={24}>
                  <h6 className="content-title-bold">Offline warning</h6>
                  <p className="justify-text">
                    Please make sure that driver is online before transfering the logs, because if driver went offline
                    the logs which has been stored in tablet might conflict with previous transfered logs.
                  </p>
                </Col>
                <Col span={24}>
                  <FormItem
                    name="duration"
                    validateStatus={formProps.errors.duration ? 'error' : ''}
                    help={formProps.errors.duration}
                    style={{ marginBottom: '0px' }}>
                    <TransferPicker
                      handleChange={value => handleTimeChange(value, formProps)}
                      selectedLogEntryDuration={selectedLogEntry?.durationTime}
                    />
                  </FormItem>
                  <div style={{ marginBottom: '10px' }}>Available time {fetchAvailableTime()}</div>
                </Col>
              </Row>

              <div className="custom-csv-file-footer">
                <Button className="generic-button-class cancel-button-set" onClick={() => setShowTransferModal(false)}>
                  Cancel
                </Button>
                <SubmitButton
                  htmlType="submit"
                  className="generic-button-class primary-btn-md"
                  disabled={!formProps.dirty || !formProps.isValid}>
                  Ok
                </SubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default TransferLogModal;
