/* eslint-disable @typescript-eslint/no-explicit-any */
import { DRIVER_API_BASE_URL } from 'constants/config';
import { isEmpty } from 'lodash';

import httpRequest from './config/HttpRequest';
import { VehicleType } from './vehicles';

export type GetDriverRequest = {
  pageNo?: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  isActive?: boolean;
  limit?: number | string;
};

export type GetDriverRequestForCSV = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  isActive?: boolean;
  limit?: number | string;
};

export type TimeZone = {
  tzCode: string;
  utc?: string;
  label?: string;
  name?: string;
};

export type DriverType = {
  id?: string;
  driverId?: string;
  profile?:
  | File
  | undefined
  | {
    name: string;
    key: string;
    date: number;
    imagePath: string;
  };
  userName?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  password?: string;
  homeTerminalAddress?: string;
  state?: string;
  phoneNumber?: string;
  deviceId?: string;
  // homeTerminalTimeZone?: string | TimeZone;
  homeTerminalTimeZone?: any;
  licenseNumber?: string;
  eldType?: string;
  deviceVersion?: string;
  deviceModel?: string;
  lastActivityDate?: number;
  vehicleId?: VehicleType | string;
  coDriverId?: string;
  notes?: string | undefined;
  assetId: string;
  trailerNumber?: string | undefined;
  enableEld?: boolean;
  enableElog?: boolean;
  yardMove?: boolean;
  personalConveyance?: boolean;
  isActive: boolean;
  shouldUpdatePassword?: boolean;
  isSaveAddButton: boolean;
  isCoDriver: boolean;
  cycleRule?: string;
  eldNo?: string;
  eldMongoId?: string;
  vehicleMongoId?: string;
  licensePlateNo?: string;
  make?: string;
};

export type DriverResponseType = {
  id?: string;
  driverId?: string;
  driverProfile?: {
    name: string;
    key: string;
    date: number;
    imagePath: string;
  };
  userName: string;
  email?: string;
  firstName: string;
  lastName: string;
  password: string;
  homeTerminalAddress: {
    id?: string;
    name?: string;
  };
  state: string;
  phoneNumber: string;
  deviceId?: string;
  // homeTerminalTimeZone?: TimeZone | string;
  homeTerminalTimeZone?: any;
  licenseNumber: string;
  vehicleId: {
    id: string;
    vehicleId: string;
    licensePlateNo?: string;
    vinNo?: string;
    make?: string;
    model?: string;
    eldId?: string;
  };
  assetId: {
    id?: string;
    assetId?: string;
  };
  coDriver?: {
    id?: string;
    licensePlateNo?: string;
    vinNo?: string;
    make?: string;
    model?: string;
  };
  cycleRule: string;
  trailerNumber?: string;
  notes: string;
  enableEld: boolean;
  enableElog: boolean;
  yardMove: boolean;
  personalConveyance: boolean;
  isActive: boolean;
  shouldUpdatePassword?: boolean;
  tenantId?: string;
};

export const DriverDefaultValues = {
  data: {
    driverId: '',
    userName: '',
    profile: File,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    homeTerminalAddress: {
      id: '',
    },
    state: '',
    phoneNumber: '',
    deviceId: '',
    homeTerminalTimeZone: undefined,
    licenseNumber: '',
    vehicleId: {
      id: '',
      vehicleId: '',
    },
    coDriver: {
      id: '',
    },
    assetId: {
      id: '',
    },
    cycleRule: '',
    notes: '',
    trailerNumber: '',
    enableEld: false,
    enableElog: false,
    yardMove: false,
    personalConveyance: false,
    isActive: false,
    id: '',
    shouldUpdatePassword: false,
    isSaveAddButton: true,
  },
  message: '',
};

export type DriverResponse = {
  data: DriverResponseType;
  message: string;
};

export type PaginatedDriver = {
  data: DriverType[];
  total: number;
  last_page: number;
  pageNo: number;
};

export type UpdateDriverStatusType = {
  id: string;
  isActive: boolean;
};

const driverapi = {
  getDrivers: async (params: GetDriverRequest): Promise<PaginatedDriver> => {
    try {
      const response = await httpRequest.request({
        baseURL: DRIVER_API_BASE_URL,
        url: '/driver',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriversForCSV: async (params: GetDriverRequestForCSV): Promise<PaginatedDriver> => {
    try {
      const response = await httpRequest.request({
        baseURL: DRIVER_API_BASE_URL,
        url: '/driver',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriver: async (driverId: string): Promise<DriverResponse> => {
    try {
      if (!isEmpty(driverId)) {
        const response = await httpRequest.request({
          baseURL: DRIVER_API_BASE_URL,
          url: `/driver/${driverId}`,
          method: 'GET',
        });
        return response;
      }
      return DriverDefaultValues;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriversOptions: async (params: GetDriverRequest) => {
    try {
      const response = await httpRequest.request({
        baseURL: DRIVER_API_BASE_URL,
        url: '/codrivers',
        method: 'GET',
        params,
      });
      return response?.data.map((d: { firstName: string; lastName: string; id: number }) => ({
        label: `${d.firstName} ${d.lastName}`,
        value: d.id,
      }));
    } catch (error) {
      throw { ...error };
    }
  },

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  saveDriver: async (body: any): Promise<DriverResponse> => {
    const driverId = body.get('id');
    try {
      const requestParams: {
        baseURL: string;
        url: string;
        method: 'POST' | 'PUT';
        // eslint-disable-next-line  @typescript-eslint/no-explicit-any
        data: any;
        headers: { 'Content-Type': string };
      } = {
        baseURL: DRIVER_API_BASE_URL,
        url: '/driver/add',
        method: 'POST',
        data: body,
        headers: { 'Content-Type': 'multipart/form-data' },
      };

      if (!isEmpty(driverId)) {
        requestParams.url = `/driver/${driverId}`;
        requestParams.method = 'PUT';
      }

      const response = await httpRequest.request(requestParams);
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  updateDriverStatus: async (body: UpdateDriverStatusType): Promise<DriverResponse> => {
    const driverId = body.id;
    try {
      const response = await httpRequest.request({
        baseURL: DRIVER_API_BASE_URL,
        url: `/driver/status/${driverId}`,
        method: 'PATCH',
        data: body,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getCoDrivers: async (): Promise<PaginatedDriver> => {
    try {
      const response = await httpRequest.request({
        baseURL: DRIVER_API_BASE_URL,
        url: '/codrivers',
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },
};

export default driverapi;
