import { VEHICLE_API_BASE_URL } from 'constants/config';
import { isEmpty } from 'lodash';

import httpRequest from './config/HttpRequest';

export type GetVehicleRequest = {
  pageNo?: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  limit?: number | string;
  isActive?: boolean;
  showUnAssigned?: boolean;
};

export type GetVehicleOptionsRequest = {
  pageNo?: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  limit?: string;
  showUnAssigned?: boolean;
};

export type TimeZone = {
  tzCode: string;
  utc?: string;
  label?: string;
  name?: string;
};

export type VehicleType = {
  id?: string;
  vehicleId?: string;
  make?: string;
  model?: string;
  modelYear?: string;
  licensePlateNo?: string;
  licensePlateIssueState?: string;
  eldId?: string;
  notes?: string | undefined;
  vinNo?: string;
  odometerUnit?: string;
  fuelType?: string;
  odometerReading?: number;
  readingDateTime?: string;
  inspectionForm?: string;
  terminalName?: string;
  mainOfficeName?: string;
  tenantId: {};
  isActive?: boolean;
  isSaveAddButton: boolean;
  eldNo?: string;
  eldMongoId?: string;
  serialNo?: string;
  eldDeviceName?: string;
};
export type VehicleResponseType = {
  id?: string;
  vehicleId: string;
  make: string;
  model: string;
  modelYear: string;
  licensePlateNo: string;
  licensePlateIssueState: string;
  eldId: {
    id?: string;
    eldNo?: string;
    serialNo?: string;
    deviceName?: string;
  };
  notes: string;
  vinNo: string;
  odometerUnit: string;
  odometerReading: number;
  fuelType: string;
  readingDateTime: string;
  inspectionForm: string;
  terminalName: string;
  mainOfficeName: string;
  tenantId: {};
  isActive?: boolean;
};
export const VehicleDefaultValues = {
  data: {
    id: '',
    vehicleId: '',
    make: '',
    model: '',
    modelYear: '',
    licensePlateNo: '',
    licensePlateIssueState: '',
    eldId: {
      id: '',
      eldNo: '',
    },
    notes: '',
    vinNo: '',
    odometerUnit: '',
    odometerReading: 0,
    readingDateTime: '',
    inspectionForm: '',
    fuelType: '',
    terminalName: '',
    mainOfficeName: '',
    tenantId: {},
    isActive: false,
    isSaveAddButton: true,
  },
  message: '',
};

export type VehicleResponse = {
  data: VehicleResponseType;
  message: string;
};

export type PaginatedVehicle = {
  data: VehicleType[];
  total: number;
  last_page: number;
  pageNo: number;
};

export type UpdateVehicleStatusType = {
  id: string;
  isActive: boolean;
};

const vehicleapi = {
  getVehicles: async (params: GetVehicleRequest): Promise<PaginatedVehicle> => {
    try {
      const response = await httpRequest.request({
        baseURL: VEHICLE_API_BASE_URL,
        url: '/vehicles',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getVehiclesOptions: async (params: GetVehicleOptionsRequest): Promise<PaginatedVehicle> => {
    try {
      const response = await httpRequest.request({
        baseURL: VEHICLE_API_BASE_URL,
        url: '/vehicles',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getVehicle: async (vehicleId: string): Promise<VehicleResponse> => {
    try {
      if (!isEmpty(vehicleId)) {
        const response = await httpRequest.request({
          baseURL: VEHICLE_API_BASE_URL,
          url: `/vehicles/${vehicleId}`,
          method: 'GET',
        });
        return response;
      }
      return VehicleDefaultValues;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  saveVehicle: async (body: VehicleType): Promise<VehicleResponse> => {
    try {
      const vehicleId = body.id;
      const requestParams: { baseURL: string; url: string; method: 'POST' | 'PUT'; data: VehicleType } = {
        baseURL: VEHICLE_API_BASE_URL,
        url: '/vehicles/add',
        method: 'POST',
        data: body,
      };

      if (!isEmpty(vehicleId)) {
        requestParams.url = `/vehicles/${vehicleId}`;
        requestParams.method = 'PUT';
      }

      const response = await httpRequest.request(requestParams);
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  updateVehicleStatus: async (body: UpdateVehicleStatusType): Promise<VehicleResponse> => {
    const vehicleId = body.id;
    try {
      const response = await httpRequest.request({
        baseURL: VEHICLE_API_BASE_URL,
        url: `/vehicles/status/${vehicleId}`,
        method: 'PATCH',
        data: body,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },
};

export default vehicleapi;
