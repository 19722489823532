import { STATUSE_TYPE_INTO_ENEVT_CODE } from 'constants/config';
import { notification, message as antMessage } from 'antd';
import jwt_decode from 'jwt-decode';

import { UserDetail, UserType } from 'types/user';
import localStorageService from 'helpers/localStorageService';
import moment, { Moment } from 'moment-timezone';

// export const filterSearchString = (recordValue: string | number | boolean, searchString: string): boolean =>
//   searchString?.toString().toLowerCase().includes(String(recordValue).toLowerCase());

export function isEmptyOrNull(value?: string) {
  if (!value) return true;

  return /(null|undefined|^$)/.test(value);
}

export const decodeUser = (jwt: string) => {
  const { userInfo }: { userInfo: UserType } = jwt_decode(jwt);
  return userInfo;
};

export const getTenantId = () => {
  const userInfo: UserDetail = localStorageService.get('userInfo');
  return userInfo?.tenantId || '';
};

const userInfo: UserDetail = localStorageService.get('userInfo');

export function passwordGenerator(len: number) {
  const length = len || 10;
  const string = 'abcdefghijklmnopqrstuvwxyz'; // to upper
  const numeric = '0123456789';
  const punctuation = '@$!%*?&';
  let password = '';
  let character = '';
  while (password.length < length) {
    const entity1 = Math.ceil(string.length * Math.random() * Math.random());
    const entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
    const entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
    let hold = string.charAt(entity1);
    hold = password.length % 2 === 0 ? hold.toUpperCase() : hold;
    character += hold;
    character += numeric.charAt(entity2);
    character += punctuation.charAt(entity3);
    password = character;
  }
  password = password
    .split('')
    .sort(() => 0.5 - Math.random())
    .join('');
  return password.substr(0, len);
}

export const generateUniqueKey = (pre: string) => `${pre}-${new Date().getTime()}`;

export const numberFormat = (val: number) => {
  const formattedNumber = new Intl.NumberFormat('en-US').format(val);
  return formattedNumber;
};

export const currencyFormat = (val: number, currency = '$') => {
  if (!val) return 0;
  return `${currency} ${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const ErrorNotifications = (message: string | string[]) => {
  if (typeof message === 'object') {
    message.forEach(msg => {
      notification.error({ message: msg });
    });
  } else {
    notification.error({ message });
  }
};

export const timestampToHHMM = (milliseconds: number) => new Date(milliseconds).toISOString().slice(11, 16);
// export const timestampToHHMM = (milliseconds: number) => format(parse('18:54:00', 'HH:mm:ss', new Date()), 'hh:mm');

export const convertUnixCompanyTimeZone = (unixValue: number) => {
  const dateFromUnix = moment.unix(unixValue).tz(userInfo?.companyTimeZone || 'Asia/Karachi');
  // dateFromUnix.minutes(new Date().getTimezoneOffset())
  return dateFromUnix;
};

export const convertDateDriverTimeZone = (date: Date | Moment, tzCode: string) =>
  moment(date).tz(tzCode || 'America/Chicago');


export const convertDateCompanyTimeZone = (date: Date | Moment) =>
  moment(date).tz(userInfo?.companyTimeZone || 'America/Chicago');

export const convertDateDriverHomeTimeZone = (date: Date | Moment, timeZone: string) =>
  moment(date).tz(timeZone || 'America/Chicago');

export const convertTimeToMoment = (eventDate: string, eventTime: string) =>
  moment(`${eventDate} ${eventTime}`, 'MMDDYY HHmmss');

export const convertTimeToPixels = (eventTime: string) => {
  const hh = parseInt(eventTime.substring(0, 2), 10);
  const mm = parseInt(eventTime.substring(2, 4), 10);
  const ss = parseInt(eventTime.substring(4, 6), 10);

  const totalSeconds = hh * 3600 + mm * 60 + ss;
  return totalSeconds;
};

export const convertPixelsToTime = (point: number) => {
  const momentFromSeconds = moment().startOf('day').add({ seconds: point });
  const time = momentFromSeconds.format('HHmmss');
  return time;
};

export const getStatusEventCode = (eventCodeValue: string, currentDate: string, convertedDate: string) => {
  let eventCode = '1';
  if (convertedDate >= currentDate) {
    if (eventCodeValue === STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY) {
      eventCode = '1';
    } else if (eventCodeValue === STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH) {
      eventCode = '2';
    } else if (eventCodeValue === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING) {
      eventCode = '3';
    } else if (eventCodeValue === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY) {
      eventCode = '4';
    }
  }
  return eventCode;
};

export const FuelType = [
  'Diesel',
  'Gasoline',
  'Propane',
  'Liquid Natural Gas',
  'Compressed Natural Gas',
  'Ethanol',
  'Methanol',
  'E-85',
  'M-85',
  'A55',
  'Biodiesel',
  'Other',
];

export async function CopyToClipboard(val: string) {
  if (val) {
    await navigator?.clipboard?.writeText(val);
  }
}

export const CalcDuration = (from: number, to: number) => {
  let format = '';
  const durationInSeconds = to - from;
  const time_duration = moment.duration(durationInSeconds, 'seconds');

  const hours: number = Math.floor(time_duration.asHours());
  const minutes: number = Math.floor(time_duration.asMinutes()) % 60;
  const seconds: number = Math.floor(time_duration.asSeconds()) % 60;

  if (hours) {
    format += `${hours}h:`;
  }

  if (minutes) {
    format += `${minutes}m:`;
  }

  if (seconds) {
    format += `${seconds}s`;
  }

  if (format.endsWith(':')) {
    format = format.slice(0, -1);
  }

  if (format === '23h:59m:59s') {
    return '24h';
  }
  return format;
};

export const secondToTime = (totalSecond: number) => {
  let format = '';
  const timeDuration = moment.duration(totalSecond, 'seconds');

  const hours: number = Math.floor(timeDuration.asHours());
  const minutes: number = Math.floor(timeDuration.asMinutes()) % 60;
  const seconds: number = Math.floor(timeDuration.asSeconds()) % 60;

  if (hours) {
    format += `${hours}h:`;
  }

  if (minutes) {
    format += `${minutes}m:`;
  }

  if (seconds) {
    format += `${seconds}s`;
  }

  if (format.endsWith(':')) {
    format = format.slice(0, -1);
  }

  if (format === '23h:59m:59s') {
    return '24h';
  }
  return format;
};
