import { DATE_ONLY } from 'constants/dates';
import { Button, Col, Modal, Row, notification } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { VscTypeHierarchySub } from 'react-icons/vsc';
import * as Yup from 'yup';
import { Form, Select, Input, SubmitButton, FormItem } from 'formik-antd';
import { NormalizeLogFormType } from 'services/logs';
import { CSVGraphStatusType, NormalizeLogsRequestType } from 'types/hos';

import { useNormalizeLogs } from 'hooks/unitHooks';
import { GET_HOS_DRIVER_CSV } from 'hooks/keys';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { queryClient } from 'hooks';
import { DriverResponseType } from 'services/drivers';
import { ReactComponent as Vehicles } from 'resources/icons/Group 73.svg';

import 'styles/modal.less';

const { Option } = Select;

const NormalizeLogModal = ({
  openModal,
  selectedLogEntry,
  onCancel,
  driverDetail,
  resetActionType,
}: {
  openModal: boolean;
  selectedLogEntry?: CSVGraphStatusType | undefined;
  driverDetail: DriverResponseType | undefined;
  onCancel: (value: React.SetStateAction<boolean>) => void;
  resetActionType: () => void;
}) => {
  const location = useLocation();
  const history = useHistory();
  const urlParams = location?.pathname.split('/');
  const driverNameByParams = urlParams[2] || '';

  const saveNormalizeLog = useNormalizeLogs();
  const formInitialValues = {
    type: '0',
    maxSpeed: '',
  };

  const validationSchema = Yup.object().shape({
    type: Yup.string().nullable(false).required('You are required to select type'),
    maxSpeed: Yup.number().when('type', {
      is: '1',
      then: Yup.number()
        .required('Max speed is required')
        .positive('Number must be positive')
        .notOneOf([0, 1, 2, 3, 4, 5, 6, 7, 8, 9], 'Min Speed (10 KMP)')
        .min(5, 'Invalid Max Speed')
        .nullable(false),
      otherwise: Yup.number().nullable(true).notRequired(),
    }),
  });

  const handleSubmitNormalize = (values: NormalizeLogFormType, formikHelpers: FormikHelpers<NormalizeLogFormType>) => {
    formikHelpers.validateForm();
    const finalData: NormalizeLogsRequestType = {
      driverId: driverDetail?.id || '',
      eventSequenceIdNumber: selectedLogEntry!.eventSequenceIdNumber,
      date: moment(selectedLogEntry?.eventDate, 'MMDDYY').format(DATE_ONLY),
      type: values?.type,
      ...(values?.type === '1' && { speed: Number(values?.maxSpeed) }),
      normalizationType: selectedLogEntry?.eventType === '1' ? 0 : 1,
    };

    saveNormalizeLog
      .mutateAsync(finalData)
      .then(response => {
        notification.success({ message: response?.message });
      })
      .catch(error => {
        notification.error({ message: error?.message });
      })
      .finally(() => {
        // history.push(`/logs/${driverNameByParams}`);
        formikHelpers.setSubmitting(false);
        onCancel(false);
        resetActionType();
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      });
  };

  return (
    <Modal
      className="app-modal"
      open={openModal}
      title="Normalize Driving"
      width={520}
      onCancel={() => onCancel(false)}
      footer={null}>
      <div className="normalize-modal-main">
        <Formik initialValues={formInitialValues} validationSchema={validationSchema} onSubmit={handleSubmitNormalize}>
          {formProps => (
            <Form size="large" layout="vertical" initialValues={formInitialValues}>
              <Row gutter={72}>
                <Col span={24}>
                  <p className="justify-text">
                    LOGELD, as your service provider, is not responsible for any financial or legal repercussions
                    resulting from facilitating your request. It is the sole responsibility of the user to maintain
                    legal compliance while using ELD.
                  </p>
                </Col>
                <Col span={24}>
                  <FormItem label="Type" name="type" className="form-label" required>
                    <VscTypeHierarchySub size={20} className="selectIcons" />
                    <div className="iconBorder" />
                    <Select name="type" className="selectOptions">
                      <Option value="0">Auto</Option>
                      <Option value="1">Manual</Option>
                    </Select>
                  </FormItem>
                </Col>
                {formProps?.values?.type === '1' && (
                  <Col span={24}>
                    <FormItem label="Max Speed" name="maxSpeed" className="form-label" required>
                      <Input
                        className="form-control"
                        name="maxSpeed"
                        type="number"
                        placeholder="Enter truck speed (Min 10 KMP and Max 110 KMP)"
                        prefix={<Vehicles width={20} height={20} />}
                      />
                    </FormItem>
                  </Col>
                )}
              </Row>
              <div className="custom-csv-file-footer">
                <Button className="generic-button-class cancel-button-set" onClick={() => onCancel(false)}>
                  Cancel
                </Button>
                <SubmitButton type="primary" htmlType="submit" className="generic-button-class primary-btn-md">
                  Ok
                </SubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  );
};

export default NormalizeLogModal;
