import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { FC } from 'react';

type TimeHandleType = {
  currentValue: string;
  setCurrentValue: (value: string) => void;
  handleFor: string;
  increment: () => void;
  decrement: () => void;
};

export const TimeHandle: FC<TimeHandleType> = ({ currentValue, handleFor, setCurrentValue, increment, decrement }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // if (parseInt(e.target.value, 10) !== 0) {
    // const inputValue = e.target.value;

    // let regex = /^(?:[1-9]?[0-9]|99)(?:\s*)$/;
    // if (handleFor !== 'h') {
    //   regex = /^(?:[0-9]|[1-4][0-9]|5[0-9])$/;
    // }

    // if (regex.test(inputValue)) {
    //   setCurrentValue(inputValue)

    // }

    // }
    if (!e.target.value.length) {
      setCurrentValue(e.target.value);
    } else {
      const only_numbers = /^[0-9]+$/;
      if (only_numbers.test(e.target.value)) {
        const inputValue = parseInt(e.target.value, 10);
        if ((handleFor === 'h' && inputValue < 100) || (handleFor !== 'h' && inputValue < 60)) {
          setCurrentValue(e.target.value);
        }
      }
    }
  };
  return (
    <div className="handle">
      <Button type="primary" icon={<UpOutlined />} ghost style={{ borderColor: 'transparent' }} onClick={increment} />
      <Input className="input" value={currentValue} onChange={handleChange} />
      <Button type="primary" icon={<DownOutlined />} ghost style={{ borderColor: 'transparent' }} onClick={decrement} />
    </div>
  );
};
