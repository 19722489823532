import { OFFICES_API_BASE_URL } from 'constants/config';
import { isEmpty } from 'lodash';

import httpRequest from './config/HttpRequest';

export type GetOfficeRequest = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  isActive?: boolean;
  limit?: number;
};

export type GetOfficeOptionsRequest = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  limit?: string;
};

export type TimeZone = {
  tzCode: string;
  utc?: string;
  label?: string;
  name?: string;
};

export type OfficeType = {
  id?: string;
  name: string;
  address: string;
  phoneNumber: string;
  description?: string | undefined;
  country: string;
  state: string;
  city: string;
  isActive: boolean;
  isHeadOffice: boolean;
  timeZone: string | TimeZone;
  // isSaveAddButton: boolean;
};

export type OfficeResponse = {
  data: OfficeType;
  message: string;
};

export type PaginatedResonse = {
  data: OfficeType[];
  total: number;
  last_page: number;
  pageNo: number;
};

export type UpdateStatusType = {
  id: string;
  isActive: boolean;
};

export const OfficeDefaultValues = {
  data: {
    name: '',
    address: '',
    phoneNumber: '',
    description: '',
    country: '',
    state: '',
    city: '',
    isActive: false,
    isHeadOffice: false,
    timeZone: '',
    // isSaveAddButton: true,
  },
  message: '',
};

const officeapi = {
  getOffices: async (params: GetOfficeRequest): Promise<PaginatedResonse> => {
    try {
      const response = await httpRequest.request({
        baseURL: OFFICES_API_BASE_URL,
        url: '/offices',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getOfficesOptions: async (params: GetOfficeOptionsRequest): Promise<PaginatedResonse> => {
    try {
      const response = await httpRequest.request({
        baseURL: OFFICES_API_BASE_URL,
        url: '/offices',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getOffice: async (officeId: string): Promise<OfficeResponse> => {
    try {
      if (!isEmpty(officeId)) {
        const response = await httpRequest.request({
          baseURL: OFFICES_API_BASE_URL,
          url: `/offices/${officeId}`,
          method: 'GET',
        });
        return response;
      }
      return OfficeDefaultValues;
    } catch (error) {
      throw { ...error };
    }
  },

  saveOffice: async (body: OfficeType): Promise<OfficeResponse> => {
    try {
      const officeId = body.id;
      const requestParams: { baseURL: string; url: string; method: 'POST' | 'PUT'; data: OfficeType } = {
        baseURL: OFFICES_API_BASE_URL,
        url: '/offices/add',
        method: 'POST',
        data: body,
      };

      if (!isEmpty(officeId)) {
        requestParams.url = `/offices/${officeId}`;
        requestParams.method = 'PUT';
      }

      const response = await httpRequest.request(requestParams);
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  updateOfficeStatus: async (body: UpdateStatusType): Promise<OfficeResponse> => {
    const officeId = body.id;
    try {
      const response = await httpRequest.request({
        baseURL: OFFICES_API_BASE_URL,
        url: `/offices/status/${officeId}`,
        method: 'PATCH',
        data: body,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },
};

export default officeapi;
