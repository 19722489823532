import { USE_QUERY_OPTIONS } from 'constants/config';
import { useMutation, useQuery } from 'react-query';
import officeapi, { GetOfficeOptionsRequest, GetOfficeRequest } from 'services/office';

import { GET_OFFICES } from './keys';

export const useGetOffices = (params: GetOfficeRequest) =>
  useQuery([GET_OFFICES, params], () => officeapi.getOffices(params));

export const useGetOfficesOptions = (params: GetOfficeOptionsRequest) =>
  useQuery([GET_OFFICES, params], () => officeapi.getOfficesOptions(params));

export const useGetOffice = (officeId: string) =>
  useQuery(['getOffice', officeId], () => officeapi.getOffice(officeId), {
    ...USE_QUERY_OPTIONS,
    select: ({ data }) => data,
    refetchOnMount: 'always',
  });

export const useSaveOffice = () => useMutation(officeapi.saveOffice, { mutationKey: 'saveOffice' });

export const useUpdateOfficeStatus = () =>
  useMutation(officeapi.updateOfficeStatus, { mutationKey: 'updateOfficeStatus' });
