import { Link, useLocation } from 'react-router-dom';

// import { ReactComponent as Dashboard } from 'resources/icons/Group 117.svg';
import { ReactComponent as AllUnits } from 'resources/icons/Group 67.svg';
import { ReactComponent as ELD } from 'resources/icons/Group 79.svg';
import { ReactComponent as Vehicles } from 'resources/icons/Group 73.svg';
import { ReactComponent as Drivers } from 'resources/icons/Group 70.svg';
// import { ReactComponent as Assets } from 'resources/icons/asset.svg';
import { ReactComponent as Logs } from 'resources/icons/Group 77.svg';
import { ReactComponent as TransferELDData } from 'resources/icons/Group 119.svg';
import { ReactComponent as UnidentifiedEvents } from 'resources/icons/Component116.svg';
import { ReactComponent as Reports } from 'resources/icons/ReportIcon.svg';

import './Sidebar.less';

const Sidebar = ({ sidebarToggle }: { sidebarToggle: boolean }) => {
  const location = useLocation();

  return (
    <div className={`side-nav ${sidebarToggle === true ? 'sidebar-expand' : 'sidebar-close'} `}>
      <ul className="sidebar-group">
        <li className="sidebar-group-emelemnt">
          <ul className="group-items">
            {/* <li className={`item ${location.pathname.startsWith('/dashboard') ? 'active-item' : ''}`}>
              <Link to="/dashboard">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Dashboard
                  </span>
                  <Dashboard />
                </div>
                <span>Dashboard</span>
              </Link>
            </li> */}
            <li className={`item ${location.pathname.startsWith('/units') ? 'active-item' : ''}`}>
              <Link to="/units">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    All Units
                  </span>
                  <AllUnits />
                </div>
                <span>All Units</span>
              </Link>
            </li>
            <li className={`item ${location.pathname.startsWith('/reports') ? 'active-item' : ''}`}>
              <Link to="/reports">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Reports
                  </span>
                  <Reports />
                </div>
                <span>Reports</span>
              </Link>
            </li>
          </ul>
        </li>
        <li className="sidebar-group-emelemnt">
          <ul className="group-items">
            <li className={`item ${location.pathname.startsWith('/eld') ? 'active-item' : ''}`}>
              <Link to="/elds">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    ELDs
                  </span>
                  <ELD />
                </div>
                <span>ELDs</span>
              </Link>
            </li>
            <li className={`item ${location.pathname.startsWith('/vehicle') ? 'active-item' : ''}`}>
              <Link to="/vehicles">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Vehicles
                  </span>
                  <Vehicles />
                </div>
                <span>Vehicles</span>
              </Link>
            </li>
            <li className={`item ${location.pathname.startsWith('/drivers') ? 'active-item' : ''}`}>
              <Link to="/drivers">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Drivers
                  </span>
                  <Drivers />
                </div>
                <span>Drivers</span>
              </Link>
            </li>
            {/* <li className={`item ${location.pathname.startsWith('/asset') ? 'active-item' : ''}`}>
              <Link to="/asset">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Assets
                  </span>
                  <Assets />
                </div>
                <span>Assets</span>
              </Link>
            </li> */}
            <li className={`item ${location.pathname.startsWith('/logs') ? 'active-item' : ''}`}>
              <Link to="/logs">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Logs
                  </span>
                  <Logs />
                </div>
                <span>Logs</span>
              </Link>
            </li>
            {/* <li className={`item ${location.pathname.startsWith('/abc') ? 'active-item' : ''}`}>
              <Link to="/">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Driving Events
                  </span>
                  <DrivingEvents />
                </div>
                <span>Driving Events</span>
              </Link>
            </li> */}
            <li className={`item ${location.pathname.startsWith('/unidentified-events') ? 'active-item' : ''}`}>
              <Link to="/unidentified-events">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Unidentified Events
                  </span>
                  <UnidentifiedEvents />
                </div>
                <span>Unidentified Events</span>
              </Link>
            </li>

            <li className={`item ${location.pathname.startsWith('/Transfer-eld-data') ? 'active-item' : ''}`}>
              <Link to="/Transfer-eld-data">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Transfer ELD Data
                  </span>
                  <TransferELDData />
                </div>
                <span>Transfer ELD Data</span>
              </Link>
            </li>
          </ul>
        </li>
        {/* <li className="sidebar-group-emelemnt">
          <ul className="group-items">
            <li className={`item ${location.pathname.startsWith('/abc') ? 'active-item' : ''}`}>
              <Link to="/group">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Add Groups
                  </span>
                  <AddGroups />
                </div>
                <span>Add Groups</span>
              </Link>
            </li>
          </ul>
        </li> */}
        {/* <li className="sidebar-group-emelemnt">
          <div className="group_divider setting_line" />
          <ul className="group-items">
            <li className={`item ${location.pathname.startsWith('/abc') ? 'active-item' : ''}`}>
              <Link to="/abc">
                <div className="left_nav_icons tooltip">
                  <span className="tooltiptext">
                    <div className="triangle-left" />
                    Settings
                  </span>
                  <Settings />
                </div>
                <span>Settings</span>
              </Link>
            </li>
          </ul>
        </li> */}
      </ul>
    </div>
  );
};
export default Sidebar;
