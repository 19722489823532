import { Divider, Input, Popover } from 'antd';
import { BsFillClockFill } from 'react-icons/bs';
import { useEffect, useState, useCallback } from 'react';
import { TimeHandle } from './TimeHandles';
import './transferTimpicker.less';

export function TransferPicker({
  handleChange,
  selectedLogEntryDuration,
}: {
  handleChange: (val: string) => void;
  selectedLogEntryDuration: string | undefined;
}) {
  const [open, setOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState({
    hours: selectedLogEntryDuration?.split(':')[0] || '23',
    minutes: selectedLogEntryDuration?.split(':')[1] || '59',
    seconds: selectedLogEntryDuration?.split(':')[2] || '59',
  });
  useEffect(() => {
    handleChange(`${currentValue.hours}:${currentValue.minutes}:${currentValue.seconds}`);
  }, [currentValue]);

  const handleOpenChange = (newOpen: boolean) => {
    setOpen(newOpen);
  };
  useEffect(() => {
    const hours = currentValue.hours.length > 1 ? currentValue.hours : `0${currentValue.hours}`;
    const minutes = currentValue.minutes.length > 1 ? currentValue.minutes : `0${currentValue.minutes}`;
    const seconds = currentValue.seconds.length > 1 ? currentValue.seconds : `0${currentValue.seconds}`;

    if (!open) {
      setCurrentValue({
        hours,
        minutes,
        seconds,
      });
    }
  }, [open]);

  const handleValueChange = useCallback((key: keyof typeof currentValue, val: string) => {
    setCurrentValue(prevValue => {
      // const newValue = val.length === 1 ? `0${val}` : val;
      const newValue = val;
      if (key === 'minutes') {
        const minuteValue = parseInt(newValue, 10);
        if (minuteValue === 0 && prevValue.minutes === '59') {
          // If minutes change from 59 to 0, increase the hour value
          const tempHour = String((Number(prevValue.hours) + 1) % 99);
          return {
            ...prevValue,
            [key]: newValue,
            // hours: tempHour.length === 1 ? `0${tempHour}` : tempHour,
            hours: String((Number(prevValue.hours) + 1) % 99),
          };
        }
        if (minuteValue === 59 && prevValue.minutes === '00') {
          // If minutes change from 0 to 59, decrease the hour value
          const tempHour = String((Number(prevValue.hours) - 1 + 99) % 99);
          return {
            ...prevValue,
            [key]: newValue,
            // hours: tempHour.length === 1 ? `0${tempHour}` : tempHour,
            hours: String((Number(prevValue.hours) - 1 + 99) % 99),
          };
        }
      } else if (key === 'seconds') {
        const secondValue = parseInt(newValue, 10);
        if (secondValue === 0 && prevValue.seconds === '59') {
          // If seconds change from 59 to 0, increase the minute value
          if (prevValue.minutes === '59') {
            // If minutes are also 59, increase the hour value
            return {
              hours: String((Number(prevValue.hours) + 1) % 99),
              minutes: '00',
              seconds: newValue,
            };
          }
          // Otherwise, just increase the minute value
          const tempMinutes = String((Number(prevValue.minutes) + 1) % 60);
          return {
            ...prevValue,
            // minutes: tempMinutes.length === 1 ? `0${tempMinutes}` : tempMinutes,
            minutes: String((Number(prevValue.minutes) + 1) % 60),
            seconds: newValue,
          };
        }
        if (secondValue === 59 && prevValue.seconds === '00') {
          // If seconds change from 0 to 59, decrease the minute value
          const tempMinutes = String((Number(prevValue.minutes) - 1 + 60) % 60);
          return {
            ...prevValue,
            // minutes: tempMinutes.length === 1 ? `0${tempMinutes}` : tempMinutes,
            minutes: String((Number(prevValue.minutes) - 1 + 60) % 60),
            seconds: newValue,
          };
        }
      }
      return { ...prevValue, [key]: newValue };
    });
  }, []);

  const handleIncrease = () => {
    handleValueChange('minutes', String((Number(currentValue.minutes) + 1) % 60));
  };

  const handleDecrease = () => {
    handleValueChange('minutes', String((Number(currentValue.minutes) - 1 + 60) % 60));
  };

  const increaseSeconds = () => {
    handleValueChange('seconds', String((Number(currentValue.seconds) + 1) % 60));
  };

  const decreaseSeconds = () => {
    handleValueChange('seconds', String((Number(currentValue.seconds) - 1 + 60) % 60));
  };

  return (
    <div style={{ position: 'relative', marginBottom: '10px' }}>
      <Popover
        content={
          <div className="time-inputs">
            <TimeHandle
              currentValue={currentValue.hours}
              setCurrentValue={val => handleValueChange('hours', val)}
              handleFor="h"
              increment={() => handleValueChange('hours', String((Number(currentValue.hours) + 1) % 99))}
              decrement={() => handleValueChange('hours', String((Number(currentValue.hours) - 1 + 99) % 99))}
            />
            <Divider type="vertical"> : </Divider>
            <TimeHandle
              currentValue={currentValue.minutes}
              setCurrentValue={val => handleValueChange('minutes', val)}
              handleFor="m"
              increment={handleIncrease}
              decrement={handleDecrease}
            />
            <Divider type="vertical"> : </Divider>
            <TimeHandle
              currentValue={currentValue.seconds}
              setCurrentValue={val => handleValueChange('seconds', val)}
              handleFor="s"
              increment={increaseSeconds}
              decrement={decreaseSeconds}
            />
          </div>
        }
        title="Title"
        trigger="click"
        open={open}
        placement="top"
        overlayClassName="TransferPicker"
        onOpenChange={handleOpenChange}>
        <Input
          id="timeInput"
          value={`${currentValue.hours}:${currentValue.minutes}:${currentValue.seconds}`}
          prefix={<BsFillClockFill width={20} height={20} />}
          autoFocus
          name="duration"
        />
      </Popover>
    </div>
  );
}
