import { Col, Row } from 'antd';
import { Route, Switch, useRouteMatch } from 'react-router';

import BreadCrumb from 'components/breadcrumb/BreadCrumb';
import Error404 from 'components/ServerErrors/404Error/Error404';
import Footer from 'components/layouts/footer/Foot';
// import TransferLogs from './components/TransferLogs/TransferLogs';
// import NormalizeLogs from './components/NormalizeLogs/NormalizeLogs';

import LogsList from './components/LogsList/LogsList';
// import EditLogs from './components/EditLogs/EditLogs';
// import EditLogStatus from './components/EditLogs/Partials/EditLogStatus/EditLogStatus';
import SingleDayLog from './components/SingleDayLog/SingleDayLog';
import InspectionReport from './components/SingleDayLog/Partials/InspectionReport/InspectionView/InspectionReport';

function LogsContainer() {
  const { url } = useRouteMatch();

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <BreadCrumb />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Switch>
            <Route exact path={url} render={(): JSX.Element => <LogsList />} />
            <Route exact path={`${url}/:name`} render={(): JSX.Element => <SingleDayLog />} />
            {/* <Route exact path={`${url}/:name/edit-log`} render={(): JSX.Element => <EditLogs />} /> */}
            {/* <Route exact path={`${url}/:name/edit-log/:date`} render={(): JSX.Element => <EditLogStatus />} /> */}

            {/* <Route exact path={`${url}/:name/transfer-log`} render={(): JSX.Element => <TransferLogs />} />
            <Route exact path={`${url}/:name/normalize-log`} render={(): JSX.Element => <NormalizeLogs />} /> */}

            <Route exact path={`${url}/:name/inspection-report`} render={(): JSX.Element => <InspectionReport />} />

            <Route path="/PageNotFound" render={(): JSX.Element => <Error404 />} />
            <Route path="*" component={Error404} />
          </Switch>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </>
  );
}
export default LogsContainer;
