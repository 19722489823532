import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import Login from './pages/Login';
import ResetPassword from './pages/ResetPassword/ResetPassword';

function AuthContainer() {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Redirect path={url} exact to={`${url}/login`} />
      <Redirect path={url} exact to={`${url}/forgotpassword`} />
      <Redirect path={url} exact to={`${url}/resetPassword`} />
      <Route path={`${url}/login`} render={(): JSX.Element => <Login />} />
      <Route path={`${url}/forgotpassword`} render={(): JSX.Element => <ForgotPassword />} />
      <Route path={`${url}/resetPassword`} render={(): JSX.Element => <ResetPassword />} />
    </Switch>
  );
}

export default AuthContainer;
