import { Modal } from 'antd';

import 'styles/modal.less';


type Props = {
  title: string;
  body: () => JSX.Element;
  onCancel: (val: boolean) => void;
  onSubmit: () => void;
  width?: number;
  okText?: string;
  cancelButtonProps?: {};
  okButtonProps?: {};
};

const AppModal = ({
  title,
  body,
  onCancel,
  onSubmit,
  width = 520,
  okText,
  cancelButtonProps,
  okButtonProps,
}: Props) => (
  <Modal
    className="app-modal"
    title={title}
    visible
    onCancel={() => onCancel(false)}
    onOk={() => onSubmit()}
    okText={okText}
    cancelButtonProps={cancelButtonProps}
    okButtonProps={okButtonProps}
    centered
    width={width}>
    {body()}
  </Modal>
);

export default AppModal;
