import React, { useRef, useState } from 'react';
import { CalcDuration, convertPixelsToTime, convertTimeToPixels } from 'helpers/utils';
import moment from 'moment';
import 'moment-duration-format';
import leftSvg from 'resources/icons/Graph_left.svg';
import rightSvg from 'resources/icons/Graph_right.svg';
import { message } from 'antd';
/* eslint-disable  @typescript-eslint/no-explicit-any */

const Slider = ({
  range,
  selectedLog,
  currentDateInZone,
  setSelectedRange,
  rightLimit,
  visibleTimeFrom,
  visibleTimeTo,
  setSliderChange,
}: {
  range: any;
  selectedLog: any;
  currentDateInZone: moment.Moment;
  setSelectedRange: any;
  rightLimit: number;
  visibleTimeFrom: boolean;
  visibleTimeTo: boolean;
  setSliderChange: any;
}) => {
  const [x1, setX1] = useState(0); // Top-left x-coordinate
  const [x2, setX2] = useState(0); // Bottom-right x-coordinate
  const [startTime, setStartTime] = useState('hh:mm:ss a');
  const [endTime, setEndTime] = useState('hh:mm:ss a');
  const [draggingLeft, setDraggingLeft] = useState(false);
  const [draggingRight, setDraggingRight] = useState(false);
  const leftDraggableRef = useRef(null);
  const rightDraggableRef = useRef(null);
  const [scale, setScale] = useState<number>(58.5);
  const setRanges = () => {
    setX1(convertTimeToPixels(range.from));
    if (range.to === '235959') {
      const logDate = selectedLog?.eventDate;
      if (logDate === currentDateInZone.format('MMDDYY')) {
        setX2(convertTimeToPixels(currentDateInZone.format('HHmmss')));
      } else {
        setX2(convertTimeToPixels(range.to));
      }
    } else {
      setX2(convertTimeToPixels(range.to));
    }
  };

  const handleMouseMove = (e: any) => {
    e.preventDefault();
    if (draggingLeft) {
      const newX1 = x1 + e.movementX * scale;
      if (newX1 >= 0 && newX1 < x2) {
        setX1(newX1 < 59 ? 0 : newX1);
      }
    }
    if (draggingRight) {
      const newX2 = x2 + e.movementX * scale;
      if (newX2 > x1 && newX2 <= rightLimit) {
        setX2(newX2 > 86341 ? 86399 : newX2);
      }
    }
  };
  const handleMouseUp = () => {
    setDraggingLeft(false);
    setDraggingRight(false);
    setSliderChange(true);
    setSelectedRange({ ...range, from: convertPixelsToTime(x1), to: convertPixelsToTime(x2) });
  };

  const handleLines = React.useMemo(
    () => (
      <>
        <rect
          x={x1}
          y={0}
          width={125}
          height="10000"
          fill="#2b5f8c"
          style={{ zIndex: 0 }}
          ref={leftDraggableRef}
          className={`${visibleTimeFrom ? 'd-none' : ''}`}
        />
        <rect
          x={x2}
          y={0}
          width={125}
          height="10000"
          fill="#2b5f8c"
          style={{ zIndex: 0 }}
          ref={rightDraggableRef}
          className={`${visibleTimeTo ? 'd-none' : ''}`}
        />
      </>
    ),
    [x1, x2],
  );

  const handleTimeBoxes = React.useMemo(
    () => (
      <>
        <foreignObject
          x={x1 - 4400}
          y={-2000}
          width="4500"
          height="2000"
          className={`${visibleTimeFrom ? 'd-none' : ''}`}>
          <div className="slider-timebox">
            <div>{startTime}</div>
          </div>
        </foreignObject>
        <foreignObject x={x2} y={-2000} width="4500" height="2000" className={`${visibleTimeTo ? 'd-none' : ''}`}>
          <div className="slider-timebox">
            <div>{endTime}</div>
          </div>
        </foreignObject>
      </>
    ),
    [x1, x2, startTime, endTime],
  );

  const duration = React.useMemo(() => {
    const format = CalcDuration(x1, x2);
    return format;
  }, [x1, x2, startTime, endTime]);

  const higlightedbox = React.useMemo(
    () => (
      <rect
        x={Math.min(x1, x2)}
        y={0}
        width={Math.abs(x2 - x1)}
        height="10000"
        fill="#2b5f8c"
        style={{ opacity: '0.6', zIndex: 0 }}
      />
    ),
    [x1, x2],
  );

  React.useEffect(() => {
    if (draggingLeft || draggingRight) {
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    } else {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);


    };
  }, [draggingLeft, draggingRight, x1, x2, rightLimit]);

  React.useEffect(() => {
    setStartTime(moment().startOf('day').add({ seconds: x1 }).format('hh:mm:ss a'));
    setEndTime(moment().startOf('day').add({ seconds: x2 }).format('hh:mm:ss a'));
  }, [x1, x2, setX1, setX2]);
  React.useEffect(() => {
    if (range?.from && range?.to) {
      setRanges();
    }
  }, [range]);
  React.useLayoutEffect(() => {
    if (window.innerWidth > 1800) {
      setScale(58.5);
    } else if (window.innerWidth >= 1200 && window.innerWidth < 1800) {
      setScale(77.9);
    } else {
      setScale(77.9);
    }
  }, [window.innerWidth]);

  return (
    <g id="slider">
      {higlightedbox}
      {/* handle lines */}
      {handleLines}
      {/* time rects boxes */}
      {/* <rect x={x1 - 63} y={-35} width={65} height="35" fill="#2b5f8c" /> */}
      {/* <rect x={x2} y={-35} width={65} height="35" fill="#2b5f8c" /> */}

      {/* time text boxes */}
      {handleTimeBoxes}

      {/* Dragables */}
      <foreignObject x={-1600} y={10000} width="90400" height={2000}>
        <div style={{ position: 'relative', width: '100%' }}>
          <img
            src={leftSvg}
            className={`slider-image ${visibleTimeFrom ? 'd-none' : ''}`}
            alt="lefthandle"
            role="presentation"
            style={{
              left: x1,
              pointerEvents: visibleTimeFrom ? 'none' : 'all',
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              if (!visibleTimeFrom) {
                setDraggingLeft(true);
              }
            }}
          />
          <img
            src={rightSvg}
            className={`slider-image ${visibleTimeTo ? 'd-none' : ''}`}
            alt="righthandle"
            role="presentation"
            style={{
              left: x2 + 1600,
              pointerEvents: visibleTimeTo ? 'none' : 'all',
            }}
            onMouseDown={(e) => {
              e.preventDefault();
              if (!visibleTimeTo) {
                setDraggingRight(true);
              }
            }}
          />
        </div>
      </foreignObject>

      <g>
        <rect
          x={Math.min(x1, x2) + Math.abs(x2 - x1) / 2 - 3000}
          y={12500}
          stroke="#acacac"
          strokeWidth="50"
          rx="150"
          ry="150"
          className="duration"
        />
        <text
          x={Math.min(x1, x2) + Math.abs(x2 - x1) / 2}
          y={13300}
          textAnchor="middle"
          alignmentBaseline="middle"
          className="duration-text">
          {duration}
        </text>
      </g>
    </g>
  );
};
export default React.memo(Slider);
