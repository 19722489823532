import { useState } from 'react';
import { Button, Col, Row, message } from 'antd';
import { isEmpty } from 'lodash';
import { svgIcons } from 'resources/icons';
import { GetLogFormResonse } from 'services/unit';
import UpdtaeLogFormModal from './UpdateLogFormModel/UpdateLogFormModal';

import './LogForm.less';

const LogForm = ({ logFormData, date }: { logFormData: GetLogFormResonse | undefined; date: string }) => {
  const [showUpdateLogFormModal, setShowUpdateLogFormModal] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const success = (e: React.MouseEvent<HTMLElement>) => {
    messageApi.open({
      type: 'success',
      content: 'Image path copied to clipboard!',
      duration: 1,
      className: 'custom-class',
      style: {
        marginTop: `${e.clientY}px`,
        marginLeft: `${200 - e.clientX + 200}px`,
      },
    });
  };

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    navigator.clipboard.writeText(logFormData?.data?.sign?.imageName!).then(() => {
      success(e);
    });
  };
  return (
    <>
      <Row>
        <Col span={24}>
          <div className="generic-card-large LogEvents">
            <div className="logform_section">
              <div className="logform_header">
                <p className="pg-heading">Log Form</p>
                <Button className="tbl-top-btn" onClick={() => setShowUpdateLogFormModal(true)}>
                  Correction & Annotation
                </Button>
              </div>
              <Row gutter={80}>
                <Col span={12}>
                  <div className="hr-line" />
                  <ul className="sub-childs">
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Driver</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">
                            {logFormData?.data?.driverFirstName || ''} {logFormData?.data?.driverLastName || ''}{' '}
                            {logFormData?.data?.manualDriverId ? `(${logFormData?.data?.manualDriverId})` : ''}
                          </span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Vehicle</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">{logFormData?.data?.manualVehicleId}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Carrier</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">{logFormData?.data?.carrier}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Trailer Number</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">
                            {Array.isArray(logFormData?.data?.trailerNumber)
                              ? logFormData?.data?.trailerNumber?.join(', ')
                              : logFormData?.data?.trailerNumber}
                          </span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Distance</span>
                        </Col>

                        <Col span={16}>
                          <span className="p-body">{`${logFormData?.data?.distance || '0'} mi` || ''}</span>
                        </Col>
                      </Row>
                    </li>
                    {logFormData?.data?.sign?.imagePath ? (
                      <li className="single-item">
                        <Row gutter={20}>
                          <Col
                            span={8}
                            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                            className="singleItemCol">
                            <span className="content-title-bold">Driver Signature</span>
                          </Col>

                          <Col span={16}>
                            <div style={{ display: 'flex' }}>
                              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                <span className="p-body">I certify that these entries are true and correct.</span>
                              </div>
                              <div
                                className="imgSignature"
                                style={{
                                  width: '200px',
                                  height: '60px',
                                  cursor: 'pointer',
                                  alignItems: 'center',
                                  paddingTop: '10px',
                                }}
                                onClick={handleClick}
                                aria-hidden="true">
                                {contextHolder}

                                <img
                                  src={logFormData?.data?.sign?.imagePath}
                                  alt="driverSignature"
                                  style={{ width: '100%', height: '100%' }}
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </li>
                    ) : (
                      <li className="single-item1">
                        <Row gutter={20}>
                          <Col span={8} className="singleItemCol">
                            <span className="content-title-bold">Driver Signature</span>
                          </Col>

                          <Col span={16}>
                            <span className="p-body">I certify that these entries are true and correct.</span>

                            <div className="InspectionTooltip">
                              <svgIcons.errorIcon />
                              <span className="tooltiptext">
                                <div
                                  className="CustomtooltipText"
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '29px',
                                    width: '109px',
                                    color: 'red',
                                  }}>
                                  <p>No Signature</p>
                                </div>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </li>
                    )}
                  </ul>
                </Col>

                <Col span={12}>
                  <div className="hr-line" />
                  <ul className="sub-childs">
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Main Office</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">{logFormData?.data?.headOffice}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">Home Terminal</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">{logFormData?.data?.homeTerminalAddress}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">From Address</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">{logFormData?.data?.from}</span>
                        </Col>
                      </Row>
                    </li>
                    <li className="single-item">
                      <Row gutter={20}>
                        <Col span={8} className="singleItemCol">
                          <span className="content-title-bold">To Address</span>
                        </Col>
                        <Col span={16}>
                          <span className="p-body">{logFormData?.data?.to}</span>
                        </Col>
                      </Row>
                    </li>
                    {logFormData?.data?.shippingDocument ? (
                      <li className="single-item">
                        <Row gutter={20}>
                          <Col span={8} className="singleItemCol">
                            <span className="content-title-bold">Shipping IDs</span>
                          </Col>
                          <Col span={16}>
                            <span className="p-body">
                              {Array.isArray(logFormData?.data?.shippingDocument)
                                ? logFormData?.data?.shippingDocument?.join(', ')
                                : logFormData?.data?.shippingDocument}
                            </span>
                          </Col>
                        </Row>
                      </li>
                    ) : (
                      <li className="single-item1" style={{ background: '#f8d7da 0% 0% no-repeat padding-box' }}>
                        <Row gutter={20}>
                          <Col span={8} className="singleItemCol">
                            <span className="content-title-bold">Shipping IDs</span>
                          </Col>
                          <Col span={16}>
                            <span className="p-body"> </span>
                          </Col>
                        </Row>
                      </li>
                    )}
                  </ul>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      {showUpdateLogFormModal && (
        <UpdtaeLogFormModal
          setShowUpdateLogFormModal={setShowUpdateLogFormModal}
          openModal={showUpdateLogFormModal}
          logFormData={logFormData}
          date={date}
        />
      )}
    </>
  );
};

export default LogForm;
