export const SHORT_DATE_FULL_TIME = 'MMM D, h:mm A';
export const FULL_DATE_TIME = 'MMMM D, YYYY h:mm A';
export const NEW_FULL_DATE_TIME = 'M-DD-YYYY, h:mm A';
export const DATE_ONLY = 'YYYY-MM-DD';
export const TZ_FULL_DATE_TIME = 'hh:mm a z MMM D';
export const HOURS_MINUTES_SECONDS = ` HH [hrs] mm [min] ss [sec]`;
export const HOURS_MINUTES = 'HH:mm';
export const HOURS_MINUTES_SMALL_A = 'hh:mm a';
export const HOURS_MINUTES_SECONDS_A = 'hh:mm:ss A';
export const HOURS_MINUTES_SECONDS_SMALL_A = 'hh:mm:ss a';
export const HOURS_MINUTES_SECONDS_H = 'HH:mm:ss';
export const MONTH_DAY = 'MMM DD';
export const YYYY = 'YYYY';
export const MONTH_DAY_YEAR = 'MM-DD-YYYY';
export const DEFAULT_MONTH_DAY_YEAR = 'MM/DD/YYYY';
export const DAY_MONTH_YEAR = 'DD/MM/YYYY';
export const MMM_DD_YYYY = 'MMM DD,YYYY';
export const DD_MMM = 'DD MMM';
export const DEFAULT_DATE_FORMATE = 'DD/MM/YY hh:mm:ss A';
export const DEFAULT_DATE_FORMATE_H_M_S = 'DD/MM/YYYY hh:mm A';
export const DATE_MONTH_YEAR_FORMATE = 'DD/MM/YYYY h:mm a';
export const MM_DD_YYYY_HH_MM_SS_A = 'MM/DD/YYYY hh:mm:ss A';
export const DEFAULT_EVENT_MMDDYY = 'MMDDYY';
export const DEFAULT_EVENT_HHMMSS = 'HHMMSS';
