// Login Mutations
export const LOGIN_MUTATION = 'loginMutation';

// User
export const GET_USERS = 'getUsers';

// Driver
export const GET_DRIVER = 'getDrivers';

// Vehicle
export const GET_VEHICLE = 'getVehicles';

// ELDs
export const GET_ELDS = 'getElds';

// Assets
export const GET_ASSETS = 'getAssets';

// Comapny
export const GET_COMPANY = 'getCompany';

// Offices
export const GET_OFFICES = 'getOffices';

// Roles
export const GET_ROLES = 'getRoles';

// Permissions
export const GET_PERMISSIONS = 'getPermissions';

// HOS History Data
export const GET_HOS = 'getHistroy';

// HOS Live Data
export const GET_HOS_LIVE = 'getHOSLive';

// HOS Profile Data
export const GET_HOS_PROFILE = 'getHOSProfile';

// Driver History Logs
export const GET_HOS_DRIVER_LOGS = 'getDriverHistoryLog';

// Driver Graph Data
export const GET_HOS_DRIVER_GRAPH_DATA = 'getDriverGraphData';

// Driver Graph CSV
export const GET_HOS_DRIVER_CSV = 'getDriverCSV';

// Driver Live History CSV
export const GET_HOS_DRIVER_LIVE_HISTORY = 'getDriverLiveHistoryCSV';

// Driver Live History CSV
export const GET_HOS_DRIVER_TRACKING_HISTORY = 'getHosDriverTrakingHistoryCSV';

export const GET_HOS_DRIVER_TRIP_TRACKING_HISTORY = 'getHosDriverTripTrakingHistoryCSV';

export const GET_HISTORY = 'getHistory';

export const GET_HISTORY_DETAILS = 'getHistoryDetails';

export const GET_HOS_DRIVER_EDITED_HISTORY = 'getDriverEditedHistory';

// Units
export const GET_UNITS = 'getUnits';

// Single Unit
export const GET_SINGLE_UNIT_BY_DRIVERID = 'getSingleUnitByDriverId';

// HOS Clock
export const GET_HOS_CLOCK = 'getClock';

// Log Form
export const GET_LOG_FORM = 'getLogForm';

// Inspection

export const GET_INSPECTION = 'getInspection';

export const GET_INSPECTION_REPORT = 'getInspectionReport';

export const GET_ALL_CSV_REPORT = 'getAllCSVReport';

// Cache
export const GET_CACHE_DATA = 'getCacheData';

// Unidentified Events
export const GET_UNIDENTIFIED_EVENTS = 'getUnidentifiedEvents';

export const GET_UNIDENTIFIED_ACUMOLATED_HOURS = 'getUnidentifiedAcumolatedHours';

export const GET_UNIDENTIFIED_ASSIGN_EVENTS = 'getUnidentifiedAssignEvents';

export const GET_ALLUNCERTIFY_DATES = 'getAllUncertifyDates';
// IFTA Reports

export const GET_ALL_IFTA_REPORTS = 'getAllIFTAReports';

export const GET_LOCATION_LAT_LONG = 'getLocationLatLong';

export const GET_ADDRESS_COORDINATES = 'getAddressCoordinates';
