import { STATUSE_TYPE_INTO_ENEVT_CODE } from 'constants/config';
import { Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import 'moment-duration-format';
import moment from 'moment';
import { CSVGraphStatusType } from 'types/hos';

const LogsEventTableColumns = (vehicleId: string): ColumnsType<CSVGraphStatusType> => [
  {
    title: '#',
    width: '8%',
    render: (text, record, index) => {
      /* eslint-disable no-plusplus */
      let indexNumber = index;
      return ++indexNumber;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '12%',
    render: (tag, record) => {
      let statusColor = '';
      let tagName = '';
      let tagStatus = '';
      if (record.eventType === '1') {
        if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY) {
          statusColor = '#586973';
          tagName = 'OFF';
          tagStatus = 'Off Duty';
        } else if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY) {
          statusColor = '#2b5f8c';
          tagName = 'ON';
          tagStatus = 'On Duty';
        } else if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING) {
          statusColor = '#52C41A';
          tagName = 'DR';
          tagStatus = 'Driving';
        } else if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH) {
          statusColor = '#FF9933';
          tagName = 'SB';
          tagStatus = 'Sleeper Berth';
        } else {
          tagStatus = 'Off Duty';
        }
      } else if (record?.eventType === '2') {
        tagStatus = 'Int. Location';
      } else if (record?.eventType === '3') {
        if (record?.eventCode === '1') {
          statusColor = '#b5b5b5';
          tagName = 'PC';
          tagStatus = 'Personal Conveyance';
        } else if (record?.eventCode === '2') {
          statusColor = '#C0E1F6';
          tagName = 'YM';
          tagStatus = 'Yard Move';
        }
      } else if (record?.eventType === '6') {
        if (record?.eventCode === '1' || record?.eventCode === '2') {
          tagStatus = 'Power Up';
        } else if (record?.eventCode === '3' || record?.eventCode === '4') {
          tagStatus = 'Power Down';
        }
      }
      return (
        <>
          {record?.eventType === '1' || record?.eventType === '3' ? (
            <>
              <Tag color={statusColor} key={tag} className="statusTag">
                <>{tagName}</>
              </Tag>
              <span style={{ fontSize: '12px', display: 'block' }} className="statusTagText">
                <>{tagStatus}</>
              </span>
            </>
          ) : (
            <>
              <span style={{ fontSize: '12px', display: 'block' }} className="statusTagText">
                {tagStatus}
              </span>
            </>
          )}
        </>
      );
    },
  },
  {
    title: 'Start Time (CST)',
    dataIndex: 'eventTime',
    key: 'eventTime',
    width: '15%',
    render(text, record) {
      return moment(record?.eventTime, 'HH:mm:ss A').format('hh:mm:ss A');
    },
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    width: '14%',
  },
  {
    title: 'Location',
    dataIndex: 'address',
    key: 'address',
    width: '17%',
   },

  {
    title: 'Odometer',
    dataIndex: ' totalVehicleMilesDutyStatus',
    key: ' totalVehicleMilesDutyStatus',
    width: '9%',
    render(text, record) {
      return <>{record?.totalVehicleMilesDutyStatus}</>;
    },
  },
  {
    title: 'Eng. Hours',
    dataIndex: 'totalEngineHoursDutyStatus',
    key: 'totalEngineHoursDutyStatus',
    width: '10%',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
    width: '13%',
    ellipsis: true,
  },
];

export default LogsEventTableColumns;
