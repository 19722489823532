import { Button, Col, Modal, Row, Checkbox, Image, Spin, notification } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { Form, Input, SubmitButton, FormItem } from 'formik-antd';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';
import { BiPen } from 'react-icons/bi';
import InfoModal from 'components/modal/modalInfo';
import moment from 'moment';
import { CertifiedSignatueRequestType, UncertifiedDatesRequestType, UnitType } from 'types/hos';
import { useCertificationDates, useGetAllUncertifyDates } from 'hooks/inspectionHooks';
import { queryClient } from 'hooks';
import { GET_ALLUNCERTIFY_DATES } from 'hooks/keys';

// import 'components/modal/modal.less';
import 'styles/modal.less';
import './Certification.less';

interface CertificateTypeForm {
  signature: string;
}

const Certification = ({
  openModal,
  onCancel,
  setShowCertifyLogModal,
  driverId,
  singleUnitDetails,
}: {
  openModal: boolean;
  onCancel: (value: React.SetStateAction<boolean>) => void;
  setShowCertifyLogModal: React.Dispatch<React.SetStateAction<boolean>>;
  driverId: string;
  singleUnitDetails: UnitType | undefined;
}) => {
  const [filters, setFilters] = useState<UncertifiedDatesRequestType>({
    // eslint-disable-next-line object-shorthand
    driverId: driverId,
    dateStart: moment().subtract(13, 'days').format('YYYY-MM-DD'),
    dateEnd: moment().subtract(1, 'days').format('YYYY-MM-DD'),
  });
  let getUserInfo = null;
  let loginUserData = null;
  try {
    getUserInfo = localStorage.getItem('userInfo');
    loginUserData = JSON.parse(getUserInfo || '');
  } catch (err) {
    throw new Error('Not Found');
  }
  const url = `https://eld-uploads.s3.amazonaws.com/${loginUserData?.tenantId}/${driverId}/Signatures/`;
  const CheckboxGroup = Checkbox.Group;
  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>();
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const formInitialValues = {
    signature: singleUnitDetails?.imageName?.split('/')[6] || '',
  };

  const { isLoading, data } = useGetAllUncertifyDates({ ...filters });
  const saveCertifySignature = useCertificationDates();

  const onChange = (list: CheckboxValueType[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < data?.data.length!);
    setCheckAll(list.length === data?.data.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? data?.data : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  const handleCertifySubmit = (values: CertificateTypeForm, formikHelpers: FormikHelpers<CertificateTypeForm>) => {
    formikHelpers.validateForm();

    const finalData: CertifiedSignatueRequestType = {
      signature: url + values.signature,
      // eslint-disable-next-line object-shorthand
      driverId: driverId,
      dates: checkedList,
    };
    saveCertifySignature
      .mutateAsync(finalData)
      .then(response => {
        notification.success({ message: response?.message });
        queryClient.refetchQueries([GET_ALLUNCERTIFY_DATES]);
        setShowCertifyLogModal(false);
      })
      .catch(error => {
        if (error.status) {
          notification.error({ message: error.status });
        } else {
          notification.error({ message: error?.message });
        }
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  const certificationNoSignatureModalBody = () => (
    <p className="modal-body-title">
      There is no signature available against this driver please go back to mobile and add signature.
    </p>
  );
  const certificationNoCertificationModalBody = () => (
    <p className="modal-body-title">All days signature have been successfully certified.</p>
  );
  const certificationModalSubmit = () => {
    setShowCertifyLogModal(false);
  };

  return (
    <Spin spinning={isLoading}>
      <>
        {singleUnitDetails?.imageName && data?.data?.length! > 0 ? (
          <Modal
            className="app-modal"
            open={openModal}
            title="Certify"
            width={620}
            onCancel={() => onCancel(false)}
            footer={null}>
            <div className="certify-modal-main">
              <Formik initialValues={formInitialValues} onSubmit={handleCertifySubmit}>
                {formProps => (
                  <Form size="large" layout="vertical" initialValues={formInitialValues}>
                    <Row gutter={72}>
                      <Col span={24}>
                        <FormItem label="Driver Signature" name="signature" className="form-label" required>
                          <Input
                            defaultValue={singleUnitDetails?.imageName}
                            className="form-control"
                            name="signature"
                            type="text"
                            placeholder="Enter driver signature"
                            prefix={<BiPen width={20} height={20} />}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={72}>
                      <Col span={24}>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                          Check all
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <CheckboxGroup options={data?.data} value={checkedList} onChange={onChange} />
                      </Col>
                    </Row>
                    <Row gutter={72}>
                      <Col>
                        {singleUnitDetails?.imageName && (
                          <>
                            <Image
                              src={singleUnitDetails?.imageName}
                              style={{ borderRadius: '5px' }}
                              width={100}
                              height={100}
                              preview={false}
                            />
                          </>
                        )}
                      </Col>
                    </Row>
                    <div className="custom-csv-file-footer">
                      <Button className="generic-button-class cancel-button-set" onClick={() => onCancel(false)}>
                        Cancel
                      </Button>
                      <SubmitButton
                        type="primary"
                        htmlType="submit"
                        disabled={!checkAll && !indeterminate}
                        className="generic-button-class primary-btn-md">
                        Ok
                      </SubmitButton>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Modal>
        ) : (
          [
            singleUnitDetails?.imageName && data?.data.length! === 0 ? (
              <InfoModal
                title="Certify"
                body={certificationNoCertificationModalBody}
                onSubmit={() => certificationModalSubmit()}
              />
            ) : (
              [
                singleUnitDetails?.imageName && data?.data.length! > 0 ? (
                  <InfoModal
                    title="Certify"
                    body={certificationNoSignatureModalBody}
                    onSubmit={() => certificationModalSubmit()}
                  />
                ) : (
                  [
                    !singleUnitDetails?.imageName && (
                      <InfoModal
                        title="Certify"
                        body={certificationNoSignatureModalBody}
                        onSubmit={() => certificationModalSubmit()}
                      />
                    ),
                  ]
                ),
              ]
            ),
          ]
        )}
      </>
    </Spin>
  );
};

export default Certification;
