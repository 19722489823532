import { INSPECTION_API_BASE_URL } from 'constants/config';
import { CertifiedSignatueRequestType, UncertifiedDatesRequestType } from 'types/hos';
import httpRequest from './config/HttpRequest';

export type InspectionType = {
  id: string;
  carrier: string;
  Location: {
    longitude: number;
    latitude: number;
    address: string;
  };
  driverId: string;
  vehicleId: string;
  vehicleManualId: string;
  trailerNumber: string;
  inspectionType: string;
  inspectionTime: number;
  defectsCategory: {
    id: string;
    vehicle: InspectionDefectType[];
    trailer: InspectionDefectType[];
  };
  signatures: {
    driverSignature: SignatureType;
    mechanicSignature: SignatureType;
  };
  isActive: boolean;
};

export type ModalFormTypes = {
  investigationCode: string;
  driverId: string[];
  dateRange: string[];
};

export type GetCSVRequest = {
  investigationCode: string;
  driverId: string[];
  dateStart: string;
  dateEnd: string;
  origin?: string;
};

export type GetAllCSVRequest = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  isActive?: boolean;
  limit?: number;
};

export type CSVErrorType = {
  detail: string;
};

export type CSVFileType = {
  _id?: string;
  dateStart?: string;
  dateEnd?: string;
  driver?: string;
  errors: CSVErrorType[];
  submissionID?: string;
  origin?: string;
  eRODScode?: string;
  status?: string;
  createdTime?: string;
  isDeleted: boolean;
  isActive: boolean;
  warnings?: string[];
};

export type PaginatedAllCSVReports = {
  data: CSVFileType[];
  total: number;
  last_page: number;
  pageNo: number;
};

export type SignatureType = {
  imageName: string;
  key: string;
  imagePath: string;
};

export type InspectionDefectType = {
  defectsType: string;
  id: string;
  Notes: string;
  imageName: string;
  imagePath: string;
  resolved: boolean;
  defects: {
    id: string;
    defectName: string;
    category: string;
    isActive: string;
  };
};

export type GetInspectionRequest = {
  driverId: string;
  date?: string;
};

export type InspectionResponse = {
  data: InspectionType[];
  message: string;
};
export type InspectionSignatureResponse = {
  data: [];
  message: string;
};
export type CertifiedSignatueResponse = {
  dates: [];
  message: string;
};
export type GetReportRequest = {
  id?: string;
  driverId: string;
  date?: string;
};
export type GetEmailRequest = {
  driverId: string;
  date?: string;
  email?: string;
};
export type ReportResponse = {
  data: string;
  message: string;
};

export type CSVReportResponse = {
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  data: any;
  message: string;
};

const Inspectionapi = {
  getInspection: async (params: GetInspectionRequest): Promise<InspectionResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/${params.driverId}?date=${params.date}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getAllUncertifyDates: async (params: UncertifiedDatesRequestType): Promise<InspectionSignatureResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/csv/unCertifyDays?dateStart=${params?.dateStart}&dateEnd=${params?.dateEnd}&driverId=${params?.driverId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  saveCertifySignature: async (params: CertifiedSignatueRequestType): Promise<CertifiedSignatueResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `inspection/csv/updateCertification?`,
        method: 'GET',
        // eslint-disable-next-line object-shorthand
        params: params,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getReport: async (params: GetReportRequest): Promise<ReportResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/report/dailyLogReport?driverId=${params.driverId}&date=${params.date}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },
  getEmail: async (params: GetEmailRequest): Promise<ReportResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/report/email?email=${params.email}&date=${params.date}&driverId=${params.driverId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getCSVReport: async (body: GetCSVRequest): Promise<CSVReportResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/csv/submitCsvToFmcsa`,
        method: 'GET',
        params: body,
      });
      return response;
    } catch (error) {
      throw { ...error, message: error?.message };
    }
  },

  getAllCSVReports: async (params: GetAllCSVRequest): Promise<PaginatedAllCSVReports> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/getAllSubmittedRecords/All`,
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },
};

export default Inspectionapi;
