import { Button, Col, Modal, notification, Row } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { useSendEmailInspectionReport } from 'hooks/inspectionHooks';
import { BiKey } from 'react-icons/bi';
import { GetEmailRequest } from 'services/inspection';
import { sendReportByEmail } from 'schemas/reportSendEmail';

import 'styles/modal.less';

const SendReportByEmail = ({
  setShowSendReportModal,
  openModal,
  driverId,
  date,
}: {
  setShowSendReportModal: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: boolean;
  driverId: string;
  date: string;
}) => {
  const SendEmailInspectionReport = useSendEmailInspectionReport();

  const formInitialValues = {
    email: '',
  };

  const handleSendReportByEmailModal = (values: { email: string }, formikHelpers: FormikHelpers<{ email: string }>) => {
    formikHelpers.validateForm();

    const finlaData: GetEmailRequest = {
      driverId,
      email: values?.email,
      date,
    };

    SendEmailInspectionReport.mutateAsync(finlaData)
      .then(response => {
        notification.success({ message: response?.message });
      })
      .catch(error => {
        notification.error({ message: error?.message });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        setShowSendReportModal(false);
      });
  };

  return (
    <Modal
      className="app-modal"
      open={openModal}
      title="Send Report via Email"
      width={520}
      onCancel={() => setShowSendReportModal(false)}
      footer={null}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={sendReportByEmail}
        onSubmit={handleSendReportByEmailModal}>
        <Form size="large" layout="vertical" initialValues={formInitialValues}>
          <Row gutter={72}>
            <Col span={24}>
              <FormItem label="Email" name="email" className="form-label" required>
                <Input
                  name="email"
                  typeof="email"
                  prefix={<BiKey size={20} className="formIcons" />}
                  type="text"
                  placeholder="name@example.com"
                  className="form-control"
                />
              </FormItem>
            </Col>
          </Row>
          <div className="custom-csv-file-footer">
            <Button className="generic-button-class cancel-button-set" onClick={() => setShowSendReportModal(false)}>
              Cancel
            </Button>
            <SubmitButton htmlType="submit" className="generic-button-class primary-btn-md">
              Send
            </SubmitButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};

export default SendReportByEmail;
