import React from 'react';
import { useLocation, Link, useHistory } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { isNaN } from 'lodash';

import './BreadCrumb.less';

const BreadCrumb = () => {
  const location = useLocation();
  const history = useHistory();

  const breacCrumbView = () => {
    const { pathname } = location;
    const pathnames = pathname.split('/').filter(item => item);
    if (pathnames.length > 0) {
      const lastValue = pathnames[pathnames.length - 1];
      if (lastValue.match(/^[0-9a-fA-F]{24}$/)) {
        pathnames.pop();
      }
    }
    const capatilize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1);
    return (
      <div className="breadCrumb">
        <Breadcrumb>
          {pathnames.length > 0 ? (
            <Breadcrumb.Item key="home-breadcrumb-item" >
              <Link to="/units">Home</Link>
              </Breadcrumb.Item>
          ) : (
            <Breadcrumb.Item key="home-breadcrumb-item" className="breadcrumbItem">
              Home
            </Breadcrumb.Item>
          )}

          {pathnames.map((name, index) => {
            if (name.length === 24 && !isNaN(Number(`0x${name}`))) {
              return null;
            }

            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;

            let isLast = index === pathnames.length - 1;

            if (index === 2 && pathnames.length === 3) {
              isLast = true;
            }

            return isLast ? (
              <Breadcrumb.Item key={`${name}-breadcrumb-item`} className="breadcrumbItem">
                {capatilize(name.split('-').join(' '))}
              </Breadcrumb.Item>
            ) : (
              <Breadcrumb.Item
                key={`${name}-breadcrumb-item`}
                className="breadcrumbItem">
                <Link to={routeTo}>{capatilize(name.split('-').join(' '))}</Link>
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
        <div className="hr-line" />
      </div>
    );
  };
  return <>{breacCrumbView()}</>;
};

export default BreadCrumb;
