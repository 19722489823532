import { DATE_ONLY, MONTH_DAY } from 'constants/dates';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { Table, Row, Col, Space, Spin, DatePicker, Button, Input, Pagination, Select } from 'antd';
import { convertDateDriverHomeTimeZone } from 'helpers/utils';
import { UnitType } from 'types/hos';
import { TimeZone } from 'types/common';
import { useGetUnits } from 'hooks/unitHooks';
import moment from 'moment';
import { FilterValue, TablePaginationConfig } from 'antd/lib/table/interface';
import { GetUnitsRequest } from 'services/unit';
import { AiOutlineDown, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { ReactComponent as SearchIcon } from 'resources/icons/Search 38.svg';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'constants/config';

import LogsTableColumns from './LogsTableColumns';
import './LogsList.less';

const { Option } = Select;
/* eslint-disable  @typescript-eslint/no-explicit-any */
const LogsList = () => {
  // const currentTZ = Intl.DateTimeFormat().resolvedOptions().timeZone || "America/Chicago"
  const history = useHistory();
  const [filterDate, setFilterDate] = useState<moment.Moment | undefined>(moment().tz('America/Chicago'));
  const currentDate = moment(new Date()).tz('America/Chicago').format(DATE_ONLY);
  const [filter, setFilter] = useState<GetUnitsRequest>({
    date: moment().tz('America/Chicago').format(DATE_ONLY),
    search: '',
    pageNo: DEFAULT_CURRENT_PAGE,
    limit: DEFAULT_PAGE_SIZE,
  });
  const { data, isLoading } = useGetUnits(filter);

  const handleNextDate = () => {
    const nextDate = moment(filterDate).add(1, 'd');
    setFilter({ ...filter, date: nextDate.format(DATE_ONLY) });
    setFilterDate(nextDate);
  };
  const handlePrevDate = () => {
    const prevDate = moment(filterDate).subtract(1, 'days');
    setFilter({ ...filter, date: prevDate.format(DATE_ONLY) });
    setFilterDate(prevDate);
  };
  const handleDriverSearch = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e?.currentTarget?.value;
    setFilter({ ...filter, search: value });
  };
  const handlePageChange = (page: number, pageSize: number) => {
    setFilter({ ...filter, pageNo: page, limit: pageSize });
  };
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  const handleTableChange = (
    pagination: TablePaginationConfig,
    tableFilters: Record<string, FilterValue | null>,
    sorter: any,
  ) => {
    setFilter({
      ...filter,
      pageNo: DEFAULT_CURRENT_PAGE,
      orderBy: sorter?.field ? sorter?.field : undefined,
      orderType: sorter?.order === 'ascend' ? 1 : -1,
    });
  };

  return (
    <Spin spinning={isLoading}>
      <div className="table-container">
        <Row>
          <Col span={24} className="">
            <Space className="space-between">
              <div className="dateLogs">
                <div className="dateLogsSelectors">
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn daySelectorBtnHandle"
                    onClick={handlePrevDate}
                    style={{ borderRadius: '4px 0px 0px 4px' }}>
                    <AiOutlineLeft className="table-btn-icons" />
                    <span className="prevDay">{moment(filterDate).subtract(1, 'days').format(MONTH_DAY)}</span>
                  </Button>
                  <DatePicker
                    className="currentDay-log"
                    disabledDate={current =>
                      current &&
                      current
                        .tz('America/Chicago')
                        .startOf('day')
                        .isAfter(moment().tz('America/Chicago').startOf('day'))
                    }
                    value={moment(filterDate)}
                    format={MONTH_DAY}
                    allowClear={false}
                    onChange={(e: moment.Moment | any) => {
                      setFilter({ ...filter, date: e.format(DATE_ONLY) });
                      setFilterDate(e);
                    }}
                    suffixIcon={<AiOutlineDown size={14} className="fa" />}
                  />
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn daySelectorBtnHandle"
                    style={{ borderRadius: '0px 4px 4px 0px' }}
                    onClick={handleNextDate}
                    disabled={currentDate === filterDate?.format(DATE_ONLY)}>
                    {moment(filterDate).add(1, 'd').format(MONTH_DAY)}
                    <AiOutlineRight className="table-btn-icons nextDayIcon" />
                  </Button>
                </div>
              </div>
              <Space>
                <Input
                  placeholder="Search the results..."
                  size="large"
                  className="searchBar"
                  onChange={handleDriverSearch}
                  suffix={<SearchIcon />}
                  allowClear
                />
                {/* <Input
                  placeholder="Search by truck no..."
                  size="large"
                  className="searchBar"
                  onChange={handleTruckSearch}
                  suffix={<SearchIcon />}
                  allowClear
                /> */}
              </Space>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div>
              <Table
                columns={LogsTableColumns()}
                rowClassName="loglistingdownload"
                dataSource={data?.data || []}
                pagination={false}
                onChange={handleTableChange}
                size="small"
                rowKey={record => record.id}
                onRow={(record: UnitType) => {
                  const { driverId, driverFirstName, driverLastName } = record;
                  const { tzCode } = (record?.homeTerminalTimeZone as TimeZone) || 'America/Chicago';

                  return {
                    onClick: () => {
                      history.push({
                        pathname: `/logs/${driverFirstName}-${driverLastName}`,
                        state: {
                          logSelectedDate: convertDateDriverHomeTimeZone(filterDate!, tzCode).format(DATE_ONLY),
                          driverId,
                          driverTimeZone: tzCode || '',
                          tenantId: record?.tenantId || '',
                          lastActivityStatus: record?.status || '',
                          currentDate: record?.meta?.lastActivity?.currentDate || '',
                          currentTime: record?.meta?.lastActivity?.currentTime || '',
                          meta: record?.meta,
                        },
                      });
                      sessionStorage.setItem(
                        `selectdLogDatail`,
                        JSON.stringify({
                          logSelectedDate: convertDateDriverHomeTimeZone(filterDate!, tzCode).format(DATE_ONLY),
                          driverId,
                          driverTimeZone: tzCode || '',
                          tenantId: record?.tenantId || '',
                          lastActivityStatus: record?.status || '',
                          currentDate: record?.meta?.lastActivity?.currentDate || '',
                          currentTime: record?.meta?.lastActivity?.currentTime || '',
                          meta: record?.meta,
                        }),
                      );
                    },
                  };
                }}
                showSorterTooltip={false}
              />
              <div className="pagination">
                <div className="cutomPaginationStyling">
                  Showing
                  <Select
                    className="customPageSelector"
                    defaultValue="10/page"
                    onChange={e => {
                      const total = data?.total || 0;
                      const LastPage = Math.ceil(total / +e);
                      const currentPage = data?.pageNo || 1;

                      setFilter({
                        ...filter,
                        limit: +e,
                        pageNo: currentPage > LastPage ? LastPage : currentPage,
                      });
                    }}>
                    <Option value="10">10/page</Option>
                    <Option value="20">20/page</Option>
                    <Option value="30">30/page</Option>
                  </Select>
                  of {data?.total || 0} entries
                </div>
                <Pagination
                  showLessItems
                  showSizeChanger={false}
                  pageSize={Number(filter?.limit) || DEFAULT_PAGE_SIZE}
                  total={data?.total || 0}
                  current={data?.pageNo || 1}
                  onChange={handlePageChange}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default LogsList;
