import { HOURS_MINUTES } from 'constants/dates';
import { BREAK_TIME, CYCLE_TIME, DRIVING_TIME, RECAPE_CYCLE_TIME, SHIFT_TIME } from 'constants/config';
import { Col, Progress, Row } from 'antd';
import moment from 'moment';
import { CycleType } from 'services/unit';

import '../../SingleDayLog.less';

const ProgressClocks = ({ clockData }: { clockData: CycleType | undefined }) => {
  const breakDataResponse = clockData?.breakSeconds || 0;
  const driveDataResponse = clockData?.driveSeconds || 0;
  const driveDataSplitResponse = clockData?.driveSecondsSplit || 0;
  const shiftDataResponse = clockData?.shiftDutySecond || 0;
  const shiftDataSplitResponse = clockData?.shiftDutySecondsSplit || 0;
  const cycleDataResponse = clockData?.cycleSeconds || 0;
  const recapeDataResponse = clockData?.recapeClock || 0;
  // const isSplitActive = clockData?.isSplitActive || false;
  const isSplitActive = false;

  let isEqualDriverSplit = false;
  let isEqualShiftSplit = false;

  const breakData = BREAK_TIME - breakDataResponse;
  const breakDataHHMM = moment.utc(breakData * 1000).format(HOURS_MINUTES) || '08:00';

  const driveData = DRIVING_TIME - driveDataResponse;
  const driveDataHHMM = moment.utc(driveData * 1000).format(HOURS_MINUTES) || '11:00';

  const driveDataSplit = DRIVING_TIME - driveDataSplitResponse;
  const driveDataSplitHHMM = moment.utc(driveDataSplit * 1000).format(HOURS_MINUTES) || '11:00';

  const shiftData = SHIFT_TIME - shiftDataResponse;
  const shiftDataHHMM = moment.utc(shiftData * 1000).format(HOURS_MINUTES) || '14:00';

  const shiftDataSplit = SHIFT_TIME - shiftDataSplitResponse;
  const shiftDataSplitHHMM = moment.utc(shiftDataSplit * 1000).format(HOURS_MINUTES) || '14:00';

  const cycleData = CYCLE_TIME - cycleDataResponse;
  const cycleDataInMoment = moment.utc(cycleData * 1000).format(HOURS_MINUTES) || '70:00';
  const CycleMints = cycleDataInMoment.split(':')[1];
  const cycleHrs = moment.duration(cycleData, 'seconds').asHours();
  const cycleDataHHMM = `${Math.floor(cycleHrs)}:${CycleMints}`;

  const recapeData = RECAPE_CYCLE_TIME - recapeDataResponse;
  const recapeDataInMoment = moment.utc(recapeData * 1000).format(HOURS_MINUTES) || '70:00';
  const recapeMints = recapeDataInMoment.split(':')[1];
  const recapeHrs = moment.duration(recapeData, 'seconds').asHours();
  const recapeDataHHMM = `${Math.floor(recapeHrs)}:${recapeMints}`;

  isEqualDriverSplit = driveDataHHMM === driveDataSplitHHMM;
  isEqualShiftSplit = shiftDataHHMM === shiftDataSplitHHMM;

  const breakPercentage = (breakData / 3600 / 8) * 100;
  const drivePercentage = (driveData / 3600 / 11) * 100;
  const driveSplitPercentage = (driveDataSplit / 3600 / 11) * 100;
  const shiftPercentage = (shiftData / 3600 / 14) * 100;
  const shiftSplitPercentage = (shiftDataSplit / 3600 / 14) * 100;
  const cyclePercentage = (cycleData / 3600 / 70) * 100;
  const recapePercentage = (recapeData / 3600 / 70) * 100;
  return (
    <>
      <div className="splitClockView" style={{ marginTop: !isSplitActive ? '15px' : '0px' }}>
        <div className={`marginAllClocks breakClock ${!isSplitActive ? 'breakClock2' : 'breakClock1'}`}>
          <Progress
            type="circle"
            percent={
              // eslint-disable-next-line
              breakDataResponse === 0 ? 100 : breakDataResponse === BREAK_TIME ? 0 : breakPercentage
            }
            format={() => (
              <>
                <p className="progressTitle">Upcoming</p>
                <p className="progressClock">
                  {breakDataResponse > BREAK_TIME ? '00:00' : [breakDataResponse === 0 ? '08:00' : breakDataHHMM]}
                </p>
                <span className="progressDicription">break</span>
              </>
            )}
            strokeColor="#2B5F8C"
            trailColor="#C9CCCE"
          />
        </div>
        {!isSplitActive ? (
          <div className="marginAllClocks driving-Clock">
            <Progress
              type="circle"
              percent={
                // eslint-disable-next-line
                driveDataResponse === 0 ? 100 : driveDataResponse === DRIVING_TIME ? 0 : drivePercentage
              }
              format={() => (
                <>
                  <p className="progressTitle">remaining</p>
                  <p className="progressClock">
                    {driveDataResponse > DRIVING_TIME ? '00:00' : [driveDataResponse === 0 ? '11:00' : driveDataHHMM]}
                  </p>
                  <span className="progressDicription">driving</span>
                </>
              )}
              strokeColor="#2B5F8C"
              trailColor="#C9CCCE"
            />
          </div>
        ) : (
          <div className="marginAllClocks hours-progress-show">
            <div className="clock1" aria-hidden="true">
              <Progress
                type="circle"
                style={{ marginRight: '15px' }}
                className="splitclockDrive"
                percent={
                  // eslint-disable-next-line
                  driveDataSplitResponse === 0
                    ? 100
                    : // eslint-disable-next-line
                    driveDataSplitResponse === DRIVING_TIME
                    ? 0
                    : driveDataSplitResponse > DRIVING_TIME
                    ? 0
                    : driveSplitPercentage
                }
                format={() => (
                  <>
                    <p className="progressTitle">remaining</p>
                    <p className="progressClock">
                      {driveDataSplitResponse > DRIVING_TIME
                        ? '00:00'
                        : [driveDataSplitResponse === 0 ? '11:00' : driveDataSplitHHMM]}
                    </p>
                    <span className="progressDicription">SSB</span>
                  </>
                )}
                strokeColor="#FF9933"
                trailColor="#C9CCCE"
              />
            </div>
            <div className="marginAllClocks clock2" aria-hidden="true">
              <Progress
                type="circle"
                percent={
                  // eslint-disable-next-line
                  driveDataResponse === 0 ? 100 : driveDataResponse === DRIVING_TIME ? 0 : drivePercentage
                }
                format={() => (
                  <>
                    <p className="progressTitle">remaining</p>
                    <p className="progressClock">
                      {driveDataResponse > DRIVING_TIME ? '00:00' : [driveDataResponse === 0 ? '11:00' : driveDataHHMM]}
                    </p>
                    <span className="progressDicription">driving</span>
                  </>
                )}
                strokeColor="#2B5F8C"
                trailColor="#C9CCCE"
              />
            </div>
          </div>
        )}

        {!isSplitActive ? (
          <div className="marginAllClocks shift-Clock">
            <Progress
              type="circle"
              percent={
                // eslint-disable-next-line
                shiftDataResponse === 0 ? 100 : shiftDataResponse === SHIFT_TIME ? 0 : shiftPercentage
              }
              format={() => (
                <>
                  <p className="progressTitle">remaining</p>
                  <p className="progressClock">
                    {shiftDataResponse > SHIFT_TIME ? '00:00' : [shiftDataResponse === 0 ? '14:00' : shiftDataHHMM]}
                  </p>
                  <span className="progressDicription">shift</span>
                </>
              )}
              strokeColor="#2B5F8C"
              trailColor="#C9CCCE"
            />
          </div>
        ) : (
          <div className="hours-progress-show media">
            <div className="clock1">
              <Progress
                type="circle"
                percent={
                  // eslint-disable-next-line
                  shiftDataSplitResponse > SHIFT_TIME ? 0 : shiftDataSplitResponse === 0 ? 100 : shiftSplitPercentage
                }
                style={{
                  marginRight: '15px',
                  left: `${isEqualShiftSplit ? '392px' : '415px'}`,
                }}
                format={() => (
                  <>
                    <p className="progressTitle">remaining</p>
                    <p className="progressClock">
                      {shiftDataSplitResponse > SHIFT_TIME
                        ? '00:00'
                        : [shiftDataSplitResponse === 0 ? '14:00' : shiftDataSplitHHMM]}
                    </p>
                    <span className="progressDicription">SSB</span>
                  </>
                )}
                strokeColor="#FF9933"
                trailColor="#C9CCCE"
              />
            </div>
            <div className="clock2">
              <Progress
                type="circle"
                percent={
                  // eslint-disable-next-line
                  shiftDataResponse === 0 ? 100 : shiftDataResponse === SHIFT_TIME ? 0 : shiftPercentage
                }
                style={{ marginRight: '15px' }}
                format={() => (
                  <>
                    <p className="progressTitle">remaining</p>
                    <p className="progressClock">
                      {shiftDataResponse > SHIFT_TIME ? '00:00' : [shiftDataResponse === 0 ? '14:00' : shiftDataHHMM]}
                    </p>
                    <span className="progressDicription">shift</span>
                  </>
                )}
                strokeColor="#2B5F8C"
                trailColor="#C9CCCE"
              />
            </div>
          </div>
        )}
        <div className={`marginAllClocks cycleClock  ${!isSplitActive ? 'cycleClock' : 'cycleClock2'}`}>
          <Progress
            className={`${!isSplitActive ? 'responsiveCycle' : 'responsiveCycleSplit'}`}
            type="circle"
            percent={
              // eslint-disable-next-line
              cycleDataResponse === 0
                ? // eslint-disable-next-line
                  100
                : // eslint-disable-next-line
                cycleDataResponse === CYCLE_TIME
                ? 0
                : cycleDataResponse > CYCLE_TIME
                ? 0
                : cyclePercentage
            }
            format={() => (
              <>
                <p className="progressTitle">remaining</p>
                <p className="progressClock">
                  {cycleDataResponse > CYCLE_TIME ? '00:00' : [cycleDataResponse === 0 ? '70:00' : cycleDataHHMM]}
                </p>
                <span className="progressDicription">cycle</span>
              </>
            )}
            strokeColor="#2B5F8C"
            trailColor="#C9CCCE"
          />
        </div>
        {/* for future use */}
        {recapeDataResponse !== 0 && (
          <div className="marginAllClocks recapClock">
            <Progress
              className={`${!isSplitActive ? 'responsiveRecap' : 'responsiveRecapSplit'}`}
              type="circle"
              percent={
                // eslint-disable-next-line
                recapeDataResponse === 0
                  ? // eslint-disable-next-line
                    100
                  : // eslint-disable-next-line
                  recapeDataResponse === RECAPE_CYCLE_TIME
                  ? 0
                  : recapeDataResponse > RECAPE_CYCLE_TIME
                  ? 0
                  : recapePercentage
              }
              format={() => (
                <>
                  <p className="progressTitle">remaining</p>
                  <p className="progressClock">
                    {recapeDataResponse > RECAPE_CYCLE_TIME
                      ? '00:00'
                      : [recapeDataResponse === 0 ? '70:00' : recapeDataHHMM]}
                  </p>
                  <span className="progressDicription">recap</span>
                </>
              )}
              strokeColor="#2B5F8C"
              trailColor="#C9CCCE"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProgressClocks;
