/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { Row, Modal, Col, Table, Button } from 'antd';

import { useGetHistory } from 'hooks/unitHooks';
import { DriverResponseType } from 'services/drivers';
import { GetEditedHistoryRequest } from 'services/unit';

import LogEditHistoryTableColumns from './LogEditHistoryTableColumns';
import HistoryDetailsModal from './LogsEventList/Partials/HistoryDetailsModal';

// import 'components/modal/modal.less';
import 'styles/modal.less';
import './LogEditHistoryModal.less';

const LogEditHistoryModal = ({
  openModal,
  onCancel,
  driverDetail,
  intialDate,
}: {
  openModal: boolean;
  onCancel: (value: React.SetStateAction<boolean>) => void;
  driverDetail: DriverResponseType | undefined;
  intialDate: string;
}) => {
  const [showEditHistoryDetailModal, setShowEditHistoryDetailModal] = useState<boolean>(false);
  const [record, setRecord] = useState<{
    id: string;
    status: string;
  }>({
    id: '',
    status: '',
  });

  const finalData: GetEditedHistoryRequest = {
    id: driverDetail?.id,
    date: intialDate,
    // @ts-ignore
    timezone: driverDetail?.homeTerminalTimeZone?.tzCode,
  };
  const history = useGetHistory(finalData).data?.data;

  return (
    <Modal
      className="app-modal"
      open={openModal}
      title="Log History"
      width={1050}
      onCancel={() => onCancel(false)}
      footer={null}>
      <div className="table-container editedHistoryTable">
        <Row>
          <Col span={24}>
            <Table
              columns={LogEditHistoryTableColumns({
                setShowEditHistoryDetailModal,
                setRecord,
              })}
              rowKey={recordTable => recordTable?.editDate}
              pagination={false}
              dataSource={history}
              size="small"
              showSorterTooltip={false}
            />
          </Col>
        </Row>
        <div className="custom-csv-file-footer">
          <Button className="ant-btn-primary" onClick={() => onCancel(false)}>
            close
          </Button>
        </div>
      </div>
      {showEditHistoryDetailModal && (
        <HistoryDetailsModal
          setShowEditHistoryDetailModal={setShowEditHistoryDetailModal}
          record={record}
          driverDetail={driverDetail}
          intialDate={intialDate}
        />
      )}
    </Modal>
  );
};

export default LogEditHistoryModal;
