import { Link, useHistory } from 'react-router-dom';

import './Error404.less';

const Error404 = () => {
  const history = useHistory();

  return (
    <div className="Wrapper">
      <div className="ServerErrorContainer">
        <div className="ErrorIcon">
          <div className="errorCode" />
        </div>
      </div>
      <div className="ErrorMessage">
        <p className="errorHeading">Error 404 - Page Not Found</p>
        <p className="errorText">The page you requested could not be found.</p>
      </div>
      <div className="Routes">
        <p className="pathRoute-text ptag">
          Go back to the
          {/* eslint-disable-next-line */}
          <span
            className="prevLink"
            onClick={() => {
              history.goBack();
            }}>
            previous page
          </span>
        </p>
        <span className="pathRoute-text">
          Go to the
          <Link to="/">
            <span className="pathLink">units</span>
          </Link>
        </span>
      </div>
    </div>
  );
};

export default Error404;
