import { HOS_API_BASE_URL, INSPECTION_API_BASE_URL, UNITS_API_BASE_URL } from 'constants/config';
import {
  DeleteLogsRequestType,
  EditLogRequest,
  GraphType,
  HOSLiveDataType,
  HOSProfileDataType,
  HOSType,
  NormalizeLogsRequestType,
  TransferLogsRequestType,
  UnitType,
  UnitTypeLog,
} from 'types/hos';
import httpRequest from './config/HttpRequest';
import {
  GetUnidentifiedRequest,
  UnidentifiedAccumolatedHoursResponse,
  UnidentifiedEventAssignRequest,
  UnidentifiedEventsAssignResponse,
  UnidentifiedEventsResponse,
} from './unidentifiedEvents';

export type InsertInfoLogRequest = {
  driverId?: string;
  date?: string;
  time: string;
  type?: string;
  sqID?: string;
  signature?: string;
  statusInfo: {
    status?: string;
    eventType?: string;
    eventCode?: string;
    lat?: string;
    long?: string;
    address?: string;
    odometer?: string;
    engineHour?: string;
    truck?: string;
    shippingDocument?: string;
    tralier?: string;
    notes?: string;
    state?: string;
  };
};
export type GetUnitsRequest = {
  pageNo?: number;
  limit?: number | string;
  orderType?: number;
  orderBy?: string;
  search?: string;
  date?: string;
};
export type GetUnitsLogRequest = {
  date?: string;
  search?: string;
  // driverName?: string | undefined;
  // truckNo?: string | undefined;
};

export type PaginatedUnits = {
  data: UnitType[];
  message: string;
  total: number;
  last_page: number;
  pageNo: number;
};
export type PaginatedUnitsLogs = {
  data: {
    driverQuery: UnitTypeLog[];
  };
  message: string;
  // total: number;
  // last_page: number;
  // pageNo: number;
};
export type SingleUnitGetResponse = {
  data: UnitType;
  message: string;
};

export type GetHosHistoryRequest = {
  driverId: string;
  date: string;
  pageNo?: number;
  limit?: number | string;
  orderType?: number;
};

export type GetHosCSVRequest = {
  driverId: string;
  date: string;
};

export type GetHosDriverLogsRequest = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  driverId?: string;
  date: number;
  limit?: number;
};
export type TrackingResponseType = {
  address?: string;
  date?: string;
  engineHours?: string;
  latitude?: string;
  longitude?: string;
  meta?: {};
  odometer?: string;
  speed?: string;
  status?: string;
  eventType?: string;
  time?: string;
  duration?: number;
  calculatedDuration?: number;
  showStopKey?: boolean;
  showMark?: boolean;
  startTime?: string;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type HistoryResponse = {
  data: any;
  message?: string;
};

export type GetCustomHosDriverLogsRequest = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  date: number;
};

export type GetHosDriverGraphDataRequest = {
  driverId?: string;
  date: string;
};

export type GetHosDriverCSVRequest = {
  driverId?: string;
  date: string;
};

export type GetHosTrackingRequest = {
  driverId?: string;
  date?: string;
  isToday?: boolean;
  // time?: string;
};
export type GetEditedHistoryRequest = {
  id?: string;
  date?: string;
  timezone?: string;
};

export type GetOriginalLogsRequest = {
  id?: string;
  date?: string;
};

export type MetaDataType = {
  clockData: CycleType;
  dateTime: number;
  totalEngineHours: string;
  totalVehicleMiles: string;
  lastActivity: {
    odoMeterMillage: string;
    engineHours: string;
    currentTime: string;
    currentDate: string;
    latitude: string;
    longitude: string;
    address: string;
    speed: string;
    currentEventCode: string;
  };
};

export type GetHosEditedHistoryRequest = {
  driverId: string;
  pageNo?: number;
  limit?: number | string;
  orderType?: number;
  orderBy?: string;
  search?: string;
};

export type DriverEditHistoryType = {
  createdAt: string;
  dateTime: string;
  driverId: string;
  driver: {
    id: string;
    name: string;
  };
  editedBy: {
    id: String;
    name: String;
  };
  status: string;
  isApproved: string;
  updatedAt: string;
  _id: string;
};

export type PaginatedDriverEditedHistory = {
  data: DriverEditHistoryType[];
  message: string;
  total: number;
  last_page: number;
  pageNo: number;
};

export type GetCustomHosDriverGraphDataRequest = {
  date: string;
};

export type GraphResponse = {
  data: GraphType[];
  message: string;
};

export type GetLogsSelectRequest = {
  pageNo: number;
  orderType?: number;
  orderBy?: string;
  search?: string;
  limit?: number;
  startDate?: string;
  endDate?: string;
  type?: string;
  vinNo?: string;
};

export type LogsType = {
  calendarStartDate: number;
  firstName: string;
  id: {
    calendarDate: [string] | number;
    driverId: number;
  };
  lastName: string;
  logDocumentId: string;
};

export type PaginatedLogs = {
  data: HOSType[];
  total: number;
  pageNo: number;
  last_page: number;
};

export type PaginatedLogListing = {
  data: LogsType[];
  total: number;
  pageNo: number;
  last_page: number;
};

export type GetHosLiveDataRequest = {
  driverId: string;
};

export type GetHosProfileDataRequest = {
  driverId: string;
};

export type HOSResponse = {
  data: HOSType[];
  message: string;
  total: number;
  last_page: number;
  pageNo: number;
};

export type HOSLiveResponse = {
  data: HOSLiveDataType;
  message: string;
};

export type HOSProfileResponse = {
  data: HOSProfileDataType;
  message: string;
};

export type CycleType = {
  breakSeconds: number;
  driveSeconds: number;
  driveSecondsSplit: number;
  shiftDutySecond: number;
  shiftDutySecondsSplit: number;
  cycleSeconds: number;
  isSplitActive: boolean;
  recapeClock: number;
};

export type HOSCycleResponse = {
  data: CycleType;
  message: string;
};
export type HOSGetLocationRequest = {
  lat: string;
  long: string;
};
export type HOSGetAddressCoordinates = {
  location: string;
};
export type LogFormType = {
  id?: string;
  driverId?: string;
  coDriverId?: string;
  date?: number;
  vehicleId?: string;
  deviceId?: string;
  deviceVendor?: string;
  deviceSerialNo?: string;
  vehicleLicensePlateNo?: string;
  vehicleMake?: string;
  manualVehicleId?: string;
  eldNo?: string;
  manualDriverId: string;
  driverFirstName?: string;
  driverLastName?: string;
  driverName?: string;
  lastKnownLocation?: {};
  homeTerminalTimeZone?: {
    tzCode?: string;
    utc?: string;
    label?: string;
    name?: string;
  };
  driverLicense?: string;
  trailerNumber?: string[];
  homeTerminalAddress?: string;
  headOffice?: string;
  headOfficeId?: string;
  homeTerminalAddressId?: string;
  odoMeterSpeed?: number;
  shippingDocument?: string[];
  status?: string;
  lastActivityDate?: number;
  isDriverActive?: boolean;
  isVehicleActive?: boolean;
  isDeviceActive?: boolean;
  tenantId?: string;
  isActive?: boolean;
  from?: string;
  to?: string;
  sign?: {
    imageName: string;
    imagePath: string;
  };
  notes?: string;
  carrier?: string;
  distance?: number;
  totalVehicleMiles?: string;
  totalEngineHours?: string;
  trailerNumberField?: string;
  shippingDocumentField?: string;
};

export type GetLogFormResonse = {
  data: LogFormType;
  message: string;
};

export type GetLogFormRequest = {
  driverId: string;
  date: string;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type HOSDataResponseType = {
  editStatus: {
    isEditRequested: boolean;
    editLogs: any;
    graphData: any;
  };
  graphData: any;
};

export type HOSDriverLiveHistoryType = {
  date: string;
  engineHours: string;
  latitude: string;
  longitude: string;
  odometer: string;
  speed: string;
  status: string;
  time: string;
  address: string;
};

export type CancelEditRequest = {
  driverId: string;
  isApproved: string;
  dateTime?: string;
};

export type SendNotificationEditRequest = {
  driverId: string;
  tenantId: string;
  date?: string;
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export type CancelRequestResponse = {
  data: any;
  message: string;
};

export type HOSDriverCSVReponse = {
  data: HOSDataResponseType;
  message: string;
};

export type HOSDriverLiveHistoryResponse = {
  data: HOSDriverLiveHistoryType[];
  message: string;
};

export type HOSDriverTrackingHistoryResponse = {
  data: HOSDriverLiveHistoryType[];
  message: string;
};
export type SpecificHosDateTrip = {
  address?: string;
  date?: string;
  engineHours?: string;
  latitude?: string;
  longitude?: string;
  odometer?: string;
  speed?: string;
  status?: string;
  eventType?: string;
  time?: string;
  _id?: string;
  duration?: number;
};
export type SpecificHosDateTripResponse = {
  data: SpecificHosDateTrip[];
  message: string;
};


const hosApi = {
  getUnits: async (params: GetUnitsRequest): Promise<PaginatedUnits> => {
    try {
      const response = await httpRequest.request({
        baseURL: UNITS_API_BASE_URL,
        url: '/units',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },
  getUnitsLogs: async (params: GetUnitsLogRequest): Promise<PaginatedUnitsLogs> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: '/hos/driverRecords',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getSingleUnitByDriverId: async (driverId: string): Promise<SingleUnitGetResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: UNITS_API_BASE_URL,
        url: `units/${driverId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriverLogs: async (params: GetHosDriverLogsRequest): Promise<PaginatedLogs> => {
    const customParams: GetCustomHosDriverLogsRequest = {
      pageNo: params.pageNo,
      orderType: params.orderType,
      orderBy: params.orderBy,
      date: params.date,
    };
    try {
      // const driverId = params.driverId;
      // params.driverId = undefined;
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/getHistoryByParam/${params.driverId}`,
        method: 'GET',
        params: customParams,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriverGraphData: async (params: GetHosDriverGraphDataRequest): Promise<GraphResponse> => {
    const customParams: GetCustomHosDriverGraphDataRequest = {
      date: params.date,
    };
    try {
      // eslint-disable-next-line
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/graph/${params.driverId}`,
        method: 'GET',
        params: customParams,
      });

      return response;
      // return {
      //   message: 'done',
      //   // eslint-disable-next-line
      //   data: response?.data?.filter((x: any) => x?.totalSecondsSpentSoFar > 0),
      // };
    } catch (error) {
      throw new Error(error as string);
    }
  },

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  getDriverCSV: async (params: GetHosDriverCSVRequest): Promise<HOSDriverCSVReponse> => {
    const customParams = {
      start: params.date,
      end: params.date,
      driverId: params.driverId,
    };
    try {
      // eslint-disable-next-line
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/csv/log`,
        method: 'GET',
        params: customParams,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getLocationLatLong: async (params: HOSGetLocationRequest): Promise<any> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/csv/getLocation?lat=${params.lat}&long=${params.long}`,
        method: 'POST',
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getAddressCoordinates: async (params: HOSGetAddressCoordinates): Promise<any> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/csv/getlatlng?address=${params.location}`,
        method: 'GET',
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriverLiveHistory: async (params: GetHosDriverCSVRequest): Promise<HOSDriverLiveHistoryResponse> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/liveLocationHistory`,
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriverTrackingHistory: async (params: GetHosTrackingRequest): Promise<SpecificHosDateTripResponse> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/specificDateTrip`,
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getTripTrackingHistory: async (params: GetHosTrackingRequest): Promise<SpecificHosDateTripResponse> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/tripHistory`,
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getHistory: async (params: GetEditedHistoryRequest): Promise<any> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/history`,
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getOriginalLogs: async (params: GetOriginalLogsRequest): Promise<any> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/orignal`,
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getHistoryDetails: async (params: string, timezone: string): Promise<HistoryResponse> => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/history?detail=true&id=${params}&timezone=${timezone}`,
        method: 'GET',
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getDriverEditedHistory: async (params: GetHosEditedHistoryRequest) => {
    try {
      const baseURL = HOS_API_BASE_URL;
      const response = await httpRequest.request({
        baseURL,
        url: `/hos/edithistory`,
        method: 'GET',
        params,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  addDriverLog: async (body: EditLogRequest): Promise<GraphResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/log`,
        method: 'POST',
        data: body,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  // editDriverLog: async (body: EditLogRequest): Promise<GraphResponse> => {
  //   try {
  //     const response = await httpRequest.request({
  //       baseURL: HOS_API_BASE_URL,
  //       url: `/hos/log`,
  //       method: 'PUT',
  //       data: body,
  //     });

  //     return response;
  //   } catch (error) {
  //     throw new Error(error as string);
  //   }
  // },

  editDriverLog: async (body: EditLogRequest): Promise<GraphResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/log`,
        method: 'PUT',
        data: body,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  cancelDriverLog: async (body: CancelEditRequest): Promise<CancelRequestResponse> => {
    const finalBody = {
      isApproved: body?.isApproved,
      dateTime: body?.dateTime,
    };
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/isapproved?driverId=${body?.driverId}`,
        method: 'POST',
        data: finalBody,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  notificationSendEditLog: async (body: SendNotificationEditRequest): Promise<CancelRequestResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/notify/driver`,
        method: 'PATCH',
        data: body,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  deleteLogs: async (body: DeleteLogsRequestType): Promise<any> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/csv/delete/log/${body?.driverId}?date=${body?.date}`,
        method: 'DELETE',
        data: body,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  normalizeLogs: async (body: NormalizeLogsRequestType): Promise<any> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/csv/normalize/${body?.driverId}?date=${body?.date}&type=${body.type}&normalizationType=${body.normalizationType}`,
        method: 'PATCH',
        data: body,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  transferLogs: async (body: TransferLogsRequestType): Promise<any> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/csv/transferLogs`,
        method: 'GET',
        params: body,
      });

      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getHistroy: async (params: GetHosHistoryRequest): Promise<HOSResponse> => {
    try {
      const filteredParams = {
        pageNo: params?.pageNo,
        limit: params?.limit,
        orderType: params?.orderType,
      };
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/history/${params.driverId}?date=${params.date}`,
        method: 'GET',
        params: filteredParams,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getLiveData: async (params: GetHosLiveDataRequest): Promise<HOSLiveResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/live/${params.driverId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getProfileData: async (params: GetHosProfileDataRequest): Promise<HOSProfileResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/profile/${params.driverId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getLogsListing: async (params: GetLogsSelectRequest): Promise<PaginatedLogListing> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: '/hos/alldriverslog',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getHOSCycle: async (params: GetHosProfileDataRequest): Promise<HOSCycleResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/clock/${params.driverId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  //  LogForm

  updateLogForm: async (body: any): Promise<GetLogFormResonse> => {
    const date = body.get('date');
    const driverId = body.get('driverId');
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/logForm/update/${driverId}?date=${date}`,
        method: 'PUT',
        data: body,
      });
      return response;
    } catch (error) {
      throw { ...error };
    }
  },

  getLogForm: async (params: GetHosHistoryRequest): Promise<GetLogFormResonse> => {
    try {
      const response = await httpRequest.request({
        baseURL: INSPECTION_API_BASE_URL,
        url: `/inspection/logForm/${params.driverId}?date=${params.date}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  // fetch unidentified evnets list

  getUnidentifiedEvents: async (params: GetUnidentifiedRequest): Promise<UnidentifiedEventsResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: '/hos/unidentifiedlogs',
        method: 'GET',
        params,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  getUnidentifiedAccumulatedhours: async (): Promise<UnidentifiedAccumolatedHoursResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: '/hos/unidentifiedlogs/accumulatedhours',
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },
  getUnidentifiedAssignEvents: async (unidentifiedEventId: string): Promise<UnidentifiedEventsAssignResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/unidentifiedlogs/${unidentifiedEventId}`,
        method: 'GET',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  saveInsertInfoLog: async (body: InsertInfoLogRequest): Promise<any> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/csv/insertLogInfo`,
        method: 'POST',
        data: body,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  saveUnidentifiedAssign: async (body: UnidentifiedEventAssignRequest): Promise<UnidentifiedEventsAssignResponse> => {
    try {
      const driverId = body?.driverId || '';
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/unidentifiedLogs/assignunidentified?driverId=${driverId}`,
        method: 'PUT',
        data: body,
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },

  cancelPendingUnidentified: async (unidentifiedEventId: string): Promise<UnidentifiedEventsAssignResponse> => {
    try {
      const response = await httpRequest.request({
        baseURL: HOS_API_BASE_URL,
        url: `/hos/unidentifiedLogs/cancel?id=${unidentifiedEventId}`,
        method: 'PUT',
      });
      return response;
    } catch (error) {
      throw new Error(error as string);
    }
  },
};

export default hosApi;
