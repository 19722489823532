import { USE_QUERY_OPTIONS } from 'constants/config';
import { useMutation, useQuery } from 'react-query';

import vehicleapi, { GetVehicleOptionsRequest, GetVehicleRequest } from 'services/vehicles';
import { GET_VEHICLE } from './keys';

export const useGetVehicles = (params: GetVehicleRequest) =>
  useQuery([GET_VEHICLE, params], () => vehicleapi.getVehicles(params), { ...USE_QUERY_OPTIONS });

export const useGetVehiclesOptions = (params: GetVehicleOptionsRequest) =>
  useQuery([GET_VEHICLE, params], () => vehicleapi.getVehiclesOptions(params));

export const useGetVehicle = (vehicleId: string) =>
  useQuery(['getVehicle', vehicleId], () => vehicleapi.getVehicle(vehicleId), {
    ...USE_QUERY_OPTIONS,
    select: ({ data }) => data,
    refetchOnMount: 'always',
  });

export const useSaveVehicle = () => useMutation(vehicleapi.saveVehicle, { mutationKey: 'saveVehicle' });

export const useUpdateVehicleStatus = () =>
  useMutation(vehicleapi.updateVehicleStatus, { mutationKey: 'deactivateVehicle' });
