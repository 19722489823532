import { Col, Row } from 'antd';
import moment from 'moment';
import { TrackingResponseType } from 'services/unit';
import './CustomInfoWindow.less';
import { secondToTime } from 'helpers/utils';

const CustomTrackingInfo = ({
  info,
  indexTrack,
  lengthArray,
  convertedDate,
}: {
  info: TrackingResponseType;
  indexTrack: number | null;
  lengthArray: number | undefined;
  convertedDate: string | null;
}) => (
  <div className="custom_info_design_current-location-history">
    <ul className="info_list">
      <li>
        <Row gutter={24}>
          <Col span={8}>
            <p className="p-body">Status:</p>
          </Col>
          <Col span={16}>
            <p>
              <div>
                {indexTrack === 0
                  ? 'Start'
                  : [
                    indexTrack === lengthArray! - 1 && convertedDate === info?.date
                      ? 'Driving'
                      : [indexTrack !== lengthArray! - 1 ? 'Stop' : 'End'],
                  ]}
              </div>
            </p>
          </Col>
        </Row>
      </li>
      <li>
        <Row className="address_row-current_location" gutter={24}>
          <Col span={8}>
            <p className="p-body">Address:</p>
          </Col>
          <Col span={16}>
            <p>{info?.address || ''}</p>
          </Col>
        </Row>
      </li>
      <li>
        <Row className="address_row-current_location" gutter={24}>
          <Col span={8}>
            <p className="p-body">Coordinates:</p>
          </Col>
          <Col span={16}>
            <p>
              {info?.latitude || ''}, {info?.longitude || ''}
            </p>
          </Col>
        </Row>
      </li>
      <li>
        <Row gutter={24}>
          <Col span={8}>
            <p className="p-body">Date:</p>
          </Col>
          <Col span={16}>
            <p>
              <>
                {info?.date! === convertedDate
                  ? ['Today', ', ', moment(info?.time, 'hh:mm:ss A').format('hh:mm:ss A')]
                  : [
                    moment(`${info?.date}`, 'MM/DD/YY').format('MM/DD/YY'),
                    ' ',
                    moment(info?.time, 'hh:mm:ss A').format('hh:mm:ss A'),
                  ]}
              </>
            </p>
          </Col>
        </Row>
      </li>
      <li>
        <Row gutter={24}>
          <Col span={8}>
            <p className="p-body">Odometer:</p>
          </Col>
          <Col span={16}>
            <p>
              <>{info?.odometer || 0}</>
            </p>
          </Col>
        </Row>
      </li>
      {info?.startTime && info?.time! && indexTrack !== 0 ? (
        <li>
          <Row className="address_row-current_location" gutter={24}>
            <Col span={8}>
              <p className="p-body">Duration:</p>
            </Col>
            <Col span={16}>
              <p>{
                secondToTime(moment.duration(moment(info.time, "HHmmss").diff(moment(info.startTime, "HHmmss"))).asSeconds())
              }</p>

            </Col>
          </Row>
        </li>
      ) : null}
      <a target="_blank" rel="noreferrer" href={`https://www.google.com/maps?q=${info?.latitude},${info?.longitude}`}>
        <span>View on Google Maps</span>
      </a>
    </ul>
  </div>
);

export default CustomTrackingInfo;
