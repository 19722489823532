import { DEFAULT_DATE_FORMATE } from 'constants/dates';
import { Badge } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { convertUnixCompanyTimeZone } from 'helpers/utils';
import { Link } from 'react-router-dom';
import { svgIcons } from 'resources/icons';
import { InspectionType } from 'services/inspection';

export interface Filter {
  id: string;
  driverId: string;
  date: string;
}

const InspectionReportTableColumns = ({ driverName }: { driverName: string }): ColumnsType<InspectionType> => [
  {
    title: 'Date / Time',
    dataIndex: 'inspectionTime',
    key: 'inspectionTime',
    render: (text: string, record: InspectionType) => (
      <Link
        to={{
          pathname: `/logs/${driverName}/inspection-report`,
          state: record,
        }}>
        <span style={{ textDecoration: 'underline', color: '#2B5F8C' }}>
          {convertUnixCompanyTimeZone(record?.inspectionTime).format(DEFAULT_DATE_FORMATE)}
        </span>
      </Link>
    ),
  },
  {
    title: 'Inspection Type',
    dataIndex: 'inspectionType',
    key: 'inspectionType',
    render(text: string, record: InspectionType) {
      return <span style={{ textTransform: 'capitalize' }}>{record?.inspectionType}</span>;
    },
  },
  {
    title: 'Vehicle ID',
    dataIndex: 'vehicleManualId',
    key: 'vehicleManualId',
  },
  {
    title: 'Vehicle Defects',
    dataIndex: 'defectsCategory',
    key: 'vehicle',
    render: (value, record: InspectionType) => {
      let counter = 0;
      let defectType = '';

      defectType = record?.defectsCategory?.vehicle[0]?.defects?.defectName;
      // eslint-disable-next-line
      if (record?.defectsCategory?.vehicle?.length > 1) {
        // eslint-disable-next-line
        record?.defectsCategory?.vehicle.map(item => {
          if (!item?.resolved) {
            counter += 1;
          }
        });
      }

      return (
        <>
          {record?.defectsCategory?.vehicle?.length > 1 ? (
            <>
              <div className="InspectionTooltip">
                <Badge
                  count={counter}
                  style={{ marginTop: '6px', borderRadius: '5px', height: '19px' }}
                  className="listingBadge">
                  <span className="voilationsText" style={{ textTransform: 'capitalize' }}>
                    {defectType}
                  </span>
                </Badge>

                <span className="tooltiptext">
                  <div className="CustomtooltipText">
                    {/* eslint-disable-next-line */}
                    {record?.defectsCategory?.vehicle.map((item, index) => {
                      let vehicleDefectItem = '';
                      if (index >= 1) {
                        vehicleDefectItem = item?.defects?.defectName;
                        return (
                          <div
                            className="AssignedVehilce"
                            style={{ textAlign: 'left', color: 'red', textTransform: 'capitalize' }}
                            key={vehicleDefectItem}>
                            <span className="vehilceDataCloumn">{vehicleDefectItem}</span>
                          </div>
                        );
                      }
                    })}
                  </div>
                </span>
                <div className="arrowDown" />
              </div>
            </>
          ) : (
            <>
              <span style={{ color: 'red', textTransform: 'capitalize' }}>{defectType}</span>
            </>
          )}
        </>
      );
    },
  },
  // {
  //   title: 'Trailer ID ',
  //   dataIndex: 'trailerNumber',
  //   key: 'trailerNumber',
  // },
  // {
  //   title: 'Trailer Defects',
  //   dataIndex: 'defectsCategory',
  //   key: 'defectsCategory',
  //   render: (value, record: InspectionType) => {
  //     let counter = 0;
  //     let defectType = '';
  //     defectType = record?.defectsCategory?.trailer[0]?.defects?.defectName;
  //     // eslint-disable-next-line
  //     if (record?.defectsCategory?.trailer?.length > 1) {
  //       // eslint-disable-next-line
  //       record?.defectsCategory?.trailer.map(item => {
  //         if (!item?.resolved) {
  //           counter += 1;
  //         }
  //       });
  //     }
  //     return record?.defectsCategory?.trailer.length > 1 ? (
  //       <div className="InspectionTooltip">
  //         <Badge
  //           count={counter}
  //           style={{ marginTop: '6px', borderRadius: '5px', height: '19px' }}
  //           className="listingBadge">
  //           <span className="voilationsText" style={{ textTransform: 'capitalize' }}>
  //             {defectType}
  //           </span>
  //         </Badge>

  //         <span className="tooltiptext">
  //           <div className="CustomtooltipText">
  //             {/* eslint-disable-next-line */}
  //             {record?.defectsCategory?.trailer.map((item, index) => {
  //               let trailerDefectItem = '';
  //               if (index >= 1) {
  //                 trailerDefectItem = item?.defects?.defectName;
  //                 return (
  //                   <div
  //                     className="AssignedVehilce"
  //                     style={{ textAlign: 'left', color: 'red', textTransform: 'capitalize' }}
  //                     key={trailerDefectItem}>
  //                     <span className="vehilceDataCloumn">{trailerDefectItem}</span>
  //                   </div>
  //                 );
  //               }
  //             })}
  //           </div>
  //         </span>
  //         <div className="arrowDown" />
  //       </div>
  //     ) : (
  //       <>
  //         <span style={{ color: 'red', textTransform: 'capitalize' }}>{defectType}</span>
  //       </>
  //     );
  //   },
  // },
  {
    title: 'Status',
    render: (value, record: InspectionType) => {
      let isVehicleDefectResolved = false;
      let isAssetsDefectResolved = false;
      // eslint-disable-next-line
      record?.defectsCategory?.vehicle.map(item => {
        isVehicleDefectResolved = item?.resolved;
      });
      // eslint-disable-next-line
      record?.defectsCategory?.trailer.map(item => {
        isAssetsDefectResolved = item?.resolved;
      });
      return (
        <>
          {isAssetsDefectResolved && isVehicleDefectResolved ? (
            <span style={{ color: '#52C41A' }}>Corrected</span>
          ) : (
            <span style={{ color: '#122640' }}>Need not to be corrected</span>
          )}
        </>
      );
    },
  },
  {
    title: 'Signatures',
    dataIndex: 'signatures',
    key: 'signatures',
    render: (text: string, record: InspectionType) =>
      record?.signatures?.driverSignature?.imagePath ? (
        <span style={{ display: 'flex', marginLeft: '31px' }}>
          <svgIcons.checkIcon />
        </span>
      ) : (
        <>
          <div style={{ display: 'flex' }}>
            <svgIcons.SingatureErrorIcon width={16} height={16} />
            <span style={{ fontSize: '13px', color: 'red', marginLeft: '10px' }}>No Signature</span>
          </div>
        </>
      ),
  },
  {
    title: '',
    render(value, record: InspectionType) {
      return (
        <>
          {/* <Button
              className="ant-btn ant-btn-default tbl-top-btn inspectionDownload"
              id="downloadButton"
              onClick={() => {
                setIsDownloadCheked(true);
                setReportDate(currentDate);
              }}
              >
              <AiOutlineDownload className="table-btn-icons" />
               Download
            </Button> */}
          <Link
            to={{
              pathname: `/logs/${driverName}/inspection-report`,
              state: record,
            }}>
            <span style={{ textDecoration: 'underline', color: '#2B5F8C' }}>View</span>
          </Link>
        </>
      );
    },
  },
];

export default InspectionReportTableColumns;
