import { DEFAULT_MONTH_DAY_YEAR } from 'constants/dates';
import { ColumnsType } from 'antd/lib/table';
import { getColumnHtml } from 'helpers/reactutils';
import { UnitType, ViolationsEntity } from 'types/hos';
import { convertDateDriverHomeTimeZone } from 'helpers/utils';
import moment from 'moment';
import { STATUSE_TYPE_INTO_ENEVT_CODE, ViolationsType } from 'constants/config';
import { Badge, Space, Tag } from 'antd';
import { FiTruck } from 'react-icons/fi';

const LogsTableColumns = (): ColumnsType<UnitType> => [
  {
    title: '#',
    dataIndex: '#',
    key: '#',
    sorter: false,
    render: (text, row, index) => <>{index + 1}</>,
  },
  {
    title: 'Driver',
    dataIndex: 'driverFullName',
    key: 'driverFullName',
  },
  {
    title: 'Truck No',
    dataIndex: 'manualVehicleId',
    key: 'manualVehicleId',
    render: truck => {
      if (truck) {
        return (
          <Space align="center" size={4}>
            <FiTruck style={{ marginTop: '3px', color: 'rgb(88, 105, 115)', height: '20px', width: '20px' }} />{' '}
            <span>{truck}</span>
          </Space>
        );
      }
      return <></>;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (tag, row) => {
      let statusColor = '';
      let tagName = '';
      let tagStatus = '';

      if (!row?.status) {
        return (
          <Tag color="#586973" key={tag} className="statusTag">
            OFF
          </Tag>
        );
      }
      if (!row?.meta?.lastActivity?.currentEventType || !row?.meta?.lastActivity?.currentEventType) {
        return (
          <Tag color="#586973" key={tag} className="statusTag">
            OFF
          </Tag>
        );
      }
      if (row?.meta?.lastActivity?.currentEventType === '1') {
        if (row?.meta?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY) {
          statusColor = '#586973';
          tagName = 'OFF';
          tagStatus = 'Off Duty';
        } else if (row?.meta?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY) {
          statusColor = '#2b5f8c';
          tagName = 'ON';
          tagStatus = 'On Duty';
        } else if (row?.meta?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING) {
          statusColor = '#52C41A';
          tagName = 'DR';
          tagStatus = 'Driving';
        } else if (row?.meta?.lastActivity?.currentEventCode === STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH) {
          statusColor = '#FF9933';
          tagName = 'SB';
          tagStatus = 'Sleeper Berth';
        } else {
          tagStatus = 'Off Duty';
        }
      } else if (row?.meta?.lastActivity?.currentEventType === '2') {
        tagStatus = 'Int. Location';
      } else if (row?.meta?.lastActivity?.currentEventType === '3') {
        if (row?.meta?.lastActivity?.currentEventCode === '1') {
          statusColor = '#858e9ef3';
          tagName = 'PC';
          tagStatus = 'Off (PC)';
        } else if (row?.meta?.lastActivity?.currentEventCode === '2') {
          statusColor = '#1ca1bff3';
          tagName = 'YM';
          tagStatus = 'On (YM)';
        }
      } else if (row?.meta?.lastActivity?.currentEventType === '6') {
        if (row?.meta?.lastActivity?.currentEventCode === '1' || row?.meta?.lastActivity?.currentEventCode === '2') {
          tagStatus = 'Power Up';
        } else if (
          row?.meta?.lastActivity?.currentEventCode === '3' ||
          row?.meta?.lastActivity?.currentEventCode === '4'
        ) {
          tagStatus = 'Power Down';
        }
      } else if (row?.meta?.lastActivity?.currentEventType === '5') {
        if (row?.meta?.lastActivity?.currentEventCode === '1') {
          tagStatus = 'Log In';
        } else if (row?.meta?.lastActivity?.currentEventCode === '2') {
          tagStatus = 'Log Out';
        }
      }
      return (
        <>
          {row?.meta?.lastActivity?.currentEventType === '1' || row?.meta?.lastActivity?.currentEventType === '3' ? (
            <>
              <Tag color={statusColor} key={tag} className="statusTag">
                <>{tagName}</>
              </Tag>
            </>
          ) : (
            <>
              <span style={{ fontSize: '12px', display: 'inline' }} className="statusTagText">
                {tagStatus}
              </span>
            </>
          )}
        </>
      );
    },
  },
  {
    title: 'Last known location',
    dataIndex: 'lastKnownLocation',
    key: 'lastKnownLocation',
    render: (row, record) => {
      if (record?.meta?.lastActivity?.currentDate && record?.meta?.lastActivity?.currentTime) {
        const formattedDateTime = moment
          .tz(
            `${record?.meta?.lastActivity?.currentDate} ${record?.meta?.lastActivity?.currentTime}`,
            'MMDDYY HHmmss',
            record?.homeTerminalTimeZone?.tzCode!,
          )
          .fromNow();

        return (
          <>
            {record?.meta?.lastActivity?.address}{' '}
            <small style={{ color: '#586973' }}>
              <b>({formattedDateTime})</b>
            </small>
          </>
        );
      }
      return <>{row?.address}</>;
    },
  },
  {
    title: 'Warning & violations',
    dataIndex: 'violations',
    key: 'violations',
    render: (row, record) => {
      type violationType = {
        endedAt?: {
          eventDate?: string;
          eventTime?: string;
        };
        startedAt?: {
          eventDate?: string;
          eventTime?: string;
        };
        type: string;
      };
      const eventCounts: { [key: string]: number } = {};

      const violations: violationType[] = [];
      if (record?.ptiType === '1') {
        violations.push({ type: ViolationsType.noPti });
      }
      if (record?.ptiType === '3') {
        violations.push({ type: ViolationsType.insufficientPtiTime });
      }

      if (row) {
        violations.push(...row);
      }
      violations?.forEach((item: violationType) => {
        const event_type: string = item.type;
        if (eventCounts[event_type]) {
          eventCounts[event_type] += 1;
        } else {
          eventCounts[event_type] = 1;
        }
      });

      if (violations?.length) {
        const event_keys = Object.keys(eventCounts);

        return (
          <>
            <li className="violation" style={{ display: 'inline' }}>
              <Badge key={`id_${1}`} count={eventCounts[event_keys[0]]} style={{ marginRight: '10px' }} />

              <span style={{ marginRight: '10px' }}>
                {event_keys[0] === ViolationsType.Break30Mint
                  ? '30 Minute Break Required'
                  : [
                      event_keys[0] === ViolationsType.Cycle70Hours
                        ? '70 Hour Cycle Limit'
                        : [
                            event_keys[0] === ViolationsType.driving11Hours
                              ? '11 Hour Driving Limit'
                              : [
                                  event_keys[0] === ViolationsType.shift14hours
                                    ? '14 Hour Shift Limit'
                                    : [
                                        event_keys[0] === ViolationsType.other
                                          ? 'Other'
                                          : [
                                              event_keys[0] === ViolationsType.noPti
                                                ? 'No PTI'
                                                : [
                                                    event_keys[0] === ViolationsType?.insufficientPtiTime
                                                      ? 'PTI time is not sufficient'
                                                      : '',
                                                  ],
                                            ],
                                      ],
                                ],
                          ],
                    ]}
              </span>
            </li>
            {event_keys?.length > 1 ? (
              <>
                <li className="InspectionTooltip">
                  <span style={{ color: 'rgb(87, 157, 255)' }}>{`+${event_keys.slice(1).length} More`}</span>
                  <span className="tooltiptext">
                    {event_keys.slice(1).map((key, index) => (
                      <li key={`id_${index + 1}`} style={{ textAlign: 'left' }}>
                        <div className="violation">
                          <span style={{ marginRight: '10px' }}>
                            {key === ViolationsType.Break30Mint
                              ? '30 Minute Break Required'
                              : [
                                  key === ViolationsType.Cycle70Hours
                                    ? '70 Hour Cycle Limit'
                                    : [
                                        key === ViolationsType.driving11Hours
                                          ? '11 Hour Driving Limit'
                                          : [
                                              key === ViolationsType.shift14hours
                                                ? '14 Hour Shift Limit'
                                                : [
                                                    key === ViolationsType.other
                                                      ? 'Other'
                                                      : [
                                                          key === ViolationsType.noPti
                                                            ? 'No PTI'
                                                            : [
                                                                key === ViolationsType?.insufficientPtiTime
                                                                  ? 'PTI time is not sufficient'
                                                                  : '',
                                                              ],
                                                        ],
                                                  ],
                                            ],
                                      ],
                                ]}
                          </span>
                          <Badge key={`id_${index + 1}`} count={eventCounts[key]} />
                        </div>
                      </li>
                    ))}
                  </span>
                </li>
              </>
            ) : null}
          </>
        );
      }

      return <span style={{ color: '#586973' }}>No violations </span>;
    },
  },
];

export default LogsTableColumns;
