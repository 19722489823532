import { Redirect } from 'react-router-dom';

import localStorageService from '../helpers/localStorageService';
// import { useAppSelector } from '../redux/hooks';

export default function Landing(): JSX.Element | null {
  const adminStatus = localStorageService.get('adminStatus');

  if (adminStatus !== 'active') {
    return <Redirect to="/auth/login" />;
  }

  // return <Redirect to="/dashboard" />;
  return <Redirect to="/units" />;
}
