import { DATE_ONLY, DEFAULT_EVENT_MMDDYY, HOURS_MINUTES_SECONDS, MONTH_DAY } from 'constants/dates';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Row, Col, Button, Spin, DatePicker, DatePickerProps, Dropdown, Menu, notification, Tabs } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { downloadReportPDF } from 'utils/form-validation';
import { svgIcons } from 'resources/icons';
import { ReactComponent as Logs } from 'resources/icons/Group 77.svg';
import { AiOutlineDown, AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { BiPen, BiTransferAlt } from 'react-icons/bi';
import {
  useGetLogForm,
  useGetDriverCSV,
  useCancelEditLog,
  useGetSingleUnitByDriverId,
  useNotificationSendEditLog,
} from 'hooks/unitHooks';
import { ReactComponent as Error } from 'resources/icons/exclamation-triangle-fill.svg';
import { useGetInspection, useGetInspectionReport } from 'hooks/inspectionHooks';
import { convertDateDriverHomeTimeZone } from 'helpers/utils';
import { BsChevronDown } from 'react-icons/bs';
import { IoIosMail } from 'react-icons/io';
import { useGetDriver } from 'hooks/driverHooks';
import { CancelEditRequest, CycleType, SendNotificationEditRequest } from 'services/unit';
import { CSVGraphStatusType, Violation } from 'types/hos';
import { TimeZone } from 'types/common';
import { queryClient } from 'hooks';
import { GET_HOS_DRIVER_CSV, GET_INSPECTION_REPORT } from 'hooks/keys';
import TabPane from 'antd/lib/tabs/TabPane';
import InfoModal from 'components/modal/modalInfo';
import LogInfoModal from './Partials/LogInfoModal/LogInfoModal';
import Graph from './Partials/GraphPlot/GraphPlot';
import ProgressClocks from './Partials/ProgressClocks/ProgressClocks';
import LogForm from './Partials/LogForm/LogForm';
import InspectionGrid from './Partials/InspectionsGrid/InspectionGrid';
import LogEditHistoryModal from './Partials/LogEditHistoryModal/LogEditHistoryModal';
import LogsEventListing from './Partials/LogsEventList/LogsEventList';
import CurrentLocation from './Partials/CurrentLocation/CurrentLocation';
import ViolationsGraph from './Partials/ViolationsGraph/ViolationsGraph';
import SendReportByEmail from './Partials/SendReportByEmailModal/SendReportByEmail';
import Tracking from './Partials/Tracking/Tracking';
import Certification from './Partials/Certification/Certification';
import ModifiedLogsEventList from './Partials/ModifiedLogsEventList/ModifiedLogsEventList';
import OriginalLogModal from './Partials/OriginalLog/OriginalLogModal';
import NormalizeLogModal from './Partials/NormalizeLogsModal/NormalizeLogModal';
import TransferLogModal from './Partials/TransferLogModal/TransferLogModal';

import './SingleDayLog.less';

export interface Filter {
  driverId: string;
  date: string;
}

type LocationType = {
  hash?: string;
  key?: string;
  pathname?: string;
  search?: string;
  state:
    | {
        logSelectedDate?: string;
        driverTimeZone?: string;
        tenantId?: string;
        lastActivityStatus?: string;
        currentDate?: string;
        currentTime?: string;
        // meta?: MetaDataType;
      }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    | any;
};

const SingleDayLog = () => {
  const histroy = useHistory();
  const location: LocationType = useLocation<LocationType>();
  const { name: driverNameByParams }: { name: string } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let logStorageData: any;
  if (!sessionStorage.getItem(`selectdLogDatail`)) {
    histroy.push('/logs');
  } else {
    logStorageData = JSON.parse(sessionStorage.getItem(`selectdLogDatail`) || '');
  }
  const selectdLogDatail = location?.state;
  const driverId = selectdLogDatail?.driverId || logStorageData?.driverId;

  const tenantId = selectdLogDatail?.tenantId || logStorageData?.tenantId;
  const driverTimeZone: string = selectdLogDatail?.driverTimeZone ?? logStorageData?.driverTimeZone;

  const [initialFilter, setInitialFilter] = useState<Filter>({
    driverId,
    date: logStorageData?.logSelectedDate || selectdLogDatail?.logSelectedDate,
  });

  const [isDownloadChecked, setIsDownloadCheked] = useState<boolean>(false);
  const [showSendReportModal, setShowSendReportModal] = useState<boolean>(false);
  const [showEditedLogHistoryModal, setShowEditedLogHistoryModal] = useState<boolean>(false);
  const [showCertifyLogModal, setShowCertifyLogModal] = useState<boolean>(false);
  const [showOriginalLogModal, setShowOriginalLogModal] = useState<boolean>(false);
  const [showCurrentLocation, setShowCurrentLocation] = useState<boolean>(false);
  const [showTrackings, setShowTrackings] = useState<boolean>(false);
  const [showInfoLogModal, setShowInfoLogModal] = useState<boolean>(false);
  const [showNormalizeModal, setShowNormalizeModal] = useState<boolean>(false);
  const [showTransferModal, setShowTransferModal] = useState<boolean>(false);
  const [showTransferConfirmationModal, setShowTransferConfirmationModal] = useState<boolean>(false);
  const [selectedLog, setSelectedLog] = useState<CSVGraphStatusType | null>();
  const [highlightedLog, setHighlightedLog] = useState<CSVGraphStatusType | null>();
  const [selectedRange, setSelectedRange] = useState<object>({ from: null, to: null });
  const [actionType, setActionType] = useState<number>(0);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  // APIs Call
  const { data: logData, isLoading } = useGetDriverCSV({ ...initialFilter });
  const { data: driverDetail } = useGetDriver(driverId);
  const { data: logFormData } = useGetLogForm({ ...initialFilter });
  const { data: inspectionData, isLoading: isInspectionLoading } = useGetInspection({ ...initialFilter });
  const { data: inspectionReport } = useGetInspectionReport({ ...initialFilter });
  const { data: singleUnitDetails } = useGetSingleUnitByDriverId(driverId);

  const cancelEditLog = useCancelEditLog();
  const sendNotification = useNotificationSendEditLog();

  const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: driverTimeZone };
  const currentDate = convertDateDriverHomeTimeZone(new Date(), tzCode).format(DATE_ONLY);

  const initialClocks = {
    breakSeconds: 0,
    cycleSeconds: 0,
    driveSeconds: 0,
    driveSecondsSplit: 0,
    shiftDutySecond: 0,
    shiftDutySecondsSplit: 0,
    isSplitActive: false,
  };

  const [tabKey, setTabKey] = useState(
    logData?.data?.editStatus?.isEditRequested ? 'edited-logs-events' : 'original-logs-events',
  );
  const [totalTime, setTotalTime] = useState<number>(0);
  const [clockData, setClockData] = useState<CycleType>(logData?.data?.graphData[0]?.meta?.clockData || initialClocks);
  const [violations, setViolations] = useState<Violation[]>(logData?.data?.graphData[0]?.meta?.voilations || []);
  const [editedCSVViolations, setEditedCSVViolations] = useState<Violation[]>(
    logData?.data?.editStatus?.editLogs[0]?.csvAfterUpdate?.voilations || [],
  );

  const scrollIntoViewFunction = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleNextDate = () => {
    const nextDate = moment(initialFilter.date).add(1, 'd').format(DATE_ONLY);
    setInitialFilter({ ...initialFilter, date: nextDate });
    sessionStorage.setItem(
      `selectdLogDatail`,
      JSON.stringify({
        logSelectedDate: nextDate,
        driverId,
        driverTimeZone: tzCode || selectdLogDatail?.driverTimeZone,
        tenantId: logStorageData?.tenantId || selectdLogDatail?.tenantId,
        lastActivityStatus:
          singleUnitDetails?.meta?.lastActivity?.currentEventCode ||
          logStorageData?.lastActivityStatus ||
          selectdLogDatail?.lastActivityStatus,
        currentDate:
          singleUnitDetails?.meta?.lastActivity?.currentDate ||
          logStorageData?.currentDate ||
          selectdLogDatail?.currentDate,
        currentTime:
          singleUnitDetails?.meta?.lastActivity?.currentTime ||
          logStorageData?.currentTime ||
          selectdLogDatail?.currentTime,
      }),
    );
  };
  const handlePrevDate = () => {
    const prevDate = moment(initialFilter.date).subtract(1, 'days').format(DATE_ONLY);
    setInitialFilter({ ...initialFilter, date: prevDate });
    sessionStorage.setItem(
      `selectdLogDatail`,
      JSON.stringify({
        logSelectedDate: prevDate,
        driverId,
        driverTimeZone: tzCode || selectdLogDatail?.driverTimeZone,
        tenantId: logStorageData?.tenantId || selectdLogDatail?.tenantId,
        lastActivityStatus:
          singleUnitDetails?.meta?.lastActivity?.currentEventCode ||
          logStorageData?.lastActivityStatus ||
          selectdLogDatail?.lastActivityStatus,
        currentDate:
          singleUnitDetails?.meta?.lastActivity?.currentDate ||
          logStorageData?.currentDate ||
          selectdLogDatail?.currentDate,
        currentTime:
          singleUnitDetails?.meta?.lastActivity?.currentTime ||
          logStorageData?.currentTime ||
          selectdLogDatail?.currentTime,
      }),
    );
  };

  const onChange: DatePickerProps['onChange'] = date => {
    const selectedDate = moment(date).startOf('day').format(DATE_ONLY);
    setInitialFilter({ ...initialFilter, date: selectedDate });
    sessionStorage.setItem(
      `selectdLogDatail`,
      JSON.stringify({
        logSelectedDate: selectedDate,
        driverId,
        driverTimeZone: tzCode || selectdLogDatail?.driverTimeZone,
        tenantId: logStorageData?.tenantId || selectdLogDatail?.tenantId,
        lastActivityStatus:
          singleUnitDetails?.meta?.lastActivity?.currentEventCode ||
          logStorageData?.lastActivityStatus ||
          selectdLogDatail?.lastActivityStatus,
        currentDate:
          singleUnitDetails?.meta?.lastActivity?.currentDate ||
          logStorageData?.currentDate ||
          selectdLogDatail?.currentDate,
        currentTime:
          singleUnitDetails?.meta?.lastActivity?.currentTime ||
          logStorageData?.currentTime ||
          selectdLogDatail?.currentTime,
      }),
    );
  };

  const cancelEditedRequest = (value: string) => {
    setLoader(true);
    const finalData: CancelEditRequest = {
      driverId,
      isApproved: value,
      dateTime: logData?.data?.editStatus?.editLogs[0]?.dateTime || '',
    };
    cancelEditLog
      // eslint-disable-next-line no-underscore-dangle
      .mutateAsync(finalData)
      .then(response => {
        setLoader(false);
        notification.success({ message: response?.message });
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        queryClient.refetchQueries([GET_INSPECTION_REPORT]);
        queryClient.removeQueries([GET_INSPECTION_REPORT]);
      })
      .catch(error => {
        setLoader(false);
        notification.error({ message: error?.message });
      });
  };

  const sendNotificationToDriver = () => {
    setLoading(true);
    const finalData: SendNotificationEditRequest = {
      driverId,
      tenantId,
      date: initialFilter?.date || '',
    };
    sendNotification
      .mutateAsync(finalData)
      .then(response => {
        setLoading(false);
        notification.success({ message: response?.message });
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      })
      .catch(error => {
        setLoading(false);
        notification.error({ message: error?.message });
      });
  };

  const onChangeTab = (key: string) => {
    setTabKey(key);
  };

  const resetActionType = () => {
    setActionType(0);
    setSelectedLog(null);
    setSelectedRange({ from: null, to: null });
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  const openTransferLogModal = () => {
    if (
      (singleUnitDetails?.meta?.lastActivity?.currentEventType === '1' &&
        singleUnitDetails?.meta?.lastActivity?.currentEventCode === '3') ||
      singleUnitDetails?.meta?.lastActivity?.currentEventType === '3'
    ) {
      setShowTransferConfirmationModal(true);
    } else {
      setShowTransferModal(true);
    }
  };

  const trnasferConfirmationBody = () => (
    <p className="modal-body-title">Truck engine is currently working before transfer make sure the engine is off.</p>
  );

  const handleTransferLogSubmit = () => {
    setShowTransferConfirmationModal(false);
  };
  const TransferLogButton = () => {
    if (
      selectedLog?.eventType === '1' &&
      singleUnitDetails?.meta?.lastActivity?.currentEventType === '1' &&
      (selectedLog?.eventCode === '1' || selectedLog?.eventCode === '2' || selectedLog?.eventCode === '4') &&
      (singleUnitDetails?.meta?.lastActivity?.currentEventCode === '1' ||
        singleUnitDetails?.meta?.lastActivity?.currentEventCode === '2' ||
        singleUnitDetails?.meta?.lastActivity?.currentEventCode === '4')
    ) {
      return (
        <div className="EditLogs actionButton">
          <Button className="ant-btn ant-btn-default tbl-top-btn" onClick={() => openTransferLogModal()}>
            <BiTransferAlt className="table-btn-icons" />
            Transfer Log
          </Button>
        </div>
      );
    }
    return null;
  };
  useEffect(() => {
    if (isDownloadChecked) {
      const pathName = inspectionReport?.data || '';
      if (!isEmpty(pathName)) {
        downloadReportPDF(pathName, initialFilter.date);
        setIsDownloadCheked(false);
      } else {
        notification.success({ message: inspectionReport?.message });
        setIsDownloadCheked(false);
      }
    }
  }, [isDownloadChecked]);

  useEffect(() => {
    setClockData(logData?.data?.graphData[0]?.meta?.clockData || initialClocks);
    setViolations(logData?.data?.graphData[0]?.meta?.voilations || []);
    setEditedCSVViolations(logData?.data?.editStatus?.editLogs[0]?.csvAfterUpdate?.voilations || []);
    setTabKey(logData?.data?.editStatus?.isEditRequested ? 'edited-logs-events' : 'original-logs-events');
    setSelectedLog(null);
    setActionType(0);
    setSelectedRange({ from: null, to: null });
  }, [initialFilter, logData]);
  return (
    <Spin spinning={isLoading}>
      <div className="logsDetail">
        <div className="generic-card-small singleDayLog">
          <Row>
            <Col span={7}>
              <ul className="logsInfo">
                <li className="infoList">
                  <svgIcons.DriverIcon width={18} height={18} />
                  <span className="logsInfoSpan">{`${driverNameByParams?.split('-').join(' ')} ${
                    driverDetail?.vehicleId?.vehicleId ? `(${driverDetail?.vehicleId?.vehicleId})` : ''
                  }`}</span>
                </li>
                <li className="infoList">
                  <svgIcons.ClockIcon width={18} height={18} />
                  <span className="logsInfoSpan">{moment.utc(totalTime * 1000).format(HOURS_MINUTES_SECONDS)}</span>
                </li>
                <li className="infoList">
                  <svgIcons.HalfMoonIcon />
                  <span className="logsInfoSpan">USA Property 70 hour / 8 day</span>
                </li>
                {violations?.length > 0 ? (
                  <li className="infoList">
                    <Error />
                    <span className="logsInfoSpan violations">{`${violations?.length} HOS Violations`}</span>
                  </li>
                ) : null}
              </ul>
              <div className="dateSection">
                <div className="dateSelectors">
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn daySelectorBtn"
                    onClick={handlePrevDate}
                    style={{ borderRadius: '4px 0px 0px 4px' }}>
                    <AiOutlineLeft className="table-btn-icons" />
                    <span className="prevDay">{moment(initialFilter.date).subtract(1, 'days').format(MONTH_DAY)}</span>
                  </Button>
                  <DatePicker
                    className="currentDay"
                    disabledDate={current => current && current?.endOf('day').isAfter(moment().tz(tzCode).endOf('day'))}
                    value={moment(initialFilter.date)}
                    format={MONTH_DAY}
                    allowClear={false}
                    picker="date"
                    onChange={onChange}
                    suffixIcon={<AiOutlineDown size={14} className="fa" />}
                  />
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn daySelectorBtn"
                    style={{ borderRadius: '0px 4px 4px 0px' }}
                    onClick={handleNextDate}
                    disabled={currentDate === initialFilter?.date}>
                    {moment(initialFilter.date).add(1, 'd').format(MONTH_DAY)}
                    <AiOutlineRight className="table-btn-icons nextDayIcon" />
                  </Button>
                </div>
              </div>
            </Col>
            <Col span={10}>{initialFilter?.date === currentDate ? <ProgressClocks clockData={clockData} /> : null}</Col>
            <Col span={7}>
              <div className="logs-selection-section">
                <div className="EditLogs actionButton">
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn"
                    onClick={() => {
                      scrollIntoViewFunction('current-location-tracking');
                      setShowTrackings(true);
                    }}>
                    <svgIcons.LocationIcon width={14} height={14} className="table-btn-icons" />
                    Tracking
                  </Button>
                </div>
                <div className="EditLogs actionButton">
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn"
                    onClick={() => {
                      setShowEditedLogHistoryModal(true);
                    }}>
                    <svgIcons.editClock className="table-btn-icons" />
                    History
                  </Button>
                </div>
                <div className="EditLogs actionButton">
                  <Button
                    className="ant-btn ant-btn-default tbl-top-btn"
                    onClick={() => {
                      setShowCurrentLocation(true);
                      scrollIntoViewFunction('current-location-tracking');
                      setShowTrackings(false);
                    }}>
                    <svgIcons.LocationIcon className="table-btn-icons" width={14} height={14} /> Current Location
                  </Button>
                </div>
                {((selectedLog?.eventType === '1' && selectedLog?.eventCode === '3') ||
                  (selectedLog?.eventType === '3' && selectedLog?.eventCode === '1')) && (
                  <div className="EditLogs actionButton">
                    <Button className="ant-btn ant-btn-default tbl-top-btn" onClick={() => setShowNormalizeModal(true)}>
                      <BiTransferAlt className="table-btn-icons" />
                      Normalize Log
                    </Button>
                  </div>
                )}
                {!(logData?.data?.editStatus?.isEditRequested && tabKey === 'original-logs-events') &&
                  !selectedLog &&
                  driverDetail?.enableElog && (
                    <div className="EditLogs actionButton">
                      <Button className="ant-btn ant-btn-default tbl-top-btn" onClick={() => setActionType(3)}>
                        <svgIcons.PencilFillIcon className="table-btn-icons" /> Edit Logs
                      </Button>
                    </div>
                  )}
                {TransferLogButton()}
                <div className="EditLogs actionButton">
                  <Button className="ant-btn ant-btn-default tbl-top-btn" onClick={() => setShowCertifyLogModal(true)}>
                    <BiPen className="table-btn-icons" /> Certify
                  </Button>
                </div>
                {!selectedLog && (
                  <div className="EditLogs actionButton">
                    <Button
                      className="ant-btn ant-btn-default tbl-top-btn"
                      onClick={() => {
                        setShowOriginalLogModal(true);
                      }}>
                      <Logs
                        className="table-btn-icons"
                        style={{ height: '13px', marginLeft: '4px', marginRight: '0px' }}
                      />
                      Original Logs
                    </Button>
                  </div>
                )}
                {!selectedLog && (
                  <>
                    {driverDetail?.enableElog && (
                      <div className="EditLogs actionButton">
                        <Button
                          className="ant-btn ant-btn-default tbl-top-btn"
                          onClick={() => {
                            setShowInfoLogModal(true);
                          }}>
                          <Logs
                            className="table-btn-icons"
                            style={{ height: '13px', marginLeft: '4px', marginRight: '0px' }}
                          />
                          Insert Info Log
                        </Button>
                      </div>
                    )}
                    <div className="EditLogs actionButton">
                      <Dropdown
                        overlay={
                          <Menu key="user-action-menu" className="actionDropDown downloadSendReport">
                            <Menu.Item key="edit-user">
                              <Button
                                className="dropdownBtn"
                                onClick={() => {
                                  setIsDownloadCheked(true);
                                }}>
                                <svgIcons.DownloadIcon className="dropdownBtn-btn-icons" />
                                <span> Download</span>
                              </Button>
                            </Menu.Item>
                            <Menu.Item key="edit-email">
                              <Button
                                className="dropdownBtn"
                                onClick={() => {
                                  setShowSendReportModal(true);
                                }}
                                disabled={loading}>
                                <IoIosMail size={16} color="#586973" className="dropdownBtn-btn-icons" />
                                <span> Send</span>
                              </Button>
                            </Menu.Item>
                          </Menu>
                        }>
                        <div className="icon-set">
                          <Button className="ant-btn ant-btn-default tbl-top-btn">
                            Report
                            <BsChevronDown
                              className="table-btn-icons"
                              style={{
                                left: '10px',
                              }}
                            />
                          </Button>
                        </div>
                      </Dropdown>
                    </div>
                  </>
                )}
                {selectedLog && (
                  <div className="EditLogs actionButton">
                    <Button className="ant-btn ant-btn-default tbl-top-btn" onClick={() => setActionType(2)}>
                      <svgIcons.PencilFillIcon className="table-btn-icons" /> Insert Duty Status
                    </Button>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </div>

        {logData?.data?.editStatus?.isEditRequested && (
          <div className="generic-card-small approvalEditLog">
            <Row>
              <Col span={12}>
                <div className="approvalTest">
                  <span className="approvalHeading">
                    {logData?.data?.editStatus?.editLogs[0]?.requestStatus !== 'Sent'
                      ? 'Request Created'
                      : 'Driver Approval'}
                  </span>
                </div>
              </Col>
              <Col span={12}>
                <div className="approvalSubmitSection">
                  <div className="submitSection">
                    <Button
                      className="tbl-top-btn canelBtn"
                      onClick={() => {
                        cancelEditedRequest('cancel');
                      }}
                      disabled={loader}>
                      Reject
                    </Button>
                    <Button
                      className="tbl-top-btn submitBtn"
                      onClick={() => {
                        cancelEditedRequest('confirm');
                      }}
                      disabled={loader}>
                      Accept
                    </Button>
                    {logData?.data?.editStatus?.editLogs[0]?.requestStatus !== 'Sent' && (
                      <Button className="tbl-top-btn sendBtn" onClick={() => sendNotificationToDriver()}>
                        send
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {!isLoading && (
          <div className="generic-card-large LogEvents">
            <div>
              <p className="pg-heading">Log Events</p>
              <div className="hr-line" />
            </div>
            <Graph
              logDataGraph={
                tabKey === 'original-logs-events'
                  ? logData?.data?.graphData[0]
                  : logData?.data?.editStatus?.editLogs[0]?.csvAfterUpdate
              }
              date={initialFilter?.date}
              violations={tabKey === 'original-logs-events' ? violations : editedCSVViolations}
              driverDetail={driverDetail}
              isEditRequestAvailbe={logData?.data?.editStatus?.isEditRequested && tabKey === 'original-logs-events'}
              setTotalTime={setTotalTime}
              selectedLog={selectedLog}
              setHighlightedLog={setHighlightedLog}
              setSelectedLog={setSelectedLog}
              actionType={actionType}
              setActionType={setActionType}
              resetActionType={resetActionType}
              selectedRange={selectedRange}
              setSelectedRange={setSelectedRange}
            />

            {actionType === 0 && (
              <>
                <ViolationsGraph
                  violations={tabKey === 'original-logs-events' ? violations : editedCSVViolations}
                  pti={tabKey === 'original-logs-events' ? logData?.data?.graphData[0]?.meta?.ptiViolation : []}
                />
                <Row>
                  <Col span={24}>
                    {logData?.data?.editStatus?.isEditRequested ? (
                      <div className="units-card-content">
                        <Tabs defaultActiveKey="edited-logs-events" onChange={onChangeTab}>
                          <TabPane tab="Current Log" key="original-logs-events">
                            <LogsEventListing
                              logDataGraph={logData?.data?.graphData[0]}
                              date={initialFilter?.date}
                              driverDetail={driverDetail}
                              highlightedLog={highlightedLog}
                              setActionType={setActionType}
                              // setSelectedLog={setSelectedLog}
                              setSelectedRange={setSelectedRange}
                              isEditRequested={logData?.data?.editStatus?.isEditRequested}
                            />
                          </TabPane>
                          <TabPane tab="Edited Log" key="edited-logs-events">
                            <ModifiedLogsEventList
                              logDataGraph={logData?.data?.editStatus?.editLogs[0]?.csvAfterUpdate}
                              date={initialFilter?.date}
                              driverDetail={driverDetail}
                              highlightedLog={highlightedLog}
                              setActionType={setActionType}
                              setSelectedLog={setSelectedLog}
                              setSelectedRange={setSelectedRange}
                            />
                          </TabPane>
                        </Tabs>
                      </div>
                    ) : (
                      <LogsEventListing
                        logDataGraph={logData?.data?.graphData[0]}
                        date={initialFilter?.date}
                        driverDetail={driverDetail}
                        highlightedLog={highlightedLog}
                        setActionType={setActionType}
                        setSelectedLog={setSelectedLog}
                        setSelectedRange={setSelectedRange}
                        isEditRequested={logData?.data?.editStatus?.isEditRequested!}
                      />
                    )}
                  </Col>
                </Row>
              </>
            )}
          </div>
        )}

        <LogForm logFormData={logFormData} date={initialFilter?.date} />

        <div id="current-location-tracking">
          {!showTrackings ? (
            <CurrentLocation
              driverId={initialFilter?.driverId}
              driverDetail={driverDetail}
              showCurrentLocation={showCurrentLocation}
              setShowCurrentLocation={setShowCurrentLocation}
            />
          ) : (
            <Tracking
              initialFilter={initialFilter}
              currentDate={convertDateDriverHomeTimeZone(new Date(), tzCode).format(DEFAULT_EVENT_MMDDYY)}
            />
          )}
        </div>

        <InspectionGrid
          inspectionData={inspectionData}
          driverNameByParams={driverNameByParams}
          isInspectionLoading={isInspectionLoading}
        />
      </div>

      {showSendReportModal && (
        <SendReportByEmail
          setShowSendReportModal={setShowSendReportModal}
          openModal={showSendReportModal}
          driverId={driverId}
          date={initialFilter?.date}
        />
      )}

      {showEditedLogHistoryModal && (
        <LogEditHistoryModal
          openModal={showEditedLogHistoryModal}
          onCancel={() => setShowEditedLogHistoryModal(false)}
          driverDetail={driverDetail}
          intialDate={initialFilter?.date}
        />
      )}

      {showOriginalLogModal && (
        <OriginalLogModal
          openModal={showOriginalLogModal}
          onCancel={() => setShowOriginalLogModal(false)}
          driverDetail={driverDetail}
          initialFilter={initialFilter}
        />
      )}

      {showInfoLogModal && (
        <LogInfoModal
          openModal={showInfoLogModal}
          onCancel={() => setShowInfoLogModal(false)}
          driverId={driverId}
          singleUnitDetails={singleUnitDetails}
          date={initialFilter?.date}
          tzCode={tzCode}
        />
      )}

      {showCertifyLogModal && (
        <Certification
          openModal={showCertifyLogModal}
          onCancel={() => setShowCertifyLogModal(false)}
          setShowCertifyLogModal={setShowCertifyLogModal}
          driverId={driverId}
          singleUnitDetails={singleUnitDetails}
        />
      )}

      {showNormalizeModal && (
        <NormalizeLogModal
          openModal={showNormalizeModal}
          selectedLogEntry={selectedLog!}
          onCancel={() => setShowNormalizeModal(false)}
          driverDetail={driverDetail}
          resetActionType={resetActionType}
        />
      )}

      {showTransferModal && (
        <TransferLogModal
          setShowTransferModal={setShowTransferModal}
          openModal={showTransferModal}
          selectedLogEntry={selectedLog!}
          singleUnitDetails={singleUnitDetails}
        />
      )}

      {showTransferConfirmationModal && (
        <InfoModal title="Transfer Log" body={trnasferConfirmationBody} onSubmit={() => handleTransferLogSubmit()} />
      )}
    </Spin>
  );
};

export default SingleDayLog;
