import * as yup from 'yup';

export const HosValidation = yup.object().shape({
  status: yup.string().nullable(false).required('You are required to select Duty status'),
  notes: yup
    .string()
    // .required('You are required to give Notes')
    // .min(4, 'Notes must be at least 4 characters')
    // .max(60, 'Notes must be at most 60 characters')
    .nullable(false),
  location: yup.string().nullable(false).required('You are required to give Location'),
  lat: yup.string().required('Lat is required').matches(/^\s*-?\s*([0-8]?[0-9](\.[0-9]+)?|90(\.0+)?)\s*$/, 'Please enter a valid Latitude'),
  lng: yup.string().required('Lng is required').matches(/^\s*-?((1[0-7][0-9]|[0-9]?[0-9])(\.[0-9]+)?|180(\.0+)?)\s*$/, 'Please enter a valid Longitude'),
  odometer: yup.string().required('Odometer is required').matches(/^[\d\s]+(\s*\.\s*[\d\s]+)?$/, 'Odometer must contain only numbers'),
  engHours: yup.string().required('Engine Hours is required').matches(/^[\d\s]+(\s*\.\s*[\d\s]+)?$/, 'Engine Hours must contain only numbers'),
});
