import { TENANT_ID } from 'constants/config';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Button, Row, Col, notification, Space, Checkbox } from 'antd';

import { BsLockFill } from 'react-icons/bs';
import { BiLogIn } from 'react-icons/bi';
import { IoIosMail } from 'react-icons/io';

import { useLoginMutation } from 'hooks/authHooks';
import localStorageService from 'helpers/localStorageService';
import { ResponseError } from 'types/common';
import { LoginRequest } from 'types/auth';

import Logo from 'resources/images/Group_34.png';
import { svgIcons } from 'resources/icons';

import './Login.less';

const Login = () => {
  const [invalidLoginState, setInavlidLoginState] = useState(false);
  const loginMutation = useLoginMutation();

  if (localStorageService.get('adminStatus') === 'active') {
    // window.location.href = '/dashboard';
    window.location.href = '/units';
  }

  const onFinish = async (values: LoginRequest) => {
    loginMutation
      .mutateAsync({ ...values, tenantId: TENANT_ID })
      .then(data => {
        const authData = data?.data;
        if (authData?.accessToken) {
          localStorageService.set('adminStatus', 'active');
          localStorageService.set('jwt', authData?.accessToken);
          localStorageService.set('userInfo', authData?.user);
          localStorageService.set('refreshToken', authData?.refreshToken);
          notification.success({
            message: 'Redirecting, please wait...',
          });
          // window.location.href = '/dashboard';
          window.location.href = '/units';
        }
      })
      // eslint-disable-next-line
      .catch((error: ResponseError) => {
        setInavlidLoginState(true);
      });
  };
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
  }, []);
  return (
    <div className="login-form-wrapper">
      <Row className="form-container">
        <Col offset={12} span={12} className="blur_bg">
          <div className="LoginForm">
            <Row>
              <Col span={24}>
                <Space
                  direction="horizontal"
                  style={{ width: '100%', justifyContent: 'center' }}
                  className="comapny_logo">
                  <img className="img-fluid logo-img pb-2" src={Logo} alt="logo" />
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                {invalidLoginState ? (
                  <div className="wrapperInavlidLogin">
                    <Space
                      direction="horizontal"
                      style={{ width: '100%', justifyContent: 'center' }}
                      className="invalidLoginText">
                      <svgIcons.errorIcon width={16} height={16} className="errorIcon" />
                      <p>
                        <span className="LoginFailedText">Login Failed!</span> Invalid login credentials!
                      </p>
                    </Space>
                  </div>
                ) : (
                  <Space
                    direction="horizontal"
                    style={{ width: '100%', justifyContent: 'center' }}
                    className="welcome-text">
                    <p>Welcome! Please login to your account.</p>
                  </Space>
                )}
              </Col>
            </Row>
            <Form layout="vertical" onFinish={onFinish} className="loginForm">
              <Form.Item
                label="Username / Email"
                name="userName"
                className="form-label"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Username!',
                  },
                ]}>
                <Input
                  prefix={<IoIosMail size={20} className="login-input-icons" />}
                  type="text"
                  placeholder="Enter Username / Email"
                  className="form-control"
                />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                className="form-label"
                rules={[
                  {
                    required: true,
                    message: 'Please input your Password!',
                  },
                  {
                    min: 8,
                    message: 'Must be at least 8 characters.',
                  },
                ]}>
                <Input.Password
                  prefix={<BsLockFill size={20} className="login-input-icons" />}
                  suffix={<svgIcons.eyeFillIcon width={20} height={20} />}
                  type="password"
                  placeholder="Your Password"
                  className="form-control"
                />
              </Form.Item>

              <Row>
                <Col span={12}>
                  <Form.Item name="remember" valuePropName="checked">
                    <Checkbox>
                      <span className="checkbox-text">Keep me signed in</span>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="remember" className="text-right">
                    <Link to="/auth/forgotpassword">Forgot Password</Link>
                  </Form.Item>
                </Col>
              </Row>

              <Button
                type="primary"
                htmlType="submit"
                block
                className="login-btn"
                style={{ marginTop: '1rem', display: 'flex' }}
                loading={loginMutation.isLoading}>
                <BiLogIn size={20} style={{ marginRight: '10px' }} /> Login
              </Button>
            </Form>
          </div>
          <div className="terms_policy">
            <p className="termPolicy-text">Term of Use. Privacy Policy</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
