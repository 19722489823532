import { DEFAULT_EVENT_MMDDYY } from 'constants/dates';
import React from 'react';
// import { useHistory, useLocation } from 'react-router-dom';
import { Table, Row, Col, Button, Modal } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DriverResponseType, TimeZone } from 'services/drivers';
import { CSVGraphStatusType } from 'types/hos';
import { GetOriginalLogsRequest } from 'services/unit';

import { convertDateDriverHomeTimeZone, getStatusEventCode } from 'helpers/utils';
import { useGetOriginalLogs } from 'hooks/unitHooks';
import Graph from '../GraphPlot/GraphPlot';
import LogsEventTableColumns from './OriginalLogModalTableColumns';

import './OriginalLogModal.less';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const OriginalLogModal = ({
  openModal,
  onCancel,
  driverDetail,
  initialFilter,
}: {
  openModal: boolean;
  onCancel: (value: React.SetStateAction<boolean>) => void;
  driverDetail: DriverResponseType | undefined;
  initialFilter: {
    driverId: string;
    date: string;
  };
}) => {
  const finaldata: GetOriginalLogsRequest = {
    id: initialFilter.driverId,
    date: initialFilter.date,
  };
  const logData = useGetOriginalLogs(finaldata);

  const logStorageData = JSON.parse(sessionStorage.getItem(`selectdLogDatail`) || '');
  const convertedDate = moment(initialFilter.date, 'YYYY-MM-DD').format(DEFAULT_EVENT_MMDDYY);
  const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: logStorageData?.driverTimeZone };
  const currentDateInZone = convertDateDriverHomeTimeZone(new Date(), tzCode);

  const csvData = logData?.data?.data?.csvBeforeUpdate?.csv;
  const tableRef = React.useRef<any>();
  const logOffDutyEvents: CSVGraphStatusType[] = csvData?.eldEventListForDriversRecordOfDutyStatus
    ?.filter(
      (item: CSVGraphStatusType) =>
        (item?.eventType === '1' || item?.eventType === '3') &&
        item?.eventRecordStatus === '1' &&
        item?.eventDate === convertedDate,
    )
    ?.sort((a: CSVGraphStatusType, b: CSVGraphStatusType) => Number(a.eventTime) - Number(b.eventTime)) || [
      {
        eventSequenceIdNumber: '1',
        eventRecordStatus: '1',
        eventRecordOrigin: '1',
        eventType: '1',
        eventCode: getStatusEventCode(logStorageData?.lastActivityStatus, logStorageData?.currentDate, convertedDate),
        eventDate: convertedDate,
        eventTime: '000000',
        accumulatedVehicleMiles: 0,
        accumulatedEngineHours: 0,
        eventLatitude: 0,
        eventLongitude: 0,
        distanceSinceLastValidCoordinates: 0,
        correspondingCmvOrderNumber: '1',
        userOrderNumberForRecordOriginator: '1',
        malfunctionIndicatorStatusForEld: '0',
        dataDiagnosticEventIndicatorForDriver: '1',
        eventDataCheckValue: 'F2',
        lineDataCheckValue: '67',
        address: logStorageData?.meta?.lastActivity?.address || '',
      },
    ];

  const intLocationEvents: CSVGraphStatusType[] =
    csvData?.eldEventListForDriversRecordOfDutyStatus?.filter((item: CSVGraphStatusType) => item?.eventType === '2') ||
    [];
  const loginLogoutEvents: CSVGraphStatusType[] =
    csvData?.eldLoginLogoutReport?.filter((item: CSVGraphStatusType) => item?.eventTypeExtra === '5') || [];

  const certifyEvents: CSVGraphStatusType[] =
    csvData?.eldEventListForDriverCertificationOfOwnRecords?.filter(
      (item: CSVGraphStatusType) => item?.certificateType === '4',
    ) || [];

  const updatedArray: CSVGraphStatusType[] = [];

  if (!isEmpty(logOffDutyEvents)) {
    const currentDate = currentDateInZone.format(DEFAULT_EVENT_MMDDYY);

    for (let i = 0; i < logOffDutyEvents?.length; i += 1) {
      const start = moment(logOffDutyEvents[i]?.eventTime, 'HH:mm:ss');
      const end = moment(
        logOffDutyEvents[i + 1]?.eventTime
          ? logOffDutyEvents[i + 1]?.eventTime
          : [convertedDate === currentDate ? currentDateInZone.format('HHmmss') : '235959'],
        'HH:mm:ss',
      );

      const showData = moment.utc(end.diff(start)).format('HH:mm:ss');
      const finalTime = showData.split(':');
      const duration = `${finalTime[0] !== '00' ? `${finalTime[0]} hrs` : ''} ${finalTime[1] !== '00' ? `${finalTime[1]} min` : ''
        } ${finalTime[2] !== '00' ? `${finalTime[2]} sec` : ''}`;
      updatedArray?.push({
        ...logOffDutyEvents[i],
        duration,
        eventEndTime: end?.format('HHmmss'),
        durationTime: showData,
      });
    }
  }

  const vehicleId = csvData?.powerUnitLine?.powerUnitNumber || driverDetail?.vehicleId?.vehicleId || '';

  const logPowerUpDownEvents = csvData?.cmvEnginePowerUpShutDownActivity || [];
  const powerUpDownEvents: CSVGraphStatusType[] = logPowerUpDownEvents?.map((item: CSVGraphStatusType) => ({
    ...item,
    eventType: '6',
  }));

  const mergedAllevents = [
    ...updatedArray,
    ...intLocationEvents,
    ...powerUpDownEvents,
    ...certifyEvents,
    ...loginLogoutEvents,
  ];

  return (
    <Modal
      className="app-modal"
      open={openModal}
      title="Original Logs"
      width={1600}
      onCancel={() => onCancel(false)}
      footer={null}>
      <>
        <div className="originalLog-modal">
          <Graph
            logDataGraph={logData?.data?.data?.csvBeforeUpdate!}
            date={initialFilter.date}
            violations={logData?.data?.data?.csvBeforeUpdate?.voilations!}
            driverDetail={driverDetail}
          />
        </div>
        <div className="table-container LogsEventListing">
          <Row>
            <Col span={24}>
              <div>
                <Table
                  columns={LogsEventTableColumns({
                    vehicleId,
                  })}
                  pagination={false}
                  ref={tableRef}
                  dataSource={mergedAllevents?.sort((a, b) => Number(a?.eventTime) - Number(b?.eventTime))}
                  rowKey={record => record.eventSequenceIdNumber}
                  scroll={{ y: 350 }}
                  size="small"
                  showSorterTooltip={false}
                />
              </div>
            </Col>
          </Row>
          <div className="custom-csv-file-footer">
            <Button className="ant-btn-primary" onClick={() => onCancel(false)}>
              close
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default OriginalLogModal;
