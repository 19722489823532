import moment from "moment";

export const isValidNumber = (value: string) => {
  const re = /^([0-9]\d*)$/;
  return re.test(value);
};

export const checkAddress = (address: string) => {
  const check = /^[a-zA-Z0-9\s, .'-]*$/;
  return check.test(address);
};

export const formatInPKR = (amount: number) =>
  new Intl.NumberFormat('en-PK', { style: 'currency', currency: 'PKR' }).format(amount);

export const downloadReportPDF = (pdfReport: string, date: string) => {
  const linkSource = `data:application/pdf;base64,${pdfReport}`;
  const downloadLink = document.createElement('a');
  const fileName = `Daily log Report ${date}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  // Remove the link element from the DOM
  // document.body.removeChild(downloadLink);
};

export const downloadReportPDFIfta = (pdfReport: string, date: string) => {
  const linkSource = `data:application/pdf;base64,${pdfReport}`;
  const downloadLink = document.createElement('a');
  const fileName = `IFTA Report ${date}.pdf`;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();

  // Remove the link element from the DOM
  document.body.removeChild(downloadLink);
};

export const downloadReportCSV = (csvReport: string, date: string) => {
  const fileName = `IFTA Report ${date}.csv`;
  const rows = csvReport.split('\n');
  const csvContent = rows.map(row => row.split(',').join(',')).join('\n');
  const blob = new Blob([csvContent], { type: 'text/csv' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();

  // Remove the link element from the DOM
  document.body.removeChild(a);
};

export const downloadReportPDFandView = (pdfReport: string, date: string) => {
  const linkSource = `data:application/pdf;base64,${pdfReport}`;
  const viewLink = window.open('', '_blank');
  viewLink!.document.write(`<object data="${linkSource}" type="application/pdf" width="100%" height="100%"></object>`);
};
