import { useEffect, useState } from 'react';
import { Row, Col, Space, Button } from 'antd';
import { Form, FormItem, Input, SubmitButton } from 'formik-antd';
import { Formik, FormikHelpers } from 'formik';
import { Link, useLocation } from 'react-router-dom';

import { ResetPasswordValidation } from 'schemas/auth';
import { ResetPasswordType } from 'types/auth';
import { useResetPassword } from 'hooks/authHooks';
import { ErrorNotifications } from 'helpers/utils';

import { BsLockFill } from 'react-icons/bs';

import Logo from 'resources/images/Group_34.png';
import { svgIcons } from 'resources/icons';

import '../Login.less';

export type UserEmail = {
  email: string;
};

const ResetPassword = () => {
  const location = useLocation();
  const userEmail = location.state as UserEmail;
  const [showConfirmationComponent, setShowConfirmationComponent] = useState(false);

  const resetPassword = useResetPassword();

  const formInitialValues = {
    password: '',
    confirmPassword: '',
  };

  const handleSumbit = (values: ResetPasswordType, formikHelpers: FormikHelpers<ResetPasswordType>) => {
    formikHelpers.validateForm();
    formikHelpers.setSubmitting(true);

    const body = {
      ...values,
      email: userEmail?.email,
    };

    resetPassword
      .mutateAsync(body)
      .then(response => {
        if (response?.success === true) {
          setShowConfirmationComponent(true);
          localStorage.clear();
        }
      })
      .catch(error => {
        ErrorNotifications(error?.message);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  };
  useEffect(() => {
    sessionStorage.setItem('resetPassword', 'true');
    sessionStorage.removeItem('forgotPassword');
  }, []);
  return (
    <div className="login-form-wrapper">
      <Row className="form-container">
        <Col offset={12} span={12} className="blur_bg">
          <div className="LoginForm">
            <Row>
              <Col span={24}>
                <Space
                  direction="horizontal"
                  style={{ width: '100%', justifyContent: 'center' }}
                  className="comapny_logo">
                  <img className="img-fluid logo-img pb-2" src={Logo} alt="logo" />
                </Space>
              </Col>
              {showConfirmationComponent ? (
                <>
                  <Col span={24}>
                    <div className="ConfirmationSuccessModalIcon">
                      <svgIcons.SuccessModalIcon width={120} height={120} />
                    </div>
                  </Col>
                </>
              ) : (
                <></>
              )}
            </Row>
            <Row>
              <Col span={24}>
                {!showConfirmationComponent ? (
                  <Space
                    direction="horizontal"
                    style={{ width: '100%', justifyContent: 'center', marginBottom: '10px' }}
                    className="formHeading">
                    <p>CREATE NEW PASSWORD</p>
                  </Space>
                ) : (
                  <Space
                    direction="horizontal"
                    style={{ width: '100%', justifyContent: 'center' }}
                    className="formHeading">
                    <p>PASSWORD CHANGED</p>
                  </Space>
                )}
              </Col>
            </Row>
            <Formik
              initialValues={formInitialValues}
              validationSchema={ResetPasswordValidation}
              onSubmit={handleSumbit}>
              {() => (
                <Form layout="vertical" className="loginForm">
                  <Row>
                    {!showConfirmationComponent ? (
                      <>
                        <Col span={24}>
                          <FormItem label="New Password" name="password" className="form-label" required>
                            <Input.Password
                              name="password"
                              prefix={<BsLockFill size={20} className="login-input-icons" />}
                              suffix={<svgIcons.eyeFillIcon width={20} height={20} />}
                              type="password"
                              placeholder="Enter a New Password"
                              className="form-control"
                            />
                          </FormItem>
                          <FormItem label="Confirm New Password" name="confirmPassword" className="form-label" required>
                            <Input
                              name="confirmPassword"
                              prefix={<BsLockFill size={20} className="login-input-icons" />}
                              type="password"
                              placeholder="Re-enter a New Password"
                              className="form-control"
                            />
                          </FormItem>
                        </Col>
                      </>
                    ) : (
                      <>
                        <Col span={24}>
                          <Space direction="horizontal" className="forgotPasswordInfoText changedPassword">
                            <p>Your password has been successfully changed.</p>
                          </Space>
                        </Col>
                      </>
                    )}

                    <Col span={24}>
                      {!showConfirmationComponent ? (
                        <SubmitButton type="primary" htmlType="submit" block className="login-btn forgotButton">
                          <svgIcons.RotateLockIcon style={{ marginRight: '10px' }} /> RESET PASSWORD
                        </SubmitButton>
                      ) : (
                        <Link to="/auth/login">
                          <Button
                            type="primary"
                            htmlType="submit"
                            block
                            className="login-btn"
                            style={{ marginTop: '1rem', display: 'flex' }}>
                            Login
                          </Button>
                        </Link>
                      )}
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
          <div className="terms_policy">
            <p className="termPolicy-text">Term of Use. Privacy Policy</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default ResetPassword;
