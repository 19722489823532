import { useLocation } from 'react-router';
import { Col, Row, Image } from 'antd';
import { isEmpty } from 'lodash';
import { InspectionType } from 'services/inspection';
import { BsCheck2All } from 'react-icons/bs';
import { svgIcons } from 'resources/icons';

import './InspectionReport.less';

const InspectionReport = () => {
  const location = useLocation();
  const getInspectionDefect = location?.state as InspectionType;

  let isVehicleDefect = false;
  let isAssetDefect = false;
  let driverSignature = false;
  let mechanicSignature = false;
  let isTrailerDefectResolved = false;
  let isVehicleDefectResolved = false;
  let isAssetsDefectResolved = false;

  /* eslint-disable */
  if (getInspectionDefect?.defectsCategory?.vehicle.length > 0) {
    isVehicleDefect = true;
    getInspectionDefect.defectsCategory.trailer.map(item => {
      if (item?.resolved) isVehicleDefectResolved = item?.resolved;
    });
  }
  /* eslint-disable */
  if (getInspectionDefect?.defectsCategory?.trailer.length > 0) {
    isAssetDefect = true;
    getInspectionDefect?.defectsCategory?.trailer.map(item => {
      if (item?.resolved) isTrailerDefectResolved = item?.resolved;
    });
  }
  if (getInspectionDefect?.signatures?.driverSignature?.imagePath) {
    driverSignature = true;
  }
  if (getInspectionDefect?.signatures?.mechanicSignature?.imagePath) {
    mechanicSignature = true;
  }

  // eslint-disable-next-line
  getInspectionDefect?.defectsCategory?.vehicle.map(item => {
    isVehicleDefectResolved = item?.resolved;
  });
  // eslint-disable-next-line
  getInspectionDefect?.defectsCategory?.trailer.map(item => {
    isAssetsDefectResolved = item?.resolved;
  });
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <div className="generic-card-small InspectionReport">
              <p
                className="pg-heading"
                style={{
                  textTransform: 'capitalize',
                }}>{`${getInspectionDefect?.inspectionType} Inspection Defects`}</p>
              <div
                className="mainColumn"
                style={{ padding: '27px', background: '#F0F0F5 0% 0% no-repeat padding-box' }}>
                <Row gutter={32}>
                  <Col span={12} style={{ borderRight: '1px solid var(--border-color)' }}>
                    <div className={`columnBg ${isAssetDefect || isVehicleDefect ? 'defectColumn' : 'noDefectsColum'}`}>
                      <Row>
                        <Col span={16}>
                          <p className="colHeading">
                            {!isEmpty(getInspectionDefect?.vehicleManualId)
                              ? `Vehicle (${getInspectionDefect?.vehicleManualId})`
                              : 'Vehicle'}{' '}
                            Defects
                          </p>
                        </Col>
                        <Col span={8}>
                          <p className="colHeading2">Status</p>
                        </Col>
                      </Row>

                      {isVehicleDefect ? (
                        <>
                          {getInspectionDefect?.defectsCategory?.vehicle.map((item, index) => {
                            return (
                              <>
                                <div className="hr-line" />
                                <Row>
                                  <Col span={16}>
                                    <div className="defectInfo">
                                      <div style={{ display: 'flex' }}>
                                        <span className="numb" style={{ fontWeight: 'bold' }}>
                                          {index + 1}
                                        </span>
                                        .<span className="defectType">{item?.defects?.defectName}</span>
                                        <div
                                          className={`DefectBadge ${
                                            item?.defectsType === 'major'
                                              ? 'major'
                                              : [item?.defectsType === 'minor' ? 'minor' : '']
                                          }`}>
                                          {item?.defectsType}
                                        </div>
                                      </div>
                                      <p className="notes">
                                        <span style={{ color: '#122640', fontWeight: 'bold', marginLeft: '17px' }}>
                                          Notes:
                                        </span>{' '}
                                        {item?.Notes}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col span={5}>
                                    <p>
                                      {!item?.resolved ? (
                                        <span style={{ color: '#52C41A', fontWeight: 'bold' }}>Corrected</span>
                                      ) : (
                                        <span style={{ color: '#122640', fontWeight: 'bold' }}>
                                          Not To Be Corrected
                                        </span>
                                      )}
                                    </p>
                                  </Col>
                                  <Col span={3}>
                                    <div className="defectImg">
                                      <Image width={50} src={item?.imagePath} />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col span={24}>
                              <div className="hr-line" />
                              <div
                                className={`noDataContainer ${
                                  isAssetDefect || isVehicleDefect ? 'defectFound' : 'noDefectsOverAll'
                                }`}>
                                <span style={{ fontWeight: 'bold', color: '#52C41A' }}>
                                  No defect found, vehicle is safe to move.
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className={`columnBg ${isAssetDefect || isVehicleDefect ? 'defectColumn' : 'noDefectsColum'}`}>
                      <Row>
                        <Col span={16}>
                          <p className="colHeading">
                            {!isEmpty(getInspectionDefect?.trailerNumber)
                              ? `Trailer (${getInspectionDefect?.trailerNumber})`
                              : 'Trailer'}{' '}
                            Defects
                          </p>
                        </Col>
                        <Col span={8}>
                          <p className="colHeading2">Status</p>
                        </Col>
                      </Row>

                      {isAssetDefect ? (
                        <>
                          {getInspectionDefect?.defectsCategory?.trailer.map((item, index) => {
                            return (
                              <>
                                <div className="hr-line" />
                                <Row>
                                  <Col span={16}>
                                    <div className="defectInfo">
                                      <div style={{ display: 'flex' }}>
                                        <span className="numb" style={{ fontWeight: 'bold' }}>
                                          {index + 1}
                                        </span>
                                        .<span className="defectType">{item?.defects?.defectName}</span>
                                        <div
                                          className={`DefectBadge ${
                                            item?.defectsType === 'major'
                                              ? 'major'
                                              : [item?.defectsType === 'minor' ? 'minor' : '']
                                          }`}>
                                          {item?.defectsType}
                                        </div>
                                      </div>
                                      <p className="notes">
                                        <span style={{ color: '#122640', fontWeight: 'bold', marginLeft: '17px' }}>
                                          Notes:
                                        </span>{' '}
                                        {item?.Notes}
                                      </p>
                                    </div>
                                  </Col>
                                  <Col span={5}>
                                    <p>
                                      {!item?.resolved ? (
                                        <span style={{ color: '#52C41A', fontWeight: 'bold' }}>Corrected</span>
                                      ) : (
                                        <span style={{ color: '#122640', fontWeight: 'bold' }}>
                                          Not To Be Corrected
                                        </span>
                                      )}
                                    </p>
                                  </Col>
                                  <Col span={3}>
                                    <div className="defectImg">
                                      <Image width={50} src={item?.imagePath} />
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <Row>
                            <Col span={24}>
                              <div className="hr-line" />
                              <div
                                className={`noDataContainer ${
                                  isAssetDefect || isVehicleDefect ? 'defectFound' : 'noDefectsOverAll'
                                }`}>
                                <span style={{ fontWeight: 'bold', color: '#52C41A' }}>
                                  No defect found, trailer is safe to move.
                                </span>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col span={24}>
                  <div className="generic-card-small driverSignature">
                    <div className="hr-line" />
                    <p className="pg-heading">Driver Signature</p>
                    {driverSignature ? (
                      <>
                        {isAssetDefect || isVehicleDefect ? (
                          <>
                            <div style={{ position: 'relative', marginLeft: '250px' }}>
                              <p className="signatureText">I certify that these entries are true and correct.</p>
                            </div>
                            <div
                              // style={{
                              //   display: 'flex',
                              //   justifyContent: 'center',
                              //   marginRight: '265px',
                              //   marginTop: '-42px',
                              // }}
                              className="singnatureImage">
                              <img
                                src={getInspectionDefect?.signatures?.driverSignature?.imagePath}
                                alt="driverSignature"
                                style={{ width: '247px', height: '100px' }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="defectRepairStatus resolveRepairStatus">
                              <BsCheck2All fill="#28A745" size={20} />
                              <span className="defectText">Vehicle Condition Satisfactory</span>
                            </div>
                            <div
                              // style={{
                              //   display: 'flex',
                              //   justifyContent: 'center',
                              //   marginRight: '265px',
                              //   marginTop: '-42px',
                              // }}
                              className="singnatureImage2">
                              <img
                                src={getInspectionDefect?.signatures?.driverSignature?.imagePath}
                                alt="driverSignature"
                                style={{ width: '247px', height: '100px' }}
                              />
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col span={24}>
                            <div className="hr-line" />
                            <div className="mechanicSignature">
                              <svgIcons.SingatureErrorIcon width={90} />
                              <span>No Signature</span>
                            </div>
                          </Col>
                        </Row>
                      </>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* {isAssetDefect || isVehicleDefect ? (
          <Row>
            <Col span={24}>
              <div className="generic-card-small driverSignature">
                <Row gutter={80}>
                  <Col span={12}>
                    <p className="pg-heading">Repair Status</p>
                    {isTrailerDefectResolved && isVehicleDefectResolved ? (
                      <div
                        className={`defectRepairStatus ${
                          isTrailerDefectResolved && isVehicleDefectResolved
                            ? 'resolveRepairStatus'
                            : 'errorRepairStatus'
                        }`}>
                        <BsCheck2All fill="#28A745" size={20} />
                        <span className="defectText">Defects Corrected</span>
                      </div>
                    ) : (
                      <div className="defectRepairStatus">
                        <svgIcons.errorIcon />
                        <span
                          className="defectText"
                          style={{
                            color: '#F2163E',
                            font: 'normal normal bold 18px/25px Roboto',
                            width: '203px',
                            height: '22px',
                            marginLeft: '9px',
                          }}>
                          Defects Status Unknown
                        </span>
                      </div>
                    )}
                  </Col>
                  <Col span={12}>
                    <p className="pg-heading">Mechanic Signature</p>
                    <div className="hr-line" />
                    {mechanicSignature ? (
                      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <img
                          src={getInspectionDefect?.signatures?.mechanicSignature?.imagePath}
                          alt="driverSignature"
                          style={{ width: '200px' }}
                        />
                      </div>
                    ) : (
                      <div className="mechanicSignature">
                        <svgIcons.SingatureErrorIcon width={90} />
                        <span>No Signature</span>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        ) : null} */}
      </Col>
    </Row>
  );
};

export default InspectionReport;
