import { DEFAULT_EVENT_MMDDYY } from 'constants/dates';
import { Row, Col, Button, Modal, Space, notification } from 'antd';
import { Formik, FormikHelpers } from 'formik';
import { Form, FormItem, Input, Select, SubmitButton, DatePicker } from 'formik-antd';
import { TimePicker } from 'components/timepicker/TimePicker';
import { svgIcons } from 'resources/icons';
import { BsFillClockFill, BsSpeedometer2, BsSpeedometer } from 'react-icons/bs';
import { AiOutlineDown } from 'react-icons/ai';
import { FaFileSignature } from 'react-icons/fa';
import { CSVGraphStatusType, UnitType } from 'types/hos';
import * as yup from 'yup';
import moment, { Moment } from 'moment';
import { ImLocation } from 'react-icons/im';
import { useGetCoordinates, useGetLatLong, useSaveInsertInfoLog } from 'hooks/unitHooks';
import { queryClient } from 'hooks';
import { GET_HOS_DRIVER_CSV, GET_SINGLE_UNIT_BY_DRIVERID } from 'hooks/keys';
import { InsertInfoLogRequest } from 'services/unit';

// import 'components/modal/modal';
import 'styles/modal.less';
import './LogInfoModal.less';

interface FormValues {
  time: moment.Moment | string;
  status: string;
  lat?: string;
  lng?: string;
  location: string;
  odometer: string;
  engHours: string;
  shippingDocument: string;
  trailer: string;
  notes: string;
  driverSignature: string;
  certifyDate: moment.Moment | string;
  state?: string;
}

const LogInfoModal = ({
  openModal,
  onCancel,
  driverId,
  singleUnitDetails,
  selectedInfoLogs,
  date,
  tzCode,
  setShowBulkButton,
  setSelectedRowKeys,
  setSelectedLogs,
}: {
  openModal: boolean;
  driverId: string | undefined;
  onCancel: (value: React.SetStateAction<boolean>) => void;
  singleUnitDetails: UnitType | undefined;
  selectedInfoLogs?: CSVGraphStatusType | undefined;
  date?: string;
  tzCode: string;
  setShowBulkButton?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedRowKeys?: React.Dispatch<React.SetStateAction<string[]>>;
  setSelectedLogs?: React.Dispatch<React.SetStateAction<string[] | undefined>>

}) => {
  const saveInsertInfo = useSaveInsertInfoLog();
  const getLocation = useGetLatLong();
  const getCoordinates = useGetCoordinates();
  let statusSelected = '';
  if (selectedInfoLogs?.eventCode === '1' && selectedInfoLogs?.eventType === '2') {
    statusSelected = 'intmediateDriving';
  } else if (selectedInfoLogs?.eventCode === '2' && selectedInfoLogs?.eventType === '2') {
    statusSelected = 'intmediatePersonal';
  } else if (selectedInfoLogs?.eventCode === '1' && selectedInfoLogs?.eventType === '6') {
    statusSelected = 'powerupDriving';
  } else if (selectedInfoLogs?.eventCode === '2' && selectedInfoLogs?.eventType === '6') {
    statusSelected = 'powerupPersonal';
  } else if (selectedInfoLogs?.eventCode === '3' && selectedInfoLogs?.eventType === '6') {
    statusSelected = 'powerdownDriving';
  } else if (selectedInfoLogs?.eventCode === '4' && selectedInfoLogs?.eventType === '6') {
    statusSelected = 'powerdownPersonal';
  } else if (selectedInfoLogs?.certificateType === '4') {
    statusSelected = 'certify';
  } else if (selectedInfoLogs?.eventCode === '1' && selectedInfoLogs?.eventTypeExtra === '5') {
    statusSelected = 'login';
  } else if (selectedInfoLogs?.eventCode === '2' && selectedInfoLogs?.eventTypeExtra === '5') {
    statusSelected = 'logout';
  }

  const formInitialValues: FormValues = {
    time: selectedInfoLogs ? moment(`${selectedInfoLogs?.eventTime}`, 'HHmmss') : '',
    status: selectedInfoLogs ? statusSelected : '',
    location: selectedInfoLogs?.address || '',
    lat:
      selectedInfoLogs?.eventTypeExtra === '5'
        ? selectedInfoLogs?.loginLatitude
        : selectedInfoLogs?.eventLatitude || '',
    lng:
      selectedInfoLogs?.eventTypeExtra === '5'
        ? selectedInfoLogs?.loginLongitude
        : selectedInfoLogs?.eventLongitude || '',
    odometer: selectedInfoLogs?.totalVehicleMilesDutyStatus || '',
    engHours: selectedInfoLogs?.totalEngineHoursDutyStatus || '',
    shippingDocument: selectedInfoLogs?.shippingDocumentNumber || '',
    trailer: selectedInfoLogs?.trailerNumber || '',
    notes: selectedInfoLogs?.notes || '',
    driverSignature: singleUnitDetails?.imageName || '',
    certifyDate: selectedInfoLogs ? moment(`${selectedInfoLogs?.eventDate}`, DEFAULT_EVENT_MMDDYY) : '',
  };

  const { Option } = Select;
  const validationSchema = yup.object().shape({
    time: yup.mixed().required('Time is required'),
    status: yup.string().required('Status is required'),
    location: yup.string().when('status', {
      is: (value: string) => value !== 'certify',
      then: yup.string().required('Location is required'),
    }),
    lat: yup.string().when('status', {
      is: (value: string) => value !== 'certify',
      then: yup
        .string()
        .required('Lat is required')
        .matches(/^\s*-?\s*([0-8]?[0-9](\.[0-9]+)?|90(\.0+)?)\s*$/, 'Please enter a valid Latitude'),
      otherwise: yup.string().nullable(true),
    }),
    lng: yup.string().when('status', {
      is: (value: string) => value !== 'certify',
      then: yup
        .string()
        .required('Lng is required')
        .matches(/^\s*-?((1[0-7][0-9]|[0-9]?[0-9])(\.[0-9]+)?|180(\.0+)?)\s*$/, 'Please enter a valid Longitude'),
    }),
    odometer: yup.string().when('status', {
      is: (value: string) => value !== 'certify',
      then: yup
        .string()
        .required('Odometer is required')
        .matches(/^[\d\s]+(\s*\.\s*[\d\s]+)?$/, 'Odometer must contain only numbers'),
    }),
    engHours: yup.string().when('status', {
      is: (value: string) => value !== 'certify',
      then: yup
        .string()
        .required('Engine Hours is required')
        .matches(/^[\d\s]+(\s*\.\s*[\d\s]+)?$/, 'Engine Hours must contain only numbers'),
    }),
    driverSignature: yup.string().when('status', {
      is: 'certify',
      then: yup.string().required('Driver Signature is required'),
    }),
    certifyDate: yup.mixed().when('status', {
      is: 'certify',
      then: yup.mixed().required('Certify Date is required'),
    }),
  });
  const getLocationFunction = (latProp: string, longProp: string, formProps: FormikHelpers<FormValues>) => {
    const lat = latProp.replace(/\s/g, '');
    const long = longProp.replace(/\s/g, '');
    getLocation
      .mutateAsync({ lat, long })
      .then(response => {
        formProps.setFieldValue('location', response.data.address);
        formProps.setFieldValue('state', response.data.state);
      })
      .catch(error => {
        notification.error({ message: error?.message });
      });
  };
  const getCoordinatesFunction = (location: string, formProps: FormikHelpers<FormValues>) => {
    getCoordinates
      .mutateAsync({ location })
      .then(response => {
        if (response.data.lat && response.data.lng) {
          formProps.setFieldValue('lat', `${response.data.lat}`);
          formProps.setFieldValue('lng', `${response.data.lng}`);
        } else {
          formProps.setFieldError('location', response.message);
        }
      })
      .catch(error => {
        notification.error({ message: error?.message });
      });
  };

  const handleSubmit = (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    if (moment(values.time).format('HHmmss') === '000000') {
      notification.error({ message: 'Time should not be 12:00:00 AM' });
      formikHelpers.setSubmitting(false);
      return;
    }

    // const dateTimeStr1 = moment().tz(tzCode).format('MMDDYY HHmmss');
    // const dateTimeStr2 = moment(values.time).format('MMDDYY HHmmss');
    // const date1 = new Date(
    //   `20${dateTimeStr1.slice(4, 6)}-${dateTimeStr1.slice(2, 4)}-${dateTimeStr1.slice(0, 2)}T${dateTimeStr1.slice(
    //     7,
    //     9,
    //   )}:${dateTimeStr1.slice(9, 11)}:${dateTimeStr1.slice(11, 13)}`,
    // );

    // const date2 = new Date(
    //   `20${dateTimeStr2.slice(4, 6)}-${dateTimeStr2.slice(2, 4)}-${dateTimeStr2.slice(0, 2)}T${dateTimeStr2.slice(
    //     7,
    //     9,
    //   )}:${dateTimeStr2.slice(9, 11)}:${dateTimeStr2.slice(11, 13)}`,
    // );

    // if (date1 < date2 && moment(date).format('MMDDYY') === moment().tz(tzCode).format('MMDDYY')) {
    //   notification.error({ message: 'Time should not be greater from driver timezone' });
    //   formikHelpers.setSubmitting(false);
    //   return;
    // }

    formikHelpers.validateForm();

    let statusCode = '';
    let statusType = '';
    if (values.status === 'intmediateDriving') {
      statusCode = '1';
      statusType = '2';
    } else if (values.status === 'intmediatePersonal') {
      statusCode = '2';
      statusType = '2';
    } else if (values.status === 'powerupDriving') {
      statusCode = '1';
      statusType = '6';
    } else if (values.status === 'powerupPersonal') {
      statusCode = '2';
      statusType = '6';
    } else if (values.status === 'powerdownDriving') {
      statusCode = '3';
      statusType = '6';
    } else if (values.status === 'powerdownPersonal') {
      statusCode = '4';
      statusType = '6';
    } else if (values.status === 'certify') {
      statusCode = '1';
      statusType = '4';
    } else if (values.status === 'login') {
      statusCode = '1';
      statusType = '5';
    } else if (values.status === 'logout') {
      statusCode = '2';
      statusType = '5';
    }

    const finalData: InsertInfoLogRequest = {
      driverId,
      // eslint-disable-next-line object-shorthand
      date: date,
      time: moment(values.time).format('HHmmss'),
      type: selectedInfoLogs ? '2' : '1',
      sqID: selectedInfoLogs ? selectedInfoLogs!.eventSequenceIdNumber : '',
      signature: values.driverSignature,
      statusInfo: {
        status: values.status,
        eventType: statusType,
        eventCode: statusCode,
        lat: values.lat!.replace(/\s/g, ''),
        long: values.lng!.replace(/\s/g, ''),
        address: values?.location,
        odometer: values?.odometer.replace(/\s/g, ''),
        engineHour: values?.engHours.replace(/\s/g, ''),
        truck: '',
        shippingDocument: values?.shippingDocument,
        tralier: values?.trailer,
        notes: values?.notes,
        state: values?.state,
      },
    };

    saveInsertInfo
      .mutateAsync(finalData)
      .then(response => {
        notification.success({ message: response?.message });
        queryClient.refetchQueries([GET_SINGLE_UNIT_BY_DRIVERID]);
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        onCancel(false);
      })
      .catch(error => {
        notification.error({ message: error?.message });
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
        queryClient.refetchQueries([GET_SINGLE_UNIT_BY_DRIVERID]);
        queryClient.refetchQueries([GET_HOS_DRIVER_CSV]);
        setShowBulkButton!(false);
        setSelectedRowKeys!([]);
        setSelectedLogs!([]);
      });
  };

  return (
    <Modal
      className="app-modal info-log-modal"
      open={openModal}
      title={`${selectedInfoLogs ? 'Edit' : 'Insert'} Info Log`}
      width={986}
      style={{ minHeight: '100px' }}
      onCancel={() => onCancel(false)}
      footer={null}>
      <>
        <Formik
          enableReinitialize
          initialValues={formInitialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}>
          {formProps => (
            <Form size="large" layout="vertical" initialValues={formInitialValues}>
              <>
                <div className="insert-info-card-large">
                  <p className="justify-text">
                    LOGELD, as your service provider, is not responsible for any financial or legal repercussions
                    resulting from facilitating your request. It is the sole responsibility of the user to maintain
                    legal compliance while using ELD.
                  </p>
                  <div className="create-form">
                    <Row gutter={128}>
                      <Col span={24}>
                        <Row gutter={12} style={{ height: '100px' }}>
                          <Col span={12}>
                            <FormItem label="Time" name="time" className="form-label" required>
                              <TimePicker
                                disabled={false}
                                onChange={(time: moment.Moment) => formProps.setFieldValue('time', time)}
                                value={formProps.values.time}
                                tzCode={tzCode}
                                min="000000"
                                max="235959"
                              />
                            </FormItem>
                          </Col>
                          <Col span={12}>
                            <FormItem label="Status" name="status" className="form-label" required>
                              <svgIcons.LocationIcon width={20} height={20} className="selectIcons" />
                              <div className="iconBorder" />
                              <Select
                                name="status"
                                key="status"
                                placeholder="Select status"
                                defaultValue={selectedInfoLogs ? statusSelected : 'Select status'}
                                // eslint-disable-next-line no-unneeded-ternary
                                disabled={selectedInfoLogs ? true : false}
                                showSearch>
                                <Option value="certify">Certify</Option>
                                <Option value="intmediateDriving">Intermediate (driving)</Option>
                                <Option value="intmediatePersonal">Intermediate (personal)</Option>
                                <Option value="powerupDriving">Power Up (driving)</Option>
                                <Option value="powerupPersonal">Power Up (personal) </Option>
                                <Option value="powerdownDriving">Power Down (driving)</Option>
                                <Option value="powerdownPersonal">Power Down (personal)</Option>
                                <Option value="login">Login </Option>
                                <Option value="logout">Logout </Option>
                              </Select>
                            </FormItem>
                          </Col>
                        </Row>
                        {formProps.values.status !== 'certify' && (
                          <>
                            <Row
                              gutter={12}
                              style={{ display: 'flex', height: '100px' }}
                              className="adjust-lat-lng-location">
                              <Col span={10}>
                                <FormItem label="Lat" name="lat" className="form-label" required>
                                  <Input
                                    name="lat"
                                    prefix={<ImLocation size={20} />}
                                    type="text"
                                    // maxLength={20}
                                    placeholder="Enter lat"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={10}>
                                <FormItem label="Lng" name="lng" className="form-label" required>
                                  <svgIcons.LocationIcon width={20} height={20} className="selectIcons" />
                                  <Input
                                    name="lng"
                                    prefix={<ImLocation size={20} />}
                                    type="text"
                                    // maxLength={20}
                                    placeholder="Enter lng"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={4} className="lat-lng-location-button">
                                <Button
                                  className="tertiary-btn-sm generateLatLngLocation"
                                  htmlType="button"
                                  onClick={() => {
                                    if (
                                      !formProps.errors.lat &&
                                      !formProps.errors.lng &&
                                      (formProps.values.lat !== '' || formProps.values.lng !== '')
                                    ) {
                                      getLocationFunction(formProps.values.lat!, formProps.values.lng!, formProps);
                                    }
                                  }}>
                                  Get Location
                                </Button>
                              </Col>
                            </Row>
                            <Row gutter={12} className="adjust-lat-lng-location" style={{ height: '100px' }}>
                              <Col span={18}>
                                <FormItem label="Location" name="location" className="form-label" required>
                                  <Input
                                    name="location"
                                    prefix={<svgIcons.LocationIcon width={20} height={20} />}
                                    type="text"
                                    placeholder="Enter location"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={6} className="lat-lng-location-button">
                                <Button
                                  className="tertiary-btn-sm generateLatLngLocation"
                                  htmlType="button"
                                  onClick={() => {
                                    if (!formProps.errors.location && formProps.values.location !== '') {
                                      getCoordinatesFunction(formProps.values.location!, formProps);
                                    }
                                  }}>
                                  Get Coordinates
                                </Button>
                              </Col>
                            </Row>
                            <Row gutter={12} style={{ height: '100px' }}>
                              <Col span={12}>
                                <FormItem label="Odometer" name="odometer" className="form-label" required>
                                  <Input
                                    name="odometer"
                                    prefix={<BsSpeedometer size={20} />}
                                    type="text"
                                    maxLength={20}
                                    placeholder="Enter odometer"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem label="Eng. Hours" name="engHours" className="form-label" required>
                                  <Input
                                    name="engHours"
                                    prefix={<BsSpeedometer2 size={20} />}
                                    type="text"
                                    maxLength={20}
                                    placeholder="Enter engine hours"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                            </Row>
                            {/* <Row gutter={12} style={{ height: '100px' }}>
                              <Col span={24}>
                                <FormItem label="Shipping Document" name="shippingDocument" className="form-label">
                                  <Input
                                    name="shippingDocument"
                                    prefix={<svgIcons.LocationIcon width={20} height={20} />}
                                    type="text"
                                    maxLength={20}
                                    placeholder="Enter shipping document"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                              <Col span={12}>
                                <FormItem label="Trailer" name="trailer" className="form-label">
                                  <Input
                                    name="trailer"
                                    prefix={<BsCardHeading size={20} />}
                                    type="text"
                                    maxLength={20}
                                    placeholder="Enter trailer"
                                    className="form-control"
                                  />
                                </FormItem>
                              </Col>
                            </Row> */}
                            <FormItem label="Notes" name="notes" className="form-label">
                              <Input.TextArea name="notes" rows={3} maxLength={300} className="form-control" />
                            </FormItem>
                          </>
                        )}
                        {formProps.values.status === 'certify' && singleUnitDetails?.imageName ? (
                          <Row gutter={12}>
                            <Col span={12}>
                              <FormItem label="Driver Signature" name="driverSignature" className="form-label" required>
                                <Input
                                  name="driverSignature"
                                  prefix={<FaFileSignature size={20} />}
                                  type="text"
                                  maxLength={20}
                                  placeholder="Enter driver signature"
                                  className="form-control"
                                  disabled
                                />
                              </FormItem>
                            </Col>
                            <Col span={12}>
                              <FormItem label="Certify Date" name="certifyDate" className="form-label" required>
                                <BsFillClockFill size={20} className="selectIcons" />
                                <div className="iconBorder" />
                                <Space direction="vertical" className="SpaceTimePicker">
                                  <DatePicker
                                    name="certifyDate"
                                    className="timePicker"
                                    // eslint-disable-next-line no-unneeded-ternary
                                    disabled={selectedInfoLogs ? true : false}
                                    suffixIcon={<AiOutlineDown size={14} />}
                                  />
                                </Space>
                              </FormItem>
                            </Col>
                          </Row>
                        ) : (
                          [
                            formProps.values.status === 'certify' && !singleUnitDetails?.imageName ? (
                              <p className="modal-body-title" style={{ color: 'red' }}>
                                There is no signature available against this driver please go back to mobile and add
                                signature.
                              </p>
                            ) : null,
                          ]
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <div className="align-submit-buttons">
                          <Button style={{ marginRight: '5px' }} onClick={() => onCancel(false)}>
                            cancel
                          </Button>
                          <SubmitButton htmlType="submit">Save</SubmitButton>
                        </div>
                      </Col>
                    </Row>
                    {/* <pre
                        style={{
                          background: '#f6f8fa',
                          fontSize: '.65rem',
                          padding: '.5rem',
                        }}>
                        <FormikDebug />
                      </pre> */}
                  </div>
                </div>
              </>
            </Form>
          )}
        </Formik>
      </>
    </Modal>
  );
};

export default LogInfoModal;
