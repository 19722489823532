import { Modal } from 'antd';

import 'styles/modal.less';

type Props = {
  title: string;
  body: () => JSX.Element;
  onSubmit: () => void;
  width?: number;
  okText?: string;
  okButtonProps?: {};
};

const InfoModal = ({
  title,
  body,
  onSubmit,
  width = 520,
  okText,
  okButtonProps,
}: Props) => (
  <Modal
    className="app-modal"
    title={title}
    visible
    closable={false}
    onOk={() => onSubmit()}
    okText={okText}
    cancelButtonProps={{ style: { display: 'none' } }}
    okButtonProps={okButtonProps}
    centered
    width={width}>
    {body()}
  </Modal>
);

export default InfoModal;
