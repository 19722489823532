/* eslint-disable @typescript-eslint/no-explicit-any */
import { STATUSE_TYPE_INTO_ENEVT_CODE } from 'constants/config';
import { Dropdown, Menu, Tag, Tooltip, message } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { CSVGraphStatusType } from 'types/hos';
import 'moment-duration-format';
import moment from 'moment';
import { svgIcons } from 'resources/icons';
import { CopyOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';

const LogsEventTableColumns = ({
  vehicleId,
  openUpdateModal,
  setShowDeleteLogModal,
  setShowEditLogInfoModal,
  openInfoModal,
  setSelectedLog,
  setSelectedRange,
  setActionType,
  enableELog,
  isEditRequested,
  drivingStatuses,
  stationaryStatus,
}: {
  vehicleId: string;
  openUpdateModal: (sequenceId: string) => void;
  setShowDeleteLogModal: React.Dispatch<React.SetStateAction<boolean>>;
  setShowEditLogInfoModal: React.Dispatch<React.SetStateAction<boolean>>;
  openInfoModal: (record: CSVGraphStatusType) => void;
  setSelectedLog?: React.Dispatch<React.SetStateAction<CSVGraphStatusType | null | undefined>>;
  setSelectedRange: React.Dispatch<React.SetStateAction<object>>;
  setActionType: React.Dispatch<React.SetStateAction<number>>;
  enableELog: boolean;
  isEditRequested?: boolean;
  drivingStatuses?: any;
  stationaryStatus?: CSVGraphStatusType[];
}): ColumnsType<CSVGraphStatusType> => [
    {
      title: '#',
      width: '4%',
      render: (text, record, index) => {
        /* eslint-disable no-plusplus */
        let indexNumber = index;
        return ++indexNumber;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status, record) => {
        let statusColor = '';
        let tagName = '';
        let tagStatus = '';
        if (record.eventType === '1') {
          if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.OFF_DUTY) {
            statusColor = '#586973';
            tagName = 'OFF';
            tagStatus = 'Off Duty';
          } else if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DUTY) {
            statusColor = '#2b5f8c';
            tagName = 'ON';
            tagStatus = 'On Duty';
          } else if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.ON_DRIVING) {
            statusColor = '#52C41A';
            tagName = 'DR';
            tagStatus = 'Driving';
          } else if (record?.eventCode === STATUSE_TYPE_INTO_ENEVT_CODE.SLEEPER_BERTH) {
            statusColor = '#FF9933';
            tagName = 'SB';
            tagStatus = 'Sleeper Berth';
          } else {
            tagStatus = 'Off Duty';
          }
        } else if (record?.eventType === '2') {
          tagStatus = 'Int. Location';
        } else if (record?.eventType === '3') {
          if (record?.eventCode === '1') {
            statusColor = '#858e9ef3';
            tagName = 'PC';
            tagStatus = 'Off (PC)';
          } else if (record?.eventCode === '2') {
            statusColor = '#1ca1bff3';
            tagName = 'YM';
            tagStatus = 'On (YM)';
          }
        } else if (record?.eventType === '6') {
          if (record?.eventCode === '1' || record?.eventCode === '2') {
            tagStatus = 'Power Up';
          } else if (record?.eventCode === '3' || record?.eventCode === '4') {
            tagStatus = 'Power Down';
          }
        } else if (record?.eventTypeExtra === '5') {
          if (record?.eventCode === '1') {
            tagStatus = 'Log In';
          } else if (record?.eventCode === '2') {
            tagStatus = 'Log Out';
          }
        } else if (record?.certificateType === '4') {
          // if (record?.eventCode === '1') {
          tagStatus = 'Certify';
          // }
        }
        return (
          <>
            {record?.eventType === '1' || record?.eventType === '3' ? (
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ flexDirection: 'column' }}>
                  <Tag color={statusColor} key={status} className="statusTag">
                    <>{tagName}</>
                  </Tag>
                  <span style={{ fontSize: '12px', display: 'block' }} className="statusTagText">
                    <>{tagStatus} </>
                  </span>
                </span>
                {(() => {
                  const messages = [];
                  if (Number(record?.speed) > 75) {
                    messages.push('Speed limit exceed');
                  }
                  if (Number(record?.speed) < 0) {
                    messages.push('Speed Cannot Be Less Than Zero');
                  }
                  return messages.length > 0 ? (
                    <span
                      style={{
                        color: '#f2163e',
                        // position: 'absolute',
                        // top: '23px',
                        // left: '75px',
                        marginLeft: '2px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      className="InspectionTooltip">
                      {record?.speed && (
                        <>
                          <span
                            style={{
                              color: record && (Number(record.speed) > 75 || Number(record.speed) < 0) ? 'red' : '',
                              fontSize: '16px',
                            }}>
                            {record?.speed.split('.')[0]}
                          </span>
                        </>
                      )}
                      <svgIcons.errorIcon width={13} height={13} style={{ marginLeft: '1px' }} className="errorIcon" />
                      <span
                        className="tooltiptext custom-position"
                        style={{ textAlign: 'start' }}
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: messages.join('<br/>') }}
                      />
                    </span>
                  ) : (
                    <>
                      {record?.speed && (
                        <>
                          <span
                            style={{
                              marginLeft: '2px',
                              fontSize: '16px',
                            }}>
                            {record?.speed.split('.')[0]}
                          </span>
                        </>
                      )}
                    </>
                  );
                })()}
              </span>
            ) : (
              <>
                <span
                  style={{ fontSize: '12px', display: 'flex', alignItems: 'center', color: record?.colored ? 'red' : '' }}
                  className={`${record?.colored ? 'InspectionTooltip' : 'statusTagText'}`}>
                  {tagStatus}

                  {/* Comment For now as Poer Up missing red show not need for now
                {record?.colored && (
                  <span className="tooltiptext custom-position">{`${
                    record?.eventCode === '1' || record?.eventCode === '2'
                      ? 'Power Down is Missing'
                      : 'Power Up is Missing'
                  }`}</span>
                )} */}

                  {/* {record?.violation && <svgIcons.errorIcon width={13} height={13} style={{marginLeft:"10px"}} className="errorIcon" />} */}
                  {(() => {
                    const messages = [];

                    if (Number(record?.speed) > 75) {
                      messages.push('Speed limit exceed');
                    }

                    if (Number(record?.speed) < 0) {
                      messages.push('Speed Cannot Be Less Than Zero');
                    }

                    if (
                      record?.eventType === '2' &&
                      stationaryStatus?.some(
                        (item: any) =>
                          moment(item?.eventTime, 'HHmmss').isBefore(moment(record?.eventTime, 'HHmmss')) &&
                          moment(record?.eventTime, 'HHmmss').isBefore(moment(item?.eventEndTime, 'HHmmss')),
                      )
                    ) {
                      messages.push('Int. location while not driving');
                    }
                    const certifyViolation =
                      record?.certificateType === '4' &&
                      drivingStatuses?.some(
                        (item: any) =>
                          moment(item?.eventTime, 'HHmmss').isBefore(moment(record?.eventTime, 'HHmmss')) &&
                          moment(record?.eventTime, 'HHmmss').isBefore(moment(item?.eventEndTime, 'HHmmss')),
                      );
                    if (certifyViolation) {
                      messages.push('Certify Entry on While Driving');
                    }

                    return messages.length > 0 ? (
                      <span
                        style={{
                          color: '#f2163e',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '16px',
                        }}
                        className="InspectionTooltip">
                        {record?.speed && (
                          <>
                            <span
                              style={{
                                marginLeft: '2px',
                                fontSize: '16px !important',
                                color: record && (Number(record.speed) > 75 || Number(record.speed) < 0) ? 'red' : '',
                              }}>
                              {record?.speed.split('.')[0]}
                            </span>
                          </>
                        )}
                        <svgIcons.errorIcon width={13} height={13} style={{ marginLeft: '1px' }} className="errorIcon" />
                        <span
                          className="tooltiptext custom-position"
                          style={{ textAlign: 'start' }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: messages.join('<br/>') }}
                        />
                      </span>
                    ) : (
                      <>
                        {record?.speed && (
                          <>
                            <span
                              style={{
                                marginLeft: '2px',
                                fontSize: '16px',
                              }}
                            >
                              {record?.speed.split('.')[0]}
                            </span>
                          </>
                        )}
                      </>
                    );
                  })()}
                </span>

              </>
            )}
          </>
        );
      },
    },
    {
      title: 'Start Time (CST)',
      dataIndex: 'eventTime',
      key: 'eventTime',
      width: '12%',
      render(text, record) {
        return moment(record?.eventTime, 'HH:mm:ss A').format('hh:mm:ss A');
      },
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      width: '15%',
    },
    {
      title: 'Location',
      dataIndex: 'address',
      key: 'address',
      width: '20%',
      render(text, record) {
        return (
          <>
            {record?.address}
            {
              text && (
                <CopyToClipboard
                  text={`${record?.eventLatitude || record?.loginLatitude}, ${record?.eventLongitude || record?.loginLongitude}`}
                  onCopy={() => message.success('Copied')}>
                  <CopyOutlined className="copy-clipboard" />
                </CopyToClipboard>
              )
            }
          </>
        )
      },

    },
    {
      title: 'Vehicle ID',
      dataIndex: 'vehicleManualId',
      key: 'vehicleManualId',
      width: '10%',
      render() {
        return <>{vehicleId}</>;
      },
    },
    {
      title: 'Odometer',
      dataIndex: ' totalVehicleMilesDutyStatus',
      key: ' totalVehicleMilesDutyStatus',
      width: '10%',
      render(text, record) {
        if (record?.isOdometer && record?.isOdometer === '1') {
          return (
            <span style={{ color: '#f2163e' }} className="InspectionTooltip">
              {record?.totalVehicleMilesDutyStatus}
              <span className="tooltiptext custom-position">Odometer change while not driving</span>
            </span>
          );
        }
        if (record?.isOdometer && record?.isOdometer === '2') {
          return (
            <span style={{ color: '#f2163e' }} className="InspectionTooltip">
              {record?.totalVehicleMilesDutyStatus}
              <span className="tooltiptext custom-position">Odometer change incorrect</span>
            </span>
          );
        }
        return <>{record?.totalVehicleMilesDutyStatus}</>;
      },
    },
    {
      title: 'Eng. Hours',
      dataIndex: 'totalEngineHoursDutyStatus',
      key: 'totalEngineHoursDutyStatus',
      width: '10%',
      render(text, record) {
        if (record?.isEngineHours && record?.isEngineHours === '1') {
          return (
            <span style={{ color: '#f2163e' }} className="InspectionTooltip">
              {record?.totalEngineHoursDutyStatus}
              <span className="tooltiptext custom-position">Engine-hours change incorrect</span>
            </span>
          );
        }
        if (record?.isEngineHours && record?.isEngineHours === '2') {
          return (
            <span style={{ color: '#f2163e' }} className="InspectionTooltip">
              {record?.totalEngineHoursDutyStatus}
              <span className="tooltiptext custom-position">Engine-hours none-change while power on</span>
            </span>
          );
        }
        return <>{record?.totalEngineHoursDutyStatus}</>;
      },
    },
    // {
    //   title: 'Origin',
    //   dataIndex: 'eventRecordOrigin',
    //   key: 'eventRecordOrigin',
    //   width: '10%',
    //   render(value) {
    //     let text = 'Auto';
    //     if (value === EventRecordOrigin.ELLD) {
    //       text = 'Auto';
    //     } else if (value === EventRecordOrigin.DRIVER) {
    //       text = 'Driver';
    //     } else if (value === EventRecordOrigin.AUTHENTICATED_DRIVER) {
    //       text = 'Admin';
    //     } else if (value === EventRecordOrigin.UNIDENTIFIED_DRIVER) {
    //       text = 'Assumed from unidentified';
    //     }
    //     return <span>{text}</span>;
    //   },
    // },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      width: '15%',
      ellipsis: true,
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      width: '6%',
      render: (text: string, record: CSVGraphStatusType) =>
        record?.eventType === '1' || record?.eventType === '3'
          ? setSelectedLog &&
          enableELog && (
            <Dropdown
              overlay={
                <Menu key={`user-action-menu-${record?.eventDate}`} className="actionDropDown">
                  <Menu.Item key={`edit-user-${record?.eventDate}`}>
                    <div
                      className="action_DropDown"
                      onClick={() => {
                        if (setSelectedLog) {
                          const { duration, ...logEntry } = record;
                          setSelectedLog(logEntry);
                          setSelectedRange({ from: record?.eventTime, to: record?.eventEndTime });
                          setActionType(1);
                          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                        }
                      }}
                      aria-hidden="true">
                      <svgIcons.EditIcon className="dropDown_icon" />
                      <span className="dropDown_list_text">Edit Log</span>
                    </div>
                  </Menu.Item>
                </Menu>
              }>
              <div className="icon-set">
                <BiDotsVerticalRounded size={24} />
              </div>
            </Dropdown>
          )
          : enableELog && (
            <Dropdown
              overlay={
                <Menu key={`user-action-menu-${record?.eventDate}`} className="actionDropDown">
                  <>
                    <Menu.Item
                      key="edit-info-log"
                      className="action-menu"
                      onClick={() => {
                        setShowEditLogInfoModal(true);
                        openInfoModal(record);
                      }}>
                      <div className="action_DropDown">
                        <svgIcons.EditIcon className="dropDown_icon" />
                        <span className="dropDown_list_text">Edit info log</span>
                      </div>
                    </Menu.Item>
                    <Menu.Item
                      key=""
                      className="action-menu"
                      onClick={() => {
                        setShowDeleteLogModal(true);
                        openUpdateModal(record?.eventSequenceIdNumber);
                      }}>
                      <div className="action_DropDown">
                        <svgIcons.DeleteIcon className="dropDown_icon" />
                        <span className="dropDown_list_text">Delete Log</span>
                      </div>
                    </Menu.Item>
                  </>
                </Menu>
              }>
              <div className="icon-set">
                <BiDotsVerticalRounded size={24} />
              </div>
            </Dropdown>
          ),
    },
  ];

export default LogsEventTableColumns;
