import { Table, Row, Col, Spin } from 'antd';
import { InspectionType } from 'services/inspection';

import InspectionReportTableColumns from './InspectionReportTableColumn';
import './InspectionList.less';

export interface Filter {
  id: string;
  driverId: string;
  date: string;
}

const InspectionListing = ({
  data,
  driverName,
  isInspectionLoading,
}: {
  data: InspectionType[] | [];
  driverName: string;
  isInspectionLoading: boolean;
}) => {
  let inspectId = '';
  let driverId = '';
  let inspectionData = 0;
  /* eslint-disable */
  data.map(item => {
    inspectId = item.id;
    driverId = item.driverId;
    inspectionData = item.inspectionTime;
  });

  return (
    <Spin spinning={isInspectionLoading}>
      <div className="table-container LogsEventListing">
        <Row>
          <Col span={24}>
            <div>
              <Table
                columns={InspectionReportTableColumns({
                  driverName,
                })}
                pagination={false}
                dataSource={data || []}
                size="small"
                rowKey={(record) => record.id!}
                showSorterTooltip={false}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Spin>
  );
};

export default InspectionListing;
