import { trailerNumberRegex } from 'constants/regex';
import * as yup from 'yup';

export const logFormValidation = yup.object().shape({
  vehicleId: yup.string().nullable(false).required('You are required to fill Vehicle Id'),
  distance: yup.number().nullable(false).required('You are required to fill Distance'),
  driverName: yup.string().nullable(false).required('You are required to fill Driver Name'),
  manualDriverId: yup.string().nullable(false).required('You are required to fill Driver Id'),
  headOffice: yup.string().nullable(false).required('You are required to fill Office Address'),
  homeTerminalAddress: yup.string().nullable(false).required('You are required to select Home Terminal Address'),
  shippingDocumentField: yup
    .string()
    .trim()
    .max(40, 'maximum 40 characters')
    // .matches(/^[A-Z0-9]{1,10}$/, 'Please enter only capital letters (A-Z) and numeric digits (0-9).')
    .test('unique', 'shipping ID already exists', function (value) {
      const shippingDocument = this.parent.shippingDocument || [];
      return !shippingDocument.includes(value);
    }),
  trailerNumberField: yup
    .string()
    .trim()
    .max(10, 'maximum 10 characters')
    // .matches(/^[A-Z0-9]{1,10}$/, 'Please enter only capital letters (A-Z) and numeric digits (0-9).')
    .test('unique', 'Trailer number already exists', function (value) {
      const trailers = this.parent.trailerNumber || [];
      return !trailers.includes(value);
    }),
});
