import { emailRegEx } from 'constants/regex';
import * as yup from 'yup';

export const sendReportByEmail = yup.object().shape({
  email: yup
    .string()
    .nullable(false)
    .required('You are required to give Email')
    .matches(emailRegEx, 'Email is not valid'),
});
