import { USE_QUERY_OPTIONS } from 'constants/config';
import { useMutation, useQuery } from 'react-query';

import driverapi, { GetDriverRequest, GetDriverRequestForCSV } from 'services/drivers';
import { GET_DRIVER } from './keys';

export const useGetDrivers = (params: GetDriverRequest) =>
  useQuery([GET_DRIVER, params], () => driverapi.getDrivers(params));

export const useGetDriversForCSV = (params: GetDriverRequestForCSV) =>
  useQuery([GET_DRIVER, params], () => driverapi.getDriversForCSV(params));

export const useGetDriver = (driverId: string) =>
  useQuery(['getDriver', driverId], () => driverapi.getDriver(driverId), {
    ...USE_QUERY_OPTIONS,
    select: ({ data }) => data,
    refetchOnMount: 'always',
  });

export const useSaveDriver = () => useMutation(driverapi.saveDriver, { mutationKey: 'saveDriver' });

export const useUpdateDriverStatus = () =>
  useMutation(driverapi.updateDriverStatus, { mutationKey: 'deactivateDriver' });

export const useGetCoDrivers = () => useQuery([GET_DRIVER], () => driverapi.getCoDrivers());
