import Datetime from 'react-datetime';
import { HOURS_MINUTES_SECONDS_A } from 'constants/dates';
import { Input } from 'antd';
import { BsFillClockFill } from 'react-icons/bs';
import React, { useEffect, useRef } from 'react';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import './timepicker.less';

type TimePickerInput = {
  type: string;
  className: string;
  value: string;
  disabled: boolean;
};
type openCalendarType = () => void;
type refState = {
  state: {
    open: boolean;
    currentView: string;
    viewDate: moment.Moment | string;
    selectedDate: moment.Moment | string;
    inputValue: string;
  };
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function TimePicker({ ...props }) {
  const inputProps = {
    disabled: props.disabled || false,
    onChange: (e: any) => {
      if (e && typeof e.target.value === 'string') {
        const trimmedValue = e.target.value.trim();
        /* eslint-disable no-param-reassign */
        e.target.value = trimmedValue;
      }
    },
  };
  const [state, setState] = React.useState<any>();
  const ref = useRef(null);
  useEffect(() => {
    if (ref.current) {
      const currentState: refState = ref.current;
      let selectedDate: moment.Moment | null = null;

      if (props.value) {
        selectedDate = props.value;
      } else if (props.tzCode) {
        selectedDate = moment().tz(props.tzCode);
        props.onChange(selectedDate);
      }

      if (selectedDate !== null) {
        currentState.state.selectedDate = selectedDate;
        currentState.state.viewDate = selectedDate;

        setState((prev: any) => ({ ...prev }));
      }
    }
  }, [props.value, props.tzCode]);

  const renderInput: any = (dateTimeProps: TimePickerInput, openCalendar: openCalendarType) => (
    <div>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Input
        {...dateTimeProps}
        onClick={openCalendar}
        type="text"
        className="timepicker"
        prefix={
          <>
            <BsFillClockFill size={20} className="selectIcons" />
            <div className="iconBorder" />
          </>
        }
      />
    </div>
  );

  return (
    <Datetime
      ref={ref}
      renderInput={renderInput}
      dateFormat={false}
      timeFormat={HOURS_MINUTES_SECONDS_A}
      onChange={e => {
        if (typeof e !== 'string') {
          if (e.format('HHmmss') >= props.min && e.format('HHmmss') <= props.max) {
            props.onChange(e);
          } else {
            props.onChange(props.value);
          }
        }
      }}
      onClose={e => {
        if (typeof e === 'string') {
          if (props.tzCode) {
            props.onChange(moment().tz(props.tzCode));
          } else {
            props.onChange(props.value);
          }
        }
      }}
      inputProps={inputProps}
    />
  );
}
