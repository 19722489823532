import { USE_QUERY_OPTIONS } from 'constants/config';
import { useMutation, useQuery } from 'react-query';
import Inspectionapi, { GetInspectionRequest, GetReportRequest, GetAllCSVRequest } from 'services/inspection';
import { UncertifiedDatesRequestType } from 'types/hos';
import { GET_ALLUNCERTIFY_DATES, GET_ALL_CSV_REPORT, GET_INSPECTION, GET_INSPECTION_REPORT } from './keys';

export const useGetInspection = (params: GetInspectionRequest) =>
  useQuery([GET_INSPECTION, params], () => Inspectionapi.getInspection(params));

export const useGetInspectionReport = (params: GetReportRequest) =>
  useQuery([GET_INSPECTION_REPORT, params], () => Inspectionapi.getReport(params), { ...USE_QUERY_OPTIONS });

export const useSendEmailInspectionReport = () =>
  useMutation(Inspectionapi.getEmail, { mutationKey: 'SendEmailInspectionReport' });

export const useSaveCSVReport = () => useMutation(Inspectionapi.getCSVReport, { mutationKey: 'saveCSVReport' });

export const useGetAllCSVReport = (params: GetAllCSVRequest) =>
  useQuery([GET_ALL_CSV_REPORT, params], () => Inspectionapi.getAllCSVReports(params), { ...USE_QUERY_OPTIONS });

  export const useGetAllUncertifyDates = (params: UncertifiedDatesRequestType) =>
  useQuery([GET_ALLUNCERTIFY_DATES, params], () => Inspectionapi.getAllUncertifyDates(params), { ...USE_QUERY_OPTIONS });

  export const useCertificationDates = () =>
  useMutation(Inspectionapi.saveCertifySignature, { mutationKey: 'saveCertifySignature' });
