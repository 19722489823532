import { DEFAULT_EVENT_MMDDYY } from 'constants/dates';
import { Table, Row, Col } from 'antd';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { DriverResponseType, TimeZone } from 'services/drivers';
import { CSVGraphStatusType } from 'types/hos';
import { convertDateDriverHomeTimeZone, getStatusEventCode } from 'helpers/utils';
import LogsEventTableColumns from './LogsEventTableColumns';

import './LogsEventListing.less';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const LogsEventListing = ({
  logDataGraph,
  date,
  driverDetail,
}: {
  logDataGraph: any;
  date: string;
  driverDetail: DriverResponseType | undefined;
}) => {
  const logStorageData = JSON.parse(sessionStorage.getItem(`selectdLogDatail`) || '');
  const convertedDate = moment(date, 'YYYY-MM-DD').format(DEFAULT_EVENT_MMDDYY);

  const logOffDutyEvents: CSVGraphStatusType[] = logDataGraph?.csv?.eldEventListForDriversRecordOfDutyStatus
    ?.filter(
      (item: CSVGraphStatusType) =>
        (item?.eventType === '1' || item?.eventType === '3') &&
        item?.eventRecordStatus === '1' &&
        item?.eventDate === convertedDate,
    )
    ?.sort((a: CSVGraphStatusType, b: CSVGraphStatusType) => Number(a.eventTime) - Number(b.eventTime)) || [
    {
      eventSequenceIdNumber: '1',
      eventRecordStatus: '1',
      eventRecordOrigin: '1',
      eventType: '1',
      eventCode: getStatusEventCode(logStorageData?.lastActivityStatus, logStorageData?.currentDate, convertedDate),
      eventDate: convertedDate,
      eventTime: '000000',
      accumulatedVehicleMiles: 0,
      accumulatedEngineHours: 0,
      eventLatitude: 0,
      eventLongitude: 0,
      distanceSinceLastValidCoordinates: 0,
      correspondingCmvOrderNumber: '1',
      userOrderNumberForRecordOriginator: '1',
      malfunctionIndicatorStatusForEld: '0',
      dataDiagnosticEventIndicatorForDriver: '1',
      eventDataCheckValue: 'F2',
      lineDataCheckValue: '67',
      address: logStorageData?.meta?.lastActivity?.address || '',
    },
  ];

  const intLocationEvents: CSVGraphStatusType[] =
    logDataGraph?.csv?.eldEventListForDriversRecordOfDutyStatus?.filter(
      (item: CSVGraphStatusType) => item?.eventType === '2',
    ) || [];

  const updatedArray: CSVGraphStatusType[] = [];

  if (!isEmpty(logOffDutyEvents)) {
    const { tzCode } = (driverDetail?.homeTerminalTimeZone as TimeZone) ?? { tzCode: logStorageData?.driverTimeZone };
    const currentDateInZone = convertDateDriverHomeTimeZone(new Date(), tzCode);
    const currentDate = currentDateInZone.format(DEFAULT_EVENT_MMDDYY);

    for (let i = 0; i < logOffDutyEvents.length; i += 1) {
      const start = moment(logOffDutyEvents[i]?.eventTime, 'HH:mm:ss');
      const end = moment(
        logOffDutyEvents[i + 1]?.eventTime
          ? logOffDutyEvents[i + 1]?.eventTime
          : [convertedDate === currentDate ? currentDateInZone.format('HHmmss') : '235959'],
        'HH:mm:ss',
      );

      const showData = moment.utc(end.diff(start)).format('HH:mm:ss');
      const finalTime = showData.split(':');
      const duration = `${finalTime[0] !== '00' ? `${finalTime[0]} hrs` : ''} ${
        finalTime[1] !== '00' ? `${finalTime[1]} min` : ''
      } ${finalTime[2] !== '00' ? `${finalTime[2]} sec` : ''}`;
      updatedArray?.push({ ...logOffDutyEvents[i], duration });
    }
  }

  const vehicleId = logDataGraph?.csv?.powerUnitLine?.powerUnitNumber || driverDetail?.vehicleId?.vehicleId || '';

  const logPowerUpDownEvents = logDataGraph?.csv?.cmvEnginePowerUpShutDownActivity || [];
  const powerUpDownEvents: CSVGraphStatusType[] = logPowerUpDownEvents?.map((item: CSVGraphStatusType) => ({
    ...item,
    eventType: '6',
  }));

  const mergedAllevents = [...updatedArray, ...intLocationEvents, ...powerUpDownEvents];
  return (
    <div className="table-container LogsEventListing logHistory">
      <Row>
        <Col span={24}>
          <div>
            <Table
              columns={LogsEventTableColumns(vehicleId)}
              pagination={false}
              dataSource={mergedAllevents?.sort((a, b) => Number(a?.eventTime) - Number(b?.eventTime))}
              rowKey={record => record.eventSequenceIdNumber}
              scroll={{y: 350 }}
              size="small"
              showSorterTooltip={false}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default LogsEventListing;
