import { FormikHelpers, FormikProps } from 'formik';
import { Button, Col, Row } from 'antd';
import { BsFillLockFill, BsSpeedometer, BsSpeedometer2 } from 'react-icons/bs';
import { editLogFormType } from 'services/logs';
import moment, { Moment } from 'moment';
import { FormItem, Input, Radio, SubmitButton } from 'formik-antd';
import { svgIcons } from 'resources/icons';
import { TimePicker } from 'components/timepicker/TimePicker';

import './EditLogForm.less';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditLogForm = ({
  resetActionType,
  timeFrom,
  onChangeTimeFrom,
  timeTo,
  onChangeTimeTo,
  visibleTimeFrom,
  visibleTimeTo,
  getLocationFunction,
  getCoordinatesFunction,
  formProps,
  sliderChange,
  actionType,
  rightLimit,
}: {
  resetActionType?: () => void;
  timeFrom: moment.Moment | null;
  onChangeTimeFrom: (time: Moment | null) => void;
  timeTo: moment.Moment | null;
  onChangeTimeTo: (time: Moment | null) => void;
  visibleTimeFrom: boolean;
  visibleTimeTo: boolean;
  getLocationFunction: (lat: string, long: string, formProps: FormikHelpers<editLogFormType>) => void;
  getCoordinatesFunction: (location: string, formProps: FormikHelpers<editLogFormType>) => void;
  formProps: FormikProps<editLogFormType>;
  sliderChange: boolean;
  actionType: number;
  rightLimit: string;
}) => (
  <>
    <Row>
      <Col span={24}>
        <FormItem label="Duty Status" name="status" className="form-label" required>
          <Radio.Group buttonStyle="solid" className="dutyStatusList" name="status">
            <Radio.Button value="1" className="status offduty">
              <svgIcons.OffDutyIcon width={20} height={20} className="dutyStatusIcon" />
              <span className="statusText">off duty</span>
            </Radio.Button>
            <Radio.Button value="2" className="status sleeperberth">
              <svgIcons.SleeperBirthIcon width={20} height={20} className="dutyStatusIcon" />
              <span className="statusText">sleeper berth</span>
            </Radio.Button>
            <Radio.Button value="3" className="status driving">
              <svgIcons.DrivingIcon width={20} height={20} className="dutyStatusIcon" />
              <span className="statusText">driving</span>
            </Radio.Button>
            <Radio.Button value="4" className="status onduty">
              <svgIcons.VehicleIcon width={20} height={20} className="dutyStatusIcon" />
              <span className="statusText">on duty</span>
            </Radio.Button>
            <Radio.Button value="5" className="status offduty offdutyPC">
              <svgIcons.OffDutyIcon width={20} height={20} className="dutyStatusIcon" />
              <span className="statusText">off duty (PC)</span>
            </Radio.Button>
            <Radio.Button value="6" className="status ondutyYM">
              <svgIcons.VehicleIcon width={20} height={20} className="dutyStatusIcon" />
              <span className="statusText">on duty (YM)</span>
            </Radio.Button>
          </Radio.Group>
        </FormItem>
      </Col>
    </Row>
    <Row gutter={30}>
      <Col span={12}>
        <FormItem label="Time From" name="timeFrom" className="form-label" required>
          <TimePicker
            value={timeFrom!}
            onChange={onChangeTimeFrom}
            disabled={visibleTimeFrom}
            min="000000"
            max={timeTo?.format('HHmmss')}
          />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="Time To" name="timeTo" className="form-label" required>
          <TimePicker
            value={timeTo!}
            onChange={onChangeTimeTo}
            disabled={visibleTimeTo}
            min={timeFrom?.format('HHmmss')}
            max={rightLimit}
          />
        </FormItem>
      </Col>
    </Row>
    <Row gutter={12} style={{ display: 'flex', height: '100px' }} className="adjust-lat-lng-location">
      <Col span={10}>
        <FormItem label="Lat" name="lat" className="form-label" required>
          <Input
            name="lat"
            prefix={<BsFillLockFill size={20} />}
            type="text"
            // maxLength={20}
            placeholder="Enter lat"
            className="form-control"
          />
        </FormItem>
      </Col>
      <Col span={10}>
        <FormItem label="Lng" name="lng" className="form-label" required>
          <Input
            name="lng"
            prefix={<BsFillLockFill size={20} />}
            type="text"
            // maxLength={20}
            placeholder="Enter lng"
            className="form-control"
          />
        </FormItem>
      </Col>
      <Col span={4} className="lat-lng-location-button">
        <Button
          className="tertiary-btn-sm generateLatLngLocation"
          htmlType="button"
          onClick={() => {
            if (
              !formProps.errors.lat &&
              !formProps.errors.lng &&
              (formProps.values.lat !== '' || formProps.values.lng !== '')
            ) {
              getLocationFunction(formProps.values.lat!, formProps.values.lng!, formProps);
            }
          }}>
          Get Location
        </Button>
      </Col>
    </Row>

    <Row gutter={12}>
      <Col span={20}>
        <FormItem label="Location" name="location" className="form-label" required>
          <Input
            name="location"
            prefix={<svgIcons.LocationIcon width={20} height={20} />}
            type="text"
            maxLength={50}
            placeholder="Location"
            className="form-control"
          />
        </FormItem>
      </Col>
      <Col span={4} className="lat-lng-location-button">
        <Button
          className="tertiary-btn-sm generateLatLngLocation"
          htmlType="button"
          onClick={() => {
            if (!formProps.errors.location && formProps.values.location !== '') {
              getCoordinatesFunction(formProps.values.location!, formProps);
            }
          }}>
          Get Coordinates
        </Button>
      </Col>
    </Row>
    <Row gutter={12} style={{ height: '100px' }}>
      <Col span={12}>
        <FormItem label="Odometer" name="odometer" className="form-label" required>
          <Input
            name="odometer"
            prefix={<BsSpeedometer size={20} />}
            type="text"
            maxLength={20}
            placeholder="Enter odometer"
            className="form-control"
          />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="Eng. Hours" name="engHours" className="form-label" required>
          <Input
            name="engHours"
            prefix={<BsSpeedometer2 size={20} />}
            type="text"
            maxLength={20}
            placeholder="Enter engine hours"
            className="form-control"
          />
        </FormItem>
      </Col>
    </Row>
    {/* <Row gutter={12} style={{ height: '100px' }}>
      <Col span={12}>
        <FormItem label="Shipping Document" name="shippingDocument" className="form-label">
          <Input
            name="shippingDocument"
            prefix={<svgIcons.LocationIcon width={20} height={20} />}
            type="text"
            maxLength={20}
            placeholder="Enter shipping document"
            className="form-control"
          />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem label="Trailer" name="trailer" className="form-label">
          <Input
            name="trailer"
            prefix={<BsCardHeading size={20} />}
            type="text"
            maxLength={20}
            placeholder="Enter trailer"
            className="form-control"
          />
        </FormItem>
      </Col>
    </Row> */}
    <Row>
      <Col span={24}>
        <FormItem label="Notes" name="notes" className="form-label">
          <Input.TextArea name="notes" rows={2} maxLength={300} className="form-control" />
        </FormItem>
      </Col>
    </Row>
    <div className="hr-line-bottom" style={{ paddingTop: '50px' }} />
    <Row>
      <Col span={12} />
      <Col span={12}>
        <div className="submitSection align-submit-buttons">
          <Button className="tbl-top-btn canelBtn" onClick={resetActionType}>
            Cancel
          </Button>
          <SubmitButton
            htmlType="submit"
            className="tbl-top-btn confirm"
            disabled={!formProps.dirty && !sliderChange && actionType === 1}>
            CONFIRM
          </SubmitButton>
        </div>
      </Col>
    </Row>
  </>
);
export default EditLogForm;
