import { QueryClient } from 'react-query';

import { ONE_DAY } from '../constants/config';

import * as keys from './keys';
// import useToggle from './useToggle';
// import useRouter from './useRouter';
// import useDarkMode from './useDarkMode';
// import useLocalStorage from './useLocalStoreage';
// import useMedia from './useMedia';
// import useAsync from './useAsync';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // ✅ globally default to 1 day
      staleTime: ONE_DAY,
    },
  },
});

export {
  // useToggle, useRouter, useDarkMode, useLocalStorage, useMedia, useAsync,
  keys,
};
