import { Col, Row } from 'antd';
import { InspectionResponse, InspectionType } from 'services/inspection';
import InspectionListing from '../InspectionReport/InspectionList';

import '../../SingleDayLog.less';

const InspectionGrid = ({
  inspectionData,
  driverNameByParams,
  isInspectionLoading,
}: {
  inspectionData: InspectionResponse | undefined;
  driverNameByParams: string;
  isInspectionLoading: boolean;
}) => {
  const inspectionList: InspectionType[] = [];
  if (inspectionData && inspectionData?.data?.length > 0) {
    for (let i = 0; i < inspectionData?.data?.length; i += 1) {
      const element = inspectionData?.data[i];
      inspectionList?.push(element);
    }
  }
  return (
    <Row>
      <Col span={24}>
        <div className="generic-card-small LogEvents">
          <div className="DriverSignature">
            <p className="pg-heading">Inspection Reports</p>
            <InspectionListing
              data={inspectionList || []}
              driverName={driverNameByParams}
              isInspectionLoading={isInspectionLoading}
            />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default InspectionGrid;
