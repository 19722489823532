import { MM_DD_YYYY_HH_MM_SS_A } from 'constants/dates';
import moment from 'moment';
import { ColumnsType } from 'antd/lib/table';
import { BsEyeFill } from 'react-icons/bs';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const LogEditHistoryTableColumns = ({
  setShowEditHistoryDetailModal,
  setRecord,
}: {
  setShowEditHistoryDetailModal: React.Dispatch<React.SetStateAction<boolean>>;
  setRecord: React.Dispatch<React.SetStateAction<{ id: string; status: string }>>;
}): ColumnsType<any> => [
  {
    title: '#',
    width: '5%',
    render: (text, record, index) => {
      /* eslint-disable no-plusplus */
      let indexNumber = index;
      return ++indexNumber;
    },
  },
  {
    title: 'User',
    dataIndex: 'user',
    key: 'user',
    width: '10%',
    render: (tag, record) => {
      const fullName = record?.editedBy?.name.split(' ') || '';

      const capitalizedFullName = fullName.map((name:string) => name.charAt(0).toUpperCase() + name.slice(1))
        .join(' ');

      return <>{capitalizedFullName}</>;
    },
  },
  // {
  //   title: 'Role',
  //   dataIndex: 'role',
  //   key: 'role',
  //   width: '15%',
  //   render: (tag, record) => <>{record?.editedBy?.role}</>,
  // },
  {
    title: 'When',
    dataIndex: 'when',
    key: 'when',
    width: '15%',
    render: (tag, record) => <>{moment(record?.editDate).format(MM_DD_YYYY_HH_MM_SS_A) }</>,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '15%',
    render: (tag, record) => <>{record?.action}</>,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: '10%',
  },
  {
    title: 'View',
    dataIndex: 'view',
    key: 'view',
    width: '10%',
    align: 'center',
    render: (tag, record) => (
      <>
        {record?.type === 'correction' && record?.action !== 'Reject' &&(
          <BsEyeFill
            className="icon-color-viewIcon"
            type="primary"
            onClick={() => {
              setShowEditHistoryDetailModal(true);
              // eslint-disable-next-line no-underscore-dangle
              setRecord({ id: record?._id, status: record?.status});
            }}
          />
        )}
      </>
    ),
  },
];

export default LogEditHistoryTableColumns;
